import _ from 'lodash'

const appPages = ['home', 'companies', 'all-roles', 'auth', 'api', 'scratch']

const labelify = (str) => _.str.titleize(_.str.humanize(str))

const $appPages = _.map(appPages, (page) => ({
  group: 'Pages',
  label: labelify(page),
  to: `/${page}`,
  subtitle: 'Page',
}))

export default $appPages
