import { Switch, Route } from 'peach/router'

import PersonCase from './PersonCase'
import PersonCases from './PersonCases'

const PersonCasesRoutes = () => (
  <Switch>
    <Route exact path='/companies/:companyId/borrowers/:personId/cases'>
      <PersonCases />
    </Route>

    <Route path='/companies/:companyId/borrowers/:personId/cases/:caseId'>
      <PersonCase />
    </Route>
  </Switch>
)

export default PersonCasesRoutes
