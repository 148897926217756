import { useDataQuery, useQueryApi } from '../useQueryApi'

const useLoanDrawQuery = (options) => {
  const [api, { personId, loanId, drawId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['people', personId, 'loans', loanId, 'draws', drawId],

    queryFn: () =>
      api.loan.draw.get({
        pathArgs: { personId, loanId, drawId },
      }),

    enabled: !!(personId && loanId && drawId),
  })
}

export default useLoanDrawQuery
