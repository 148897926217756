import { useDataQuery, useQueryApi } from '../useQueryApi'

const useWorkflowTypesQuery = (options) => {
  const [api, { companyId, workflowTypeId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['companies', companyId, 'workflow-types', workflowTypeId],

    queryFn: () =>
      api.company.workflowType.get({
        pathArgs: { companyId, workflowTypeId },
      }),
  })
}

export default useWorkflowTypesQuery
