import _ from 'lodash'
import { useParams } from 'react-router-dom'

import { LoadingContainer, Modal, Input, Page, Model } from 'peach/components'
import { useReadData, useApi } from 'peach/hooks'
import {
  CompanyEmployeeSelect,
  CompanyAgentUserSelect,
} from 'peach/scopes/company'

import AddEmployeeForm from './AddEmployeeForm'
import useCreateUserAndEmployee from './useCreateUserAndEmployee'

const CompanyPeople = () => {
  const { companyId } = useParams()

  const api = useApi()

  const [employees, loading, error, refresh] = useReadData(
    () =>
      api.company.employees.getAll({
        pathArgs: { companyId },
        queryParams: { limit: 100 },
      }),
    [companyId],
  )

  const sendAddEmployee = async (newEmployee) => {
    await api.company.employees.post({
      pathArgs: { companyId },
      body: newEmployee,
    })
    refresh()
  }

  const addEmployee = (
    <Modal onSubmit={sendAddEmployee} title='Add Employee'>
      <Input formKey='firstName' />
      <Input formKey='lastName' />
      <Input formKey='jobName' />
      <CompanyAgentUserSelect formKey='userId' label='User' nullable />
      <CompanyEmployeeSelect
        formKey='managerEmployeeId'
        label='Manager Employee'
        nullable
      />
    </Modal>
  )

  const [sendAddUserAndEmployee] = useCreateUserAndEmployee()

  const addUserAndEmployee = (
    <Modal onSubmit={sendAddUserAndEmployee} title='Add User & Employee Record'>
      <AddEmployeeForm />
    </Modal>
  )

  const sortedEmployees = _.sortBy(employees, 'createdAt').reverse()

  return (
    <LoadingContainer error={error} loading={loading}>
      {addUserAndEmployee}
      {addEmployee}
      <Page.Full>
        <Model.List json={sortedEmployees} title='Employees' />
      </Page.Full>
    </LoadingContainer>
  )
}

export default CompanyPeople
