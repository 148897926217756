import { useState, useMemo } from 'react'

import { styled } from 'styled-components'

import { getName } from 'peach/helpers'

import Json from '../Json'
import Raw from '../ModelViewer/Raw'
import Note from '../Note/Note'
import Page from '../Page'
import { Tabs, Tab } from '../Tabs/Tabs'

const Wrap = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`
const Main = styled.div`
  border: 1px solid ${(p) => p.theme.border};
  border-radius: 2px;
  background-color: ${(p) => p.theme.offset};
  padding: 16px;
  min-height: 200px;
`

const Title = styled.div`
  margin-bottom: 12px;
  color: ${(p) => p.theme.text};
  font-weight: bold;
`

const stringify = (json) => {
  try {
    return JSON.stringify(json, null, 2)
  } catch (e) {
    console.warn(e)
    return ''
  }
}

const ModelWrapper = ({ title, json, children, page, note, ...rest }) => {
  const [tab, setTab] = useState('data')

  const $title = title || getName(json)

  const jsonStr = useMemo(() => stringify(json), [json])

  const tabs = (
    <Tabs activeName={tab} onSelect={setTab}>
      <Tab name='data'>Data</Tab>
      <Tab name='json'>Json</Tab>
      <Tab name='raw'>Raw</Tab>
    </Tabs>
  )

  const content = (
    <>
      {$title && <Title>{$title}</Title>}

      {tabs}

      {note && <Note>{note}</Note>}

      {tab === 'data' ? (
        children
      ) : tab === 'json' ? (
        <Json json={json} />
      ) : tab === 'raw' ? (
        <Raw value={jsonStr} />
      ) : (
        '?'
      )}
    </>
  )

  const wrapped = (
    <Wrap>
      <Main>{content}</Main>
    </Wrap>
  )

  return page ? <Page.Full>{wrapped}</Page.Full> : wrapped
}

export default ModelWrapper
