import lodash from 'lodash'
import underscoreDotString from 'underscore.string'

lodash.str = underscoreDotString

const alias =
  (name, realName) =>
  (...args) => {
    const msg = `'_.${name}(${args})' does not exist, you probably want '_.${realName}()'`
    throw new Error(msg)
  }

lodash.contains = alias('contains', 'includes')

window._ = lodash
