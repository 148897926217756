import { useState, useEffect, Dispatch, SetStateAction } from 'react'

import { useStorage } from 'core/storage/StorageProvider'

type StorageKey = string

const useStorageState = <T>(
  key: StorageKey,
  defaultState: T,
): [T, Dispatch<SetStateAction<T>>] => {
  const storage = useStorage()
  const [state, setState] = useState<T>(() =>
    storage.has(key) ? storage.get(key) : defaultState,
  )

  useEffect(() => {
    storage.set(key, state)
  }, [key, storage, state])

  return [state, setState]
}

export default useStorageState
