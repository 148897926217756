import { Circles } from 'react-loader-spinner'
import { useTheme } from 'styled-components'

const SIZE = 20

const SmallSpinner = ({ size = undefined, color = undefined }) => {
  const { invertedText } = useTheme()

  return (
    <Circles
      height={size ?? SIZE}
      width={size ?? SIZE}
      color={color ?? invertedText}
      ariaLabel='circles-loading'
      timeout={3000}
    />
  )
}

export default SmallSpinner
