import { styled } from 'styled-components'

import { parseError } from 'peach/helpers'

const Wrapper = styled.div`
  width: 100%;
  max-width: 550px;
`
const ErrorBox = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid red;
  border-radius: 4px;
  box-shadow: 0 10px 10px rgb(255 128 128 / 20%);
  background-color: rgb(255 196 196);
  color: red;
`

const Bar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid red;
  padding: 12px;
`

const Title = styled.span`
  display: inline-block;
  flex: 1 1 400px;
  text-align: left;
  font-size: 20px;
  font-weight: bold;
`

const Message = styled.div`
  padding: 24px 12px;
  width: 100%;
  overflow-y: auto;
  text-align: left;
  white-space: pre-wrap;
  font-size: 14px;
`

const ClearButton = styled.button`
  margin: 0;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  padding: 4px 12px;
  color: red;
  font-size: 24px;

  &:hover {
    background-color: red;
    color: white;
  }
`

const LoadingErrorBox = ({ error, onClear }) => {
  const titleStatus = error?.status ? ` (${error.status})` : ''

  const title = 'Error' + titleStatus

  return (
    <Wrapper>
      <ErrorBox>
        <Bar>
          <Title>{title}</Title>
          {onClear && <ClearButton onClick={onClear}>✗</ClearButton>}
        </Bar>
        <Message>{parseError(error)}</Message>
      </ErrorBox>
    </Wrapper>
  )
}

export default LoadingErrorBox
