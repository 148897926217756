import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { useMenuConfig } from './useMenuConfig'

const itemStyles = css`
  line-height: 1;
  border-radius: 4px;
  width: 100%;
  display: block;
  padding: 10px 4px;
  text-decoration: none;
  background-color: transparent;
  color: ${(p) => p.theme.text};
  cursor: default;
  border: 1px solid transparent;
  text-align: left;
  outline: none;

  &:disabled {
    color: ${(p) => p.theme.disabledText};
    background-color: transparent;
  }
  &:active,
  &.active {
    background-color: ${(p) => p.theme.highlight};
  }

  &:not(:disabled):not(.active):not(:active) {
    &:focus {
      background-color: transparent;
      color: ${(p) => p.theme.text};
      // border-color: ${(p) => p.theme.highlight};
    }

    &[data-selected],
    &:hover {
      cursor: pointer;
      background-color: ${(p) => p.theme.hover};
      color: ${(p) => p.theme.text};
    }
  }

  &.active {
    background-color: ${(p) => p.theme.highlight};
    color: ${(p) => p.theme.invertedText};
  }
`

const ItemLink = styled(NavLink)`
  ${itemStyles};
`

const ItemButton = styled.button`
  ${itemStyles}
`

const Contents = styled.div`
  line-height: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 4px;
  padding-left: ${(p) => (p.hasLeftSideSpace ? 0 : '4px')};
`
const CheckboxArea = styled.span`
  line-height: 1;
  display: inline-block;
  width: 12px;
  height: 16px;
`
const LabelArea = styled.div`
  line-height: 1;
  padding-left: ${(p) => (p.hasCheckbox ? '8px' : 0)};
`
const Label = styled.div`
  line-height: 1;
`
const Note = styled.div`
  line-height: 1;
`
// const checkmarks = '☑️✔️✅✓✔︎'
// const chevrons =  '› ›‹'

const MenuItem = (props) => {
  const {
    to,
    onClick,
    disabled,
    children,
    label,
    checked,
    note,
    ellipsis,
    onSelect,
    isOpen,
    onOpen,
    onClose,
    ...rest
  } = props

  const $onClick = onClick || onSelect || onOpen

  const { onClose: onMenuClose, hasLeftSideSpace } = useMenuConfig()

  const handleClick = (event) => {
    if (!disabled) $onClick(event)

    onMenuClose()
  }

  const renderProps = {
    disabled: disabled || (!$onClick && !to),
    onClick: handleClick,
  }

  const $label = label || children || '[ Menu Item ]'

  const noEllipsis = _.has(props, 'ellipsis') && !ellipsis

  const $ellipsis =
    (ellipsis || _.has(props, 'onOpen')) && !noEllipsis ? '…' : null

  const displayLabel = (
    <Label>
      {$label}
      {$ellipsis}
    </Label>
  )

  const displayNote = note && <Note>{note}</Note>

  const contents = (
    <Contents hasNote={!!note} hasLeftSideSpace={hasLeftSideSpace}>
      {hasLeftSideSpace && (
        <CheckboxArea checked={checked}>{checked ? '›' : ''}</CheckboxArea>
      )}

      <LabelArea hasCheckbox={hasLeftSideSpace}>
        {displayLabel}
        {displayNote}
      </LabelArea>
    </Contents>
  )

  if (to) {
    return (
      <ItemLink to={to} {...renderProps} {...rest}>
        {contents}
      </ItemLink>
    )
  } else {
    return (
      <ItemButton {...renderProps} {...rest}>
        {contents}
      </ItemButton>
    )
  }
}

export default MenuItem
