import _ from 'lodash'

const companyPages = [
  'bootstrap',
  'all-permissions',
  'roles',
  'copies',
  'logos',
  'links',
  'colors',
  'support',
  'config',
  'loan-types',
  'case-types',
  'workflow-types',
  'workflow-step-types',
  'verification-types',
  'investors',
  'users',
  'people',
  'borrowers',
  'teams',
  'subjects',
  'templates',
  'descriptors',
  'import-templates',
]

const labelify = (str) => _.str.titleize(_.str.humanize(str))

const getCompanyPages = ({ companyId, companyName }) => {
  if (!companyId) return []

  return _.map(companyPages, (page) => ({
    group: companyName || 'Company',
    label: labelify(page),
    to: `/companies/${companyId}/${page}`,
    note: companyName || companyId,
  }))
}

export default getCompanyPages
