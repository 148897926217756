import { useDataQuery, useQueryApi } from '../useQueryApi'

const useWorkflowStepTypeQuery = (options) => {
  const [api, { companyId, workflowStepTypeId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: [
      'companies',
      companyId,
      'workflow-step-types',
      workflowStepTypeId,
    ],

    queryFn: () =>
      api.company.workflowStepType.get({
        pathArgs: { companyId, workflowStepTypeId },
      }),
  })
}

export default useWorkflowStepTypeQuery
