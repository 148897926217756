import { useDataQuery, useQueryApi } from '../useQueryApi'

const useCompanyRolesQuery = (options) => {
  const [api, { companyId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['companies', companyId, 'roles'],

    queryFn: () => api.company.roles.get({ pathArgs: { companyId } }),
  })
}

export default useCompanyRolesQuery
