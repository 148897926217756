import {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react'

const PagesContext = createContext({
  addPage: () => {},
  removePage: () => {},
  activePage: '',
  setActivePage: () => {},
  pages: [],
})

const useModalPages = () => useContext(PagesContext)

const ModalPagesProvider = ({ children }) => {
  const [activePage, setActivePage] = useState(undefined)

  const [pages, setPages] = useState([])

  const addPage = useCallback(({ page, isDefault }) => {
    setPages((pages) => _.uniq(pages.concat({ page, isDefault })))
  }, [])

  const removePage = useCallback((page) => {
    setPages((pages) => _.reject(pages, { page }))
  }, [])

  const value = useMemo(
    () => ({ activePage, setActivePage, pages, addPage, removePage }),
    [activePage, setActivePage, pages, addPage, removePage],
  )

  useEffect(() => {
    if (!activePage) {
      const theDefault = _.find(pages, 'isDefault') || _.first(pages)

      if (theDefault?.page) setActivePage(theDefault.page)
    }
  }, [activePage, pages])

  useEffect(() => {
    setActivePage(activePage)
  }, [activePage])

  return (
    <PagesContext.Provider value={value}>{children} </PagesContext.Provider>
  )
}

export { ModalPagesProvider, useModalPages }
