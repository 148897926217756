import _ from 'lodash'
import { styled } from 'styled-components'

const Group = styled.div`
  padding-bottom: 4px;
`

const Label = styled.div`
  padding: 8px;
  color: ${(p) => p.theme.text};
  font-size: 12px;
  font-weight: bold;
`

const Message = styled.div`
  color: ${(p) => p.theme.mutedText};
`

const SearchResultsGroup = ({ label, loading, children }) => (
  <Group>
    <Label>{label}</Label>
    {_.isEmpty(children) && loading ? <Message>Loading...</Message> : children}
  </Group>
)

export default SearchResultsGroup
