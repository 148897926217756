import { styled } from 'styled-components'

export const Title = styled.h2`
  margin-bottom: 16px;
  font-size: 22px;
  color: ${(p) => p.theme.text};
`

export const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px 0 16px;
`

export const Error = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 8px;
`

export const Section = styled.div`
  margin-bottom: 16px;
  border-radius: 4px;
  border: 1px solid ${(p) => p.theme.border};
  padding: 16px;
`

export const SectionLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  color: ${(p) => p.theme.text};
`
