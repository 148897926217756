import { Children } from 'react'

import styled, { css } from 'styled-components'

import { withLabel } from 'peach/decorators'

const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: top;
  justify-content: flex-start;
`

const Item = styled.span`
  display: inline-block;
  flex-grow: 1;
  flex-shrink: 1;
  padding-right: 12px;
  ${(p) =>
    p.percentage &&
    css`
      flex-basis: ${p.percentage};
    `}
`

const FormRow = ({ children, count }) => {
  const $count = Children.count(children) || count
  const percentage = count ? `${100 / $count}%` : undefined

  const items = Children.map(children, (child, index) => (
    <Item percentage={percentage} key={index}>
      {child}
    </Item>
  ))
  return <Row>{items}</Row>
}

export default withLabel(FormRow)
