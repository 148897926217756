import _ from 'lodash'

import { Page, Markdown } from 'peach/components'
import { truncateString } from 'peach/helpers'

import { useAuthState } from 'core/auth/AuthProvider'

const stringify = (obj) => {
  if (_.isEmpty(obj)) return ''

  return JSON.stringify(obj, null, 2)
}

const code = (str, lang = '') => '```' + lang + '\n' + str + '\n```'

const formatOptions = (options = {}) => {
  if (options?.headers?.Authorization) {
    const { headers, ...restOptions } = options
    const { Authorization, ...restHeaders } = headers
    return {
      ...restOptions,
      headers: {
        ...restHeaders,
        Authorization: truncateString(Authorization, 24),
      },
    }
  }
  return options
}

const ApiCallRequest = (props) => {
  const { fetchArgs } = props

  const { companyId, userId, personId, decodedToken } = useAuthState()

  const { userType } = decodedToken

  const [fetchUrl, fetchOptions] = fetchArgs || []

  const options = formatOptions(fetchOptions)

  const codeString = `Session Context: 

- User Type: "${userType || ''}"
- Company Id: "${companyId || ''}"
- User Id: "${userId || ''}"
- Person Id: "${personId || ''}"

Request: 

- URL: '${fetchUrl}'

- Options: 

${stringify(options)}
`

  const markdown = code(codeString, 'yml')

  return (
    <Page.Full>
      <Markdown markdown={markdown} wrap />
    </Page.Full>
  )
}

export default ApiCallRequest
