import { useState } from 'react'

import { styled } from 'styled-components'

import { Panels, Bar, Button, Menu } from 'peach/components'
import { useRect } from 'peach/hooks'

const Wrapper = styled.div`
  background-color: ${(p) => p.theme.offset};
  padding: 8px;
  height: 100%;
`

const IframeWrapper = styled.div`
  border: 1px solid ${(p) => p.theme.border};
  border-radius: 4px;
  background-color: ${(p) => p.theme.background};
  width: 100%;
  height: 100%;
  overflow: hidden;
  word-break: break-word;
`

const Iframe = styled.iframe`
  width: ${(p) => p.width};
  height: ${(p) => p.height};
`

const TemplateRenderPreview = ({ preview, src, onRender }) => {
  const [dimensions, ref] = useRect()

  const { width, height } = dimensions

  const [type, setType] = useState('html')

  const header = (
    <Bar
      left={
        <Bar.Section>
          <Button onClick={onRender}>Preview</Button>
          <Menu label={type}>
            <Menu.Item onSelect={() => setType('html')}>html</Menu.Item>
            <Menu.Item onSelect={() => setType('text')}>text</Menu.Item>
            <Menu.Item onSelect={() => setType('subject')}>subject</Menu.Item>
          </Menu>
        </Bar.Section>
      }
    />
  )

  const { subjectLine, contentHtml, contentText } = preview || {}

  const content =
    type === 'html' ? contentHtml : type === 'text' ? contentText : subjectLine

  return (
    <Panels header={header}>
      <Wrapper>
        <IframeWrapper ref={ref}>
          <Iframe
            frameBorder={0}
            height={height}
            id='temapltePreview'
            srcDoc={src || content}
            title='temapltePreview'
            width={width}
          />
        </IframeWrapper>
      </Wrapper>
    </Panels>
  )
}

export default TemplateRenderPreview
