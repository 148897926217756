import { useDataQuery, useQueryApi } from '../useQueryApi'

const useUsersQuery = ({ userType, ...rest } = {}) => {
  const [api, { companyId }] = useQueryApi(rest)

  return useDataQuery({
    queryKey: ['companies', companyId, 'users', { userType }],

    queryFn: () =>
      api.company.users.get({
        pathArgs: { companyId },
        queryParams: { limit: 100, userType },
      }),

    enabled: !!companyId,
  })
}

export default useUsersQuery
