import { useMemo, useState } from 'react'

import _ from 'lodash'

import { Panels, Bar, Select, Form, Input } from 'peach/components'
import { humanizeKey } from 'peach/helpers'

const facet = (collection, key) => _.uniq(_.map(collection, key))

const deSnake = (str) => _.str.titleize(_.str.humanize(str))

const makeMatches = (rawQuery = '') => {
  const query = rawQuery.trim().toLowerCase()
  return (str = '') => str.toLowerCase().includes(query)
}

const objMatches = (obj, rawQuery = '') => {
  const query = rawQuery.trim().toLowerCase()
  if (!query) return true
  const matchFn = makeMatches(rawQuery)
  const tokens = _.compact(query.split(' '))
  return _.some(tokens, () =>
    _.some(obj, (val) => {
      const isValidType = _.isString(val) || _.isFinite(val)
      if (isValidType) return matchFn('' + val)
    }),
  )
}

const sortKeys = ['effectiveDate', 'postedDate', 'createdAt']

const makeSortOptions = () => {
  const options = []
  _.each(sortKeys, (key) => {
    const label = humanizeKey(key)
    options.push({ label: `${label} Asc`, value: key })
    options.push({ label: `${label} Desc`, value: `-${key}` })
  })
  return options
}

const parseSort = (key = '') => {
  const reverse = key.slice(0, 1) === '-'
  const prop = reverse ? key.slice(1) : key
  const direction = reverse ? 'desc' : 'asc'
  return { prop, reverse, direction }
}

const sortOptions = makeSortOptions(sortKeys)

const LedgerFilters = ({ ledger, right, render }) => {
  const [filters, setFilters] = useState({
    quickSearch: '',
    sortBy: 'effectiveDate',
    buckets: [],
  })

  const bucketNames = useMemo(() => {
    const facets = facet(ledger, 'bucketName')
    return _.map(facets, (facet) => ({ value: facet, label: deSnake(facet) }))
  }, [ledger])

  const filteredEntries = _.filter(ledger, (entry) => {
    const matchesButckets =
      _.isEmpty(filters.buckets) ||
      _.includes(filters.buckets, entry.bucketName)

    const matchesSearch = objMatches(entry, filters.quickSearch)

    return matchesButckets && matchesSearch
  })

  const sort = parseSort(filters.sortBy)

  const sortedEntries = _.orderBy(
    filteredEntries,
    [sort.prop, 'id'],
    [sort.direction, sort.direction],
  )

  const shownCount = _.size(filteredEntries)

  const totalCount = _.size(ledger)

  const countDisplay = !totalCount ? '' : `${shownCount} / ${totalCount}`

  const filterControls = (
    <Form.Provider onChange={setFilters} value={filters}>
      <Input formKey='quickSearch' width='240px' />

      <Select formKey='buckets' multi options={bucketNames} width='240px' />

      <Select formKey='sortBy' options={sortOptions} width='240px' />

      <div style={{ width: 240 }}>{countDisplay}</div>
    </Form.Provider>
  )

  const header = <Bar left={filterControls} right={right} />

  return (
    <Panels header={header}>
      {render ? render({ ledger: sortedEntries }) : null}
    </Panels>
  )
}

export default LedgerFilters
