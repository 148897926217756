import { useParams, useRouteMatch } from 'react-router-dom'

import {
  Model,
  LoadingContainer,
  Modal,
  Panels,
  Bar,
  Input,
  Page,
  Breadcrumb,
} from 'peach/components'
import { getName } from 'peach/helpers'
import { useReadData, useApi } from 'peach/hooks'
import {
  CompanyTeamSelect,
  CompanyEmployeeSelect,
  CompanyAgentUserSelect,
} from 'peach/scopes/company'

const CompanyEmployee = () => {
  const { url } = useRouteMatch()
  const { companyId, employeeId } = useParams()

  const api = useApi()

  const [employee, loading, error, refresh] = useReadData(
    () => api.company.employee.get({ pathArgs: { companyId, employeeId } }),
    [companyId, employeeId],
  )

  const sendEditEmployee = async (_employee, diff) => {
    await api.company.employee.put({
      pathArgs: { companyId, employeeId },
      body: diff,
    })
    refresh()
  }

  const initialValue = { userId: employee?.user?.id, ...employee }

  const editEmployee = (
    <Modal
      initialValue={initialValue}
      onSubmit={sendEditEmployee}
      title='Edit Employee'
      trigger
    >
      <Input formKey='firstName' />
      <Input formKey='lastName' />
      <Input formKey='jobName' />
      <CompanyAgentUserSelect
        formKey='userId'
        label='User'
        nullable
        searchable
      />
      <CompanyEmployeeSelect
        formKey='managerEmployeeId'
        label='Manager Employee'
        nullable
        searchable
      />
    </Modal>
  )

  const sendAddToTeam = async ({ teamId }) => {
    await api.company.employeeTeam.put({
      pathArgs: { companyId, employeeId, teamId },
    })
    refresh()
  }

  const addToTeam = (
    <Modal onSubmit={sendAddToTeam} title='Add To Team' trigger>
      <CompanyTeamSelect formKey='teamId' />
    </Modal>
  )

  const name = getName(employee)

  const header = (
    <Bar
      right={
        <>
          {addToTeam}
          {editEmployee}
        </>
      }
      title={name}
    />
  )

  return (
    <Panels header={header}>
      <Breadcrumb title={name} to={url} />
      <LoadingContainer error={error} loading={loading}>
        <Page>
          <Model.Item json={employee} />
        </Page>
      </LoadingContainer>
    </Panels>
  )
}

export default CompanyEmployee
