import { useEffect } from 'react'

import { Redirect } from 'react-router-dom'

import { useAuthApi } from 'core/auth/AuthProvider'

const Logout = () => {
  const authApi = useAuthApi()

  useEffect(() => {
    authApi.logout()
  }, [authApi])

  return <Redirect to='/' />
}

export default Logout
