import {
  Input,
  Textarea,
  Select,
  NumericInput,
  Form,
  OrderableList,
  Modal,
  StateCodeSelect,
  CountryCodeSelect,
} from 'peach/components'

const BaseProperties = () => (
  <Modal.Page page='Loan Type'>
    <Input formKey='name' required />

    <Input formKey='assetDisplayName' required />

    <Select disabled formKey='type' options={['installment', 'lineOfCredit']} />

    <Form.If if={{ type: 'installment' }}>
      <Select
        formKey='installmentType'
        options={['standard', 'multipleAdvances']}
      />
    </Form.If>

    <Form.If if={{ type: 'lineOfCredit' }}>
      <Select
        formKey='lineOfCreditType'
        options={['revolving', 'nonRevolving']}
      />
    </Form.If>

    <Textarea formKey='description' />

    <Select boolean formKey='managedByPeach' />

    <Input formKey='externalId' />

    <Input formKey='timezone' />

    <Select
      formKey='assetType'
      options={[
        'personalClosedEndUnsecured',
        'personalOpenEndUnsecured',
        'personalClosedEndSecured',
        'personalOpenEndSecured',
        'motorMVRISC',
        'motorDirect',
        'motorLease',
        'businessClosedEndUnsecured',
        'businessOpenEndUnsecured',
        'businessClosedEndSecured',
        'businessOpenEndSecured',
      ]}
    />

    <Select boolean formKey='paymentAllowedWhenChargedOff' />

    <Select boolean formKey='useExternalIdInUI' />

    <Form.If if={{ type: 'installment' }}>
      <Select boolean formKey='allowPrepayments' />
    </Form.If>

    <Form.Provider formKey='paydayNotices'>
      <Select boolean formKey='sendFirstPaymentWithdrawalNotice' />
      <Select boolean formKey='sendConsumerRightsNotice' />
    </Form.Provider>

    <Textarea.Json formKey='extraFields' />
  </Modal.Page>
)

const NewBase = () => (
  <Modal.Page page='Loan Type'>
    <Select disabled formKey='type' options={['installment', 'lineOfCredit']} />

    <Input formKey='assetDisplayName' required />

    <Select
      formKey='assetType'
      options={[
        'personalClosedEndUnsecured',
        'personalOpenEndUnsecured',
        'personalClosedEndSecured',
        'personalOpenEndSecured',
        'motorMVRISC',
        'motorDirect',
        'motorLease',
        'businessClosedEndUnsecured',
        'businessOpenEndUnsecured',
        'businessClosedEndSecured',
        'businessOpenEndSecured',
      ]}
      required
    />

    <Form.If if={{ type: 'installment' }}>
      <Select
        formKey='installmentType'
        options={['standard', 'multipleAdvances']}
      />
    </Form.If>

    <Form.If if={{ type: 'lineOfCredit' }}>
      <Select
        formKey='lineOfCreditType'
        options={['revolving', 'nonRevolving']}
      />
    </Form.If>

    <Input formKey='name' />

    <Textarea formKey='description' />

    <Select boolean formKey='managedByPeach' />

    <Input formKey='externalId' />

    <Input formKey='timezone' />

    <Select boolean formKey='paymentAllowedWhenChargedOff' />

    <Select boolean formKey='useExternalIdInUI' />

    <Form.If if={{ type: 'installment' }}>
      <Select boolean formKey='allowPrepayments' />
    </Form.If>

    <Textarea.Json formKey='extraFields' />
  </Modal.Page>
)

const PaydayNotices = () => (
  <Modal.Page formKey='paydayNotices'>
    <Select boolean formKey='sendFirstPaymentWithdrawalNotice' />
    <Select boolean formKey='sendConsumerRightsNotice' />
  </Modal.Page>
)

const FuturePay = () => (
  <Modal.Page formKey='futurePay'>
    <Select
      formKey='achDebitTiming'
      options={['initiateOnDueDate', 'hitOnDueDate']}
    />
    <Select
      formKey='achDebitTimingShiftOnNonBusinessDay'
      options={['forward', 'backward']}
    />
    <Form.Provider formKey='balanceCheck' section>
      <Select boolean formKey='enabled' />
      <NumericInput formKey='checkOffsetDays' />
      <Form.List add formKey='thresholds' remove>
        <Input formKey='zone' />
        <NumericInput formKey='value' />
      </Form.List>

      <Form.List add formKey='debitLogic' remove>
        <Select
          formKey='condition'
          options={[
            'balanceNA',
            'balanceBroken',
            'balanceRed',
            'balanceYellow',
            'balanceGreen',
          ]}
        />
        <Select formKey='action' options={['cancel', 'debit', 'reschedule']} />
      </Form.List>
      <NumericInput
        formKey='rescheduleBufferInDays'
        label='Reschedule Buffer In Days'
        placeholder='Number'
      />
      <Select
        formKey='rescheduleBufferDayType'
        options={['business', 'calendar']}
      />
    </Form.Provider>
  </Modal.Page>
)

const feeLogicOptions = [
  'onTopPeriodicPayment',
  'blendWithPeriodicPayment',
  'chargedAtOrigination',
]
const originationFeeAPROptions = ['noOverride', 'atOrigination']

const InstallmentFees = () => (
  <Modal.Page formKey='fees'>
    <Select formKey='originationFeeChargeLogic' options={feeLogicOptions} />
    <Select
      formKey='originationFeeAPRCalcOverride'
      options={originationFeeAPROptions}
    />
  </Modal.Page>
)

const LineOfCreditFees = () => (
  <Modal.Page formKey='fees'>
    <Select formKey='originationFeeChargeLogic' options={feeLogicOptions} />
    <Select
      formKey='originationFeeAPRCalcOverride'
      options={originationFeeAPROptions}
    />

    <Select formKey='drawFeeChargeLogic' options={feeLogicOptions} />
  </Modal.Page>
)

const InstallmentDuePayments = () => (
  <Modal.Page formKey='duePayments'>
    <Select
      formKey='amortizationMethod'
      options={['amortizationEqual', 'custom']}
    />
    <Textarea formKey='customFunctionId' nullable />
  </Modal.Page>
)

const LineOfCreditDuePayments = () => (
  <Modal.Page formKey='duePayments'>
    <Select boolean formKey='expectedPaymentIsAmortizedDrawAllowed' />

    <Select
      formKey='amortizationMethod'
      options={['amortizationEqual', 'custom']}
    />

    <Textarea formKey='customFunctionId' nullable />

    <NumericInput formKey='statementMinAmount' />

    <NumericInput formKey='statementMinPrecentageOfPrincipal' />
  </Modal.Page>
)

const Refunds = () => (
  <Modal.Page formKey='refunds'>
    <Select boolean formKey='isRefundAllowed' />
  </Modal.Page>
)

const InterestRate = () => (
  <>
    <CountryCodeSelect formKey='country' />
    <Form.If if={{ country: 'US' }}>
      <StateCodeSelect formKey='state' />
    </Form.If>
    <Form.Row>
      <NumericInput formKey='maxRate' />
      <NumericInput formKey='minRate' />
    </Form.Row>
  </>
)

const Interest = () => (
  <>
    <Select formKey='accrualMethod' options={['simple', 'compound']} />

    <NumericInput formKey='dailyInterestCalculation' />

    <Select formKey='periodAccrualDurationType' options={['actual', 'fixed']} />

    <Select formKey='aprMethod' options={['usRule', 'actuarial']} />

    <Select boolean formKey='isInterestAmountCap' />

    <Form.List
      add
      defaultValue={{ country: 'US' }}
      formKey='annualInterestRateRange'
      remove
    >
      <InterestRate />
    </Form.List>

    <Form.List add defaultValue={{ country: 'US' }} formKey='aprRange' remove>
      <InterestRate />
    </Form.List>
  </>
)

const LineOfCreditInterest = () => (
  <Modal.Page formKey='interest'>
    <Interest />
  </Modal.Page>
)

const InstallmentInterest = () => (
  <Modal.Page formKey='interest'>
    <Interest />
    <Select boolean formKey='accrueInterestAfterTermEnds' />
  </Modal.Page>
)

const DueDates = () => (
  <>
    <Select boolean formKey='isShowRevisedTermsOnFrequencyChange' />
    <Select formKey='dateAdjustment' options={['backward', 'forward']} />
    <Form.Provider formKey='gapBetweenDueDates' section>
      <Form.Provider formKey='weekly' section>
        <Form.Row>
          <NumericInput formKey='min' />
          <NumericInput formKey='max' />
        </Form.Row>
      </Form.Provider>
      <Form.Provider formKey='everyTwoWeeks' section>
        <Form.Row>
          <NumericInput formKey='min' />
          <NumericInput formKey='max' />
        </Form.Row>
      </Form.Provider>
      <Form.Provider formKey='twiceMonthly' section>
        <Form.Row>
          <NumericInput formKey='min' />
          <NumericInput formKey='max' />
        </Form.Row>
      </Form.Provider>
      <Form.Provider formKey='monthly' section>
        <Form.Row>
          <NumericInput formKey='min' />
          <NumericInput formKey='max' />
        </Form.Row>
      </Form.Provider>
    </Form.Provider>
    <Select formKey='dayType' options={['business', 'calendar']} />
    <Form.Provider formKey='allowedFrequencyNewLoan' section>
      <Select boolean formKey='isDueDatesAvailableWeekly' />
      <Select boolean formKey='isDueDatesAvailableEveryTwoWeeks' />
      <Select boolean formKey='isDueDatesAvailableMonthly' />
      <Select boolean formKey='isDueDatesAvailableTwiceMonthly' />
      <Select boolean formKey='isDueDatesAvailableSingleTerm' />
    </Form.Provider>
    <Form.Provider formKey='allowedFrequencyChanges' section>
      <Select boolean formKey='weeklyToWeeklyDay' />
      <Select boolean formKey='weeklyToEveryTwoWeeks' />
      <Select boolean formKey='weeklyToTwiceMonthly' />
      <Select boolean formKey='weeklyToMonthly' />

      <Select boolean formKey='everyTwoWeeksToEveryTwoWeeksDay' />
      <Select boolean formKey='everyTwoWeeksToWeekly' />
      <Select boolean formKey='everyTwoWeeksToTwiceMonthly' />
      <Select boolean formKey='everyTwoWeeksToMonthly' />

      <Select boolean formKey='twiceMonthlyToTwiceMonthlyDay' />
      <Select boolean formKey='twiceMonthlyToWeekly' />
      <Select boolean formKey='twiceMonthlyToEveryTwoWeeks' />
      <Select boolean formKey='twiceMonthlyToMonthly' />

      <Select boolean formKey='monthlyToMonthlyDay' />
      <Select boolean formKey='monthlyToWeekly' />
      <Select boolean formKey='monthlyToEveryTwoWeeks' />
      <Select boolean formKey='monthlyToTwiceMonthly' />
    </Form.Provider>
  </>
)

const InstallmentDueDates = () => (
  <Modal.Page formKey='dueDates'>
    <DueDates />
    <Select formKey='periodType' options={['periodEnd', 'periodBegin']} />
    <Select
      formKey='singleTermUnitPeriod'
      options={['days', 'months', 'sixMonths', 'years']}
    />
  </Modal.Page>
)

const LineOfCreditDueDates = () => (
  <Modal.Page formKey='dueDates'>
    <DueDates />
  </Modal.Page>
)

const Thresholds = () => (
  <>
    <Select formKey='zone' options={['balanceRed', 'balanceYellow']} />
    <NumericInput formKey='value' />
  </>
)

const DebitLogic = () => (
  <>
    <Select
      formKey='condition'
      options={[
        'balanceNA',
        'balanceBroken',
        'balanceRed',
        'balanceYellow',
        'balanceGreen',
      ]}
    />
    <Select formKey='action' options={['cancel', 'debit', 'reschedule']} />
  </>
)

const PaymentsRetry = () => (
  <>
    <Select boolean formKey='isRetryAutopayFailedPayments' />
    <NumericInput formKey='maxNumberOfAutoRetriesPerPayment' />
    <Select formKey='retryDayMethod' options={['fixedBuffer', 'dayOfWeek']} />
    <Select formKey='retryBufferDayType' options={['business', 'calendar']} />
    <NumericInput formKey='retryBufferInDays' />
    <NumericInput formKey='doNotRetryAfterMaxDays' />
    <NumericInput formKey='retryDayOfWeek' />
    <NumericInput formKey='retryDayOfWeekMinBuffer' />
  </>
)

const Autopay = () => (
  <Modal.Page formKey='autoPay' page='AutoPay'>
    <Select
      formKey='achDebitTiming'
      options={['initiateOnDueDate', 'hitOnDueDate']}
    />
    <Select
      formKey='achDebitTimingShiftOnNonBusinessDay'
      options={['forward', 'backward']}
    />
    <NumericInput formKey='amountChangeNotificationBuffer' />
    <NumericInput formKey='amountChangePercentageThreshold' />
    <NumericInput formKey='numberOfConsecutiveFailedAttempts' />
    <Form.Provider formKey='paymentsRetry' section>
      <PaymentsRetry />
    </Form.Provider>
    <Form.Provider formKey='balanceCheck' section>
      <Select boolean formKey='enabled' />
      <NumericInput formKey='checkOffsetDays' />
      <NumericInput formKey='rescheduleBufferInDays' />
      <Select
        formKey='rescheduleBufferDayType'
        options={['business', 'calendar']}
      />
      <Form.List add formKey='thresholds' remove>
        <Thresholds />
      </Form.List>
      <Form.List add formKey='debitLogic' remove>
        <DebitLogic />
      </Form.List>
    </Form.Provider>
  </Modal.Page>
)

const Terms = () => (
  <>
    <NumericInput formKey='accelerationDueWithin' />
    <NumericInput formKey='chargeOffDueWithin' />
    <NumericInput formKey='rightToCureBuffer' />
  </>
)

const LineOfCreditTerms = () => (
  <Modal.Page formKey='terms'>
    <Terms />
    <NumericInput formKey='closeLineAfterInactivePeriods' />
  </Modal.Page>
)

const InstallmentTerms = () => (
  <Modal.Page formKey='terms'>
    <Terms />
    <NumericInput formKey='durationExtensionMaxDays' />
  </Modal.Page>
)

const MultiWaterfall = () => (
  <Modal.Page page='Payment Waterfall / Multi'>
    <Form.List formKey='multi'>
      <Select
        disabled
        formKey='transactionType'
        options={['payment', 'serviceCredit']}
      />
      <OrderableList formKey='loansOrder' />
    </Form.List>
  </Modal.Page>
)

const SingleWaterfall = () => (
  <Modal.Page page='Payment Waterfall / Single'>
    <Form.List formKey='single'>
      <Form.Row>
        <Select
          disabled
          formKey='transactionType'
          options={['payment', 'serviceCredit']}
        />
        <Select
          disabled
          formKey='loanStatus'
          options={['active', 'accelerated', 'chargedOff']}
        />
      </Form.Row>

      <Form.If if={{ loanStatus: 'active' }}>
        <OrderableList formKey='balancesOrder' />
      </Form.If>
      <Form.If if={{ loanStatus: 'accelerated' }}>
        <OrderableList formKey='balancesOrder' />
      </Form.If>
      <Form.If if={{ loanStatus: 'chargedOff' }}>
        <OrderableList formKey='balancesOrder' />
      </Form.If>
    </Form.List>
  </Modal.Page>
)

const Waterfall = () => (
  <Form.Provider formKey='paymentWaterfall'>
    <MultiWaterfall />

    <SingleWaterfall />
  </Form.Provider>
)

const CreditReporting = () => (
  <Modal.Page formKey='creditReporting'>
    <Select boolean formKey='isReportingToBureaus' />

    <Select boolean formKey='isReportingToExperian' />

    <Select boolean formKey='isReportingToEquifax' />

    <Select boolean formKey='isReportingToTransunion' />
  </Modal.Page>
)

const Statements = () => (
  <>
    <Select boolean formKey='statementsEnabled' />
    <NumericInput formKey='lateFeeUpToAmount' />
    <Select boolean formKey='isCreateAndSendPDF' />
  </>
)

const LineOfCreditStatements = () => (
  <Modal.Page formKey='statements'>
    <Statements />
    <NumericInput formKey='locStatementCreationDaysOffset' />
    <NumericInput formKey='pauseStatementsAfterInactivePeriods' />
  </Modal.Page>
)

const InstallmentStatements = () => (
  <Modal.Page formKey='statements'>
    <Statements />
    <Form.Provider formKey='installmentStatementCreationDaysOffsets' section>
      <NumericInput formKey='everyTwoWeeks' />

      <NumericInput formKey='monthly' />

      <NumericInput formKey='twiceMonthly' />

      <NumericInput formKey='weekly' />
    </Form.Provider>
  </Modal.Page>
)

const CreateInstallmentLoanTypeForm = () => (
  <>
    {/* <BaseProperties /> */}
    <NewBase />

    <PaydayNotices />

    <FuturePay />

    <InstallmentFees />

    <InstallmentDuePayments />

    <Refunds />

    <InstallmentInterest />

    <InstallmentDueDates />

    <Autopay />

    <InstallmentTerms />

    <Waterfall />

    <CreditReporting />

    <InstallmentStatements />
  </>
)

const CreateLineOfCreditLoanTypeForm = () => (
  <>
    <BaseProperties />

    <FuturePay />

    <LineOfCreditFees />

    <LineOfCreditDuePayments />

    <Refunds />

    <LineOfCreditInterest />

    <LineOfCreditDueDates />

    <Autopay />

    <LineOfCreditTerms />

    <Waterfall />

    <CreditReporting />

    <LineOfCreditStatements />
  </>
)

export { CreateInstallmentLoanTypeForm, CreateLineOfCreditLoanTypeForm }
