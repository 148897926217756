import { Input, Form, Page } from 'peach/components'
import { withFormValue } from 'peach/decorators'

import CodeEditor from './CodeEditor'

const TemplateEditor = ({ value, onChange }) => (
  <Page.Full>
    <Form.Provider onChange={onChange} value={value || {}}>
      <Input formKey='subjectLineTemplate' />
      <CodeEditor formKey='contentHtmlTemplate' />
      <CodeEditor formKey='contentTextTemplate' />
    </Form.Provider>
  </Page.Full>
)

export default withFormValue(TemplateEditor)
