import _ from 'lodash'
import queryString from 'query-string'
const defaultHeaders = {
  // Accept: 'application/json',
  'Content-type': 'application/json',
}

const getPath = (pathPattern, pathArgs = {}, opts = {}) => {
  const { throwErrors } = opts

  if (throwErrors && !pathPattern) {
    throw new Error('request requires a pathPattern')
  }

  const finalPath = _.reduce(
    pathArgs,
    (path, value, name) => {
      const subStr = `{${name}}`
      if (path.includes(subStr)) {
        if (!value) {
          if (throwErrors) {
            throw new Error(
              `path replacement '${name}' requires non-empty string value for path pattern '${pathPattern}'.`,
            )
          }
          return path.replace(subStr, ` [ ${name} ] `)
        }
        return path.replace(subStr, value)
      }
      return path
    },
    pathPattern,
  )

  if (finalPath.includes('{')) {
    if (throwErrors) {
      throw new Error(
        `arguments were not supplied for pathPattern '${pathPattern}', received '${JSON.stringify(
          pathArgs,
        )}'`,
      )
    } else {
      return finalPath.replaceAll(/{\w+}/g, 'ID-XXXX-XXXX')
    }
  }

  return finalPath
}

const getQueryString = (params) => queryString.stringify(params)

const getUrl = (base, route, queryString) =>
  '' + base + route + (queryString ? `?${queryString}` : '')

const getBody = (body, method) => {
  if (body && ['PUT', 'POST', 'PATCH'].includes(method)) {
    return JSON.stringify(body)
  }
}

const getFetchOptions = (options) => {
  const {
    // session based info
    token,
    apiKey,
    // endpoint specific properties
    method,
    pathPattern,
    // call specific properties
    pathArgs,
    headers,
    queryParams,
    body,
    apiBase = '\\\\',
    throwErrors,
    paginationUrl,
  } = options

  const requestHeaders = { ...defaultHeaders, ...headers }

  if (apiKey) {
    requestHeaders.X_API_KEY = apiKey
  } else {
    requestHeaders.Authorization = token ? `Bearer ${token}` : ''
  }

  const requestOptions = {
    method,
    headers: requestHeaders,
    body: getBody(body, method),
  }

  if (paginationUrl) {
    return [paginationUrl, requestOptions]
  }

  const path = getPath(pathPattern, pathArgs, { throwErrors })

  const queryString = getQueryString(queryParams)

  const url = getUrl(apiBase, path, queryString)

  return [url, requestOptions]
}

const executeRequest = async (options) => {
  const { responseType = 'json' } = options

  const [fetchUrl, fetchOptions] = getFetchOptions({
    ...options,
    throwErrors: true,
  })

  return fetch(fetchUrl, fetchOptions)
    .then((resp) => {
      if (resp.status === 204) {
        return [resp, {}]
      } else {
        try {
          if (resp.ok && responseType === 'text') {
            return resp.text().then((text) => [resp, text])
          } else {
            return resp.json().then((json) => [resp, json])
          }
        } catch (err) {
          console.error('Error parsing response', err)
          return [resp, {}]
        }
      }
    })
    .then((result) => {
      const [resp, json] = result

      if (resp.ok) {
        return json
      } else {
        const status = resp.status || json.status || 0

        const message =
          (_.isString(json) && json) ||
          resp.message ||
          json.message ||
          'Unknown Error'
        const error = { status, message, ...(_.isObject(json) && json) }
        throw error
      }
    })
}

executeRequest.getFetchOptions = getFetchOptions

export default executeRequest
