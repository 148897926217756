import _ from 'lodash'

import { Page, MiniTable, Card, Json, LoadingContainer } from 'peach/components'
import {
  useSessionCompaniesQuery,
  useSessionPermissionsQuery,
} from 'peach/data'
import { humanizeKey } from 'peach/helpers'

import variables from 'core/theme/variables'
const join = (...args) => _.map(_.compact(args), humanizeKey).join(' / ')

const companyRow = ({ company, disabled }) => {
  const {
    id,
    agentAuthType,
    agentAuthValueType,
    borrowerAuthType,
    borrowerAuthValueType,
    config,
    timezone,
    name,
    brandName,
    legalName,
    type,
  } = company

  // This used to be color = config?.brandAssets?.colors?.primary
  // but sometimes when a company's primary color is too dark, the name
  // text of the company in the companies table gets hard to read
  // when the theme is set to dark mode.
  //
  // For now we're using the app's primary blue color.
  const color = variables.colorBluePrimary

  const processedByPeach = config?.payment?.processedByPeach

  const details = {
    type,
    processedByPeach,
    timezone,
  }

  const auth = {
    agentAuth: join(agentAuthValueType, agentAuthType),
    borrowerAuth: join(borrowerAuthValueType, borrowerAuthType),
  }

  const names = {
    name,
    brandName,
    legalName,
  }

  return (
    <MiniTable.Row key={id}>
      <Card color={color} disabled={disabled} model={company} />
      <Json.List compact humanizeKeys json={details} limit={3} />
      <Json.List compact humanizeKeys json={auth} limit={3} />
      <Json.List compact humanizeKeys json={names} limit={3} />
    </MiniTable.Row>
  )
}

const Companies = () => {
  const [companies, companiesQuery] = useSessionCompaniesQuery()

  const [permissions, permissionsQuery] = useSessionPermissionsQuery()

  const annotatedCompanies = _.map(companies, (company) => ({
    company,
    disabled: !_.includes(permissions?.[company.id], 'company:read'),
  }))

  const sortedCompanies = _.sortBy(annotatedCompanies, 'disabled')

  const rows = _.map(sortedCompanies, ({ company, disabled }) =>
    companyRow({ company, disabled }),
  )

  return (
    <LoadingContainer queries={[companiesQuery, permissionsQuery]}>
      <Page.Full title='Companies'>
        <MiniTable columns={['Company', 'Details', 'Auth', 'Names']} fixed>
          {rows}
        </MiniTable>
      </Page.Full>
    </LoadingContainer>
  )
}

export default Companies
