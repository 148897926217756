import { combineStrings } from 'peach/helpers'

const capitalize = (str) => {
  if (!_.isString(str)) return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const parseEnpointRestriction = (data) =>
  'Permissions: \n\n' +
  _.map(
    data,
    ([id, permission]) => ` - Missing '${permission}' from ${id}`,
  ).join('\n')

const parse = (error) => {
  try {
    if (!error) return 'Empty Error'

    if (error?.message === 'Failed to fetch') {
      return 'Network Error:  \n\nOften the endpoint called does not exist and the response was returned without CORS headers'
    }

    if (_.isString(error)) return error

    const { status, message, detail, data, description } = error

    const moreInfo =
      message === 'Endpoint access restricted' && _.isArray(data)
        ? parseEnpointRestriction(data)
        : detail || data?.description || description

    const displayStatus = _.isFinite(status) ? status : null

    const displayMessage = combineStrings([displayStatus, message], ' - ')

    const fullMessage = combineStrings([displayMessage, moreInfo], ': \n\n')

    return fullMessage || 'Unknown Error'
  } catch (err) {
    console.error('Issue parsing error response', err)
    return 'Unexpected Error'
  }
}

const parseError = (error) => capitalize(parse(error))

export default parseError
