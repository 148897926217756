import { styled } from 'styled-components'

const SectionWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
  border-top: 1px solid ${(p) => p.theme.border};
  padding: 12px;
`

const Content = styled.div``

const Section = ({ children }) => (
  <SectionWrapper>
    <Content>{children}</Content>
  </SectionWrapper>
)

export default Section
