import { forwardRef } from 'react'

import { Portal } from '@reach/portal'
import styled, { css } from 'styled-components'

import color from 'peach/helpers/color/color'
import { useAnimatedIsOpen } from 'peach/hooks'

import useClickOutside from '../useClickOutside'

import FadeInOut from './FadeInOut'
import SlidingWindow from './SlidingWindow'

const OverlayShade = styled.div`
  position: absolute;
  inset: 0;
  background-color: rgb(64 64 64 / 45%);
`

const OverlayLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  overflow: auto;

  ${(p) =>
    p.big
      ? css`
          height: 100%;
        `
      : css`
          min-height: 0;
          max-height: 100%;
        `}
`

const TopSpacer = styled.div`
  flex: 0 2 64px;
  min-height: 0;
`

const ContentsRow = styled.div`
  ${(p) =>
    p.big
      ? css`
          height: 80%;
        `
      : css`
          min-height: 240px;
          max-height: 75%;
        `}
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  overflow: hidden;
`

const BottomSpacer = styled.div`
  flex: 1 2 128px;
  min-height: 0;
`

const ContentsArea = styled.div`
  flex-grow: 1;
  padding: 24px;
  width: ${(p) => (p.big ? '90%' : '600px')};
  overflow: auto;
`

const SideSpcaer = styled.div`
  flex-basis: 24px;
  flex-grow: 3;
`
const ContentsDiv = styled.div`
  flex-grow: 1;
  border: 1px solid ${(p) => color.deepen(p.theme.border)};
  border-radius: 4px;
  box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
  background-color: ${(p) => p.theme.background};
  height: 100%;
  overflow: auto;
`

const BigModalContainer = forwardRef((props, ref) => {
  const { children, isOpen, onClose, big } = props
  const [$ref] = useClickOutside(onClose, ref)

  const [isMounted, $isOpen] = useAnimatedIsOpen(isOpen)

  const show = !big || $isOpen

  return isMounted ? (
    <Portal>
      <FadeInOut isOpen={$isOpen}>
        <OverlayShade>
          <OverlayLayout big={big}>
            <TopSpacer />
            <ContentsRow big={big}>
              <SideSpcaer />
              <ContentsArea big={big}>
                <SlidingWindow isOpen={$isOpen}>
                  {show && <ContentsDiv ref={$ref}>{children}</ContentsDiv>}
                </SlidingWindow>
              </ContentsArea>
              <SideSpcaer />
            </ContentsRow>
            <BottomSpacer />
          </OverlayLayout>
        </OverlayShade>
      </FadeInOut>
    </Portal>
  ) : null
})

BigModalContainer.displayName = 'BigModalContainer'

export default BigModalContainer
