import styled, { css } from 'styled-components'

import withLabel from 'peach/decorators/withLabel/withLabel'
// importing directly to avoid some circular dependency stuff

const ContentArea = styled.div`
  display: flex;
  position: relative;
  flex: 1 1 auto;
  flex-direction: column;
  border-left: 1px solid ${(p) => p.theme.border};
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
`

const lineStyles = css`
  display: inline-block;
  position: absolute;
  border-width: 0;
  border-style: solid;
  border-color: ${(p) => p.theme.border};
  width: 32px;
  height: 4px;
`
const TopLine = styled.span`
  ${lineStyles}
  top: 0;
  left: 0;
  border-top-width: 1px;
  border-top-left-radius: 4px;
`
const BottomLine = styled.span`
  ${lineStyles}
  bottom: 0;
  left: 0;
  border-bottom-width: 1px;
  border-bottom-left-radius: 4px;
`

const FormSection = (props) => {
  const { children } = props

  return (
    <ContentArea>
      <TopLine />
      {children}
      <BottomLine />
    </ContentArea>
  )
}

const $FormSection = withLabel(FormSection)

export default $FormSection
