import { styled } from 'styled-components'

import Button from '../Button/Button'

const ActionsArea = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(p) => p.theme.offset};
`
const OtherActionsArea = styled.span`
  display: inline-block;
`

const SubmitArea = styled.span`
  & > * {
    margin-left: 12px;
  }
`

const ModalActionsBar = (props) => {
  const {
    left,
    onSubmit,
    submitLabel,
    canSubmit,

    onCancel,
    canCancel,
    cancelLabel,

    sending,
  } = props

  return (
    <ActionsArea>
      <OtherActionsArea>{left}</OtherActionsArea>
      <SubmitArea>
        <Button onClick={onCancel} disabled={!onCancel || canCancel === false}>
          {cancelLabel || 'Cancel'}
        </Button>
        <Button
          primary
          onClick={onSubmit}
          disabled={!onSubmit || canSubmit === false}
          sending={sending}
        >
          {submitLabel || 'Submit'}
        </Button>
      </SubmitArea>
    </ActionsArea>
  )
}

export default ModalActionsBar
