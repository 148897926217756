import _ from 'lodash'

import {
  LoadingContainer,
  Model,
  Panels,
  Modal,
  Bar,
  Select,
  Input,
  NumericInput,
  Form,
  Page,
} from 'peach/components'
import { usePaymentInstrumentsQuery } from 'peach/data'
import { formatAddress, combineStrings } from 'peach/helpers'
import { useApi } from 'peach/hooks'
import { usePersonId } from 'peach/scopes/person'

const PersonPaymentInstruments = () => {
  const api = useApi()
  const personId = usePersonId()

  const [paymentInstruments, query] = usePaymentInstrumentsQuery()

  const [externalPaymentInstruments, externalQuery] =
    usePaymentInstrumentsQuery({
      isExternal: true,
    })

  const renderList = (title, instruments) => (
    <Page.Full>
      <Model.List
        columns={[
          {
            label: 'Instrument',
            renderJson: (item) =>
              _.pick(item, ['nickname', 'instrumentType', 'verified']),
          },
          {
            label: 'Details',
            renderJson: (item) => {
              const vals = _.pick(item, [
                'accountHolderName',
                'accountHolderType',
                'accountNumberLastFour',
                'accountType',
                'cardType',
                'cardNumberLastFour',
                'externalId',
                'failureReason',
                'failureDescriptionShort',
                'inactiveReason',
                'network',
                'issuer',
              ])
              return _.pickBy({
                address: formatAddress(item.address),
                fullName: combineStrings([item.firstName, item.lastName]),
                expiration: combineStrings(
                  [item.expirationMonth, item.expirationYear],
                  ' / ',
                ),
                ...vals,
              })
            },
          },
        ]}
        json={instruments}
        title={title}
        withTimestamps
      />
    </Page.Full>
  )

  const sendCreatePaymentInstrument = async (newInstrument) => {
    const { force, ...rest } = newInstrument
    await api.person.paymentInstruments.post({
      pathArgs: { personId },
      queryParams: { force },
      body: rest,
    })
    query.refetch()
    externalQuery.refetch()
  }

  const address = (
    <Form.Provider formKey='address' section>
      <Input formKey='addressLine1' />
      <Input formKey='addressLine2' />
      <Input formKey='city' />
      <Input formKey='countyOrRegion' />
      <Input formKey='state' />
      <Input formKey='postalCode' />
      <Input formKey='country' />
      <Input formKey='POBox' />
    </Form.Provider>
  )

  const canBeExternal = (state) =>
    _.includes(['bankAccount', 'card', 'check'], state.instrumentType)

  const createPaymentInstrument = (
    <Modal
      initialValue={{ force: false, status: 'inactive', verified: false }}
      onSubmit={sendCreatePaymentInstrument}
      title='Create Payment Instrument'
      trigger
    >
      <Select
        formKey='instrumentType'
        options={['bankAccount', 'card', 'check']}
      />
      <Form.If if={canBeExternal}>
        <Select boolean formKey='isExternal' />
      </Form.If>
      <Input formKey='nickname' />
      <Select formKey='status' options={['active', 'inactive', 'pending']} />
      <Select boolean formKey='verified' />

      <Form.If if={{ instrumentType: 'bankAccount' }} label='Bank Account'>
        <Input formKey='accountNumber' />
        <Input formKey='routingNumber' />

        <Select formKey='accountType' options={['checking', 'savings']} />
        <Select
          formKey='accountHolderType'
          options={['business', 'personal']}
        />
        <Input formKey='accountHolderName' />
      </Form.If>

      <Form.If if={{ instrumentType: 'card' }} label='Card'>
        <Select formKey='accountType' options={['credit', 'debit']} />
        <Input formKey='issuer' />
        <Select
          formKey='network'
          options={['visa', 'mastercard', 'discover', 'americanExpress']}
        />
        <Input formKey='firstName' />
        <Input formKey='lastName' />
        <Input formKey='cardNumber' />
        <NumericInput formKey='expirationMonth' />
        <NumericInput formKey='expirationYear' />
        <Input formKey='cvv' />
        {address}
      </Form.If>

      <Form.If if={{ instrumentType: 'check' }} label='Check'>
        <Input formKey='accountNumber' />
        <Input formKey='routingNumber' />
        <Input formKey='checkNumber' />
        <Select
          formKey='accountHolderType'
          options={['business', 'personal']}
        />
        <Input formKey='accountHolderName' />
        {address}
      </Form.If>
      <Select boolean formKey='force' />
    </Modal>
  )

  const header = (
    <Bar right={createPaymentInstrument} title='Payment Instruments' />
  )

  return (
    <Panels header={header}>
      <LoadingContainer queries={[query, externalQuery]}>
        {renderList('Payment Instruments', paymentInstruments)}
        {renderList('External Payment Instruments', externalPaymentInstruments)}
      </LoadingContainer>
    </Panels>
  )
}

export default PersonPaymentInstruments
