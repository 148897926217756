import {
  Select,
  ColorInput,
  Textarea,
  Modal,
  Form,
  Input,
  Image,
} from 'peach/components'

const Timezone = () => (
  <Modal.Page page='Timezone'>
    <Input formKey='timezone' />
    <Select boolean formKey='useExternalIdInUI' />
  </Modal.Page>
)

const BorrowerVerification = () => (
  <Modal.Page formKey='borrowerVerification' page='Borrower Verification'>
    <Input formKey='crmDefaultTypeId' />
  </Modal.Page>
)

const Documents = () => (
  <Modal.Page formKey='documents' page='Documents'>
    <Input formKey='signing_key' />
    <Input formKey='signing_key_password_token' />
    <Input formKey='signer_contact' />
    <Input formKey='signer_location' />
  </Modal.Page>
)

const Compliance = () => (
  <Modal.Page formKey='compliance' page='Compliance'>
    <Select boolean formKey='no_review_task_for_autocreated_cases' />
    <Select boolean formKey='no_review_task_for_autoupdated_cases' />
    <Input formKey='rndCompanyId' />
  </Modal.Page>
)

const Evidence = () => (
  <Modal.Page formKey='evidence' page='Evidence'>
    <Select boolean formKey='makePayment' />
    <Select boolean formKey='alwaysCaptureHtml' />
  </Modal.Page>
)

const BrandAssets = ({ value }) => (
  <Form.Provider formKey='brandAssets'>
    <Modal.Page page='Colors'>
      <Form.Provider formKey='colors'>
        <ColorInput formKey='primary' />
        <ColorInput formKey='secondary' />
        <ColorInput formKey='tertiary' />
      </Form.Provider>
    </Modal.Page>

    <Modal.Page page='Copies'>
      <Form.Provider formKey='copies'>
        <Textarea formKey='consent' />
        <Textarea formKey='disclosures' />
        <Textarea
          formKey='borrowerLoginConsent'
          note='present => use custom text, null => use default value,  empty string => disable consent in UI'
          nullable
        />
      </Form.Provider>
    </Modal.Page>

    <Modal.Page page='Logos'>
      <Form.Provider formKey='logos'>
        <Form.Section label='App Large Centered'>
          <Input
            formKey='appLargeCentered'
            label='URL'
            note='400 x 64 (AR 50:8 aka 25:4) (1200 x 192 asset @ 3x)'
          />
          <Image
            backgroundPosition='center'
            border
            height={64}
            url={value.brandAssets?.logos?.appLargeCentered}
            width={400}
          />
        </Form.Section>

        <Form.Section label='App Regular Navigation'>
          <Input
            formKey='appRegularNavigation'
            label='URL'
            note='304 x 48 (AR 38:6 aka 19:3) (912 x 144 asset @ 3x)'
          />
          <Image
            backgroundPosition='left'
            border
            height={48}
            url={value.brandAssets?.logos?.appRegularNavigation}
            width={304}
          />
        </Form.Section>

        <Form.Section label='App Compact Navigation'>
          <Input
            formKey='appCompactNavigation'
            label='URL'
            note='200 x 32 (AR 25:4) (600 x 96 asset @ 3x)'
          />
          <Image
            backgroundPosition='left'
            border
            height={32}
            url={value.brandAssets?.logos?.appCompactNavigation}
            width={200}
          />
        </Form.Section>

        <Form.Section label='Legacy'>
          <Input formKey='primary' />
          <Input formKey='small' />
          <Input formKey='appRegularLeftNavigation' />
          <Input formKey='appCompactLeftNavigation' />
        </Form.Section>
      </Form.Provider>
    </Modal.Page>
  </Form.Provider>
)

const Support = () => (
  <Modal.Page page='Support'>
    <Form.Provider formKey='support'>
      <Input formKey='phone' />
      <Input formKey='email' />
      <Input formKey='departmentName' />
      <Form.Provider formKey='mailingAddress' section>
        <Input formKey='addressLine1' />
        <Input formKey='addressLine2' />
        <Input formKey='city' />
        <Input formKey='state' />
        <Input formKey='postalCode' />
        <Input formKey='country' />
      </Form.Provider>
      <Input formKey='outboundCallRecordingConsentScript' />
      <Input formKey='privacyPolicyUrl' />
      <Input formKey='phoneSupportPleaseHoldMessage' />
      <Select boolean formKey='enableVoicemailWhenClosed' />
      <Input formKey='phoneSupportClosedMessage' />
      <Input formKey='phoneSupportDisabledMessage' />
      <Input formKey='phoneSupportClosedLeaveVoicemailMessage' />
      <Select boolean formKey='enableVoicemailWhenNoAgents' />
      <Input formKey='phoneSupportNoAgentsLeaveVoicemailMessage' />
      <Select boolean formKey='recordAllInboundVoiceCalls' />
      <Select boolean formKey='recordAllOutboundVoiceCalls' />
      <Input formKey='textSupportRejectUnknownNumberMessage' />
      <Input formKey='textSupportPleaseWaitMessage' />
      <Input formKey='textSupportClosedPleaseWaitMessage' />
      <Input formKey='textSupportFindNewAgentPleaseWaitMessage' />
      <Input formKey='textSupportDisabledMessage' />
    </Form.Provider>
  </Modal.Page>
)

const Links = () => (
  <Modal.Page page='Links'>
    <Form.Provider formKey='links'>
      <Input
        formKey='helpUrl'
        note='The help link used in the account settings menu'
      />
      <Input formKey='termsOfUseUrl' />
      <Input formKey='privacyPolicyUrl' />
    </Form.Provider>
  </Modal.Page>
)

const Communication = () => (
  <Modal.Page page='Communication'>
    <Form.Provider formKey='communication'>
      <Input formKey='text_from_number' />
      <Form.Provider formKey='email_from' section>
        <Input formKey='name' />
        <Input formKey='address' />
      </Form.Provider>
      <Form.Provider formKey='address_from' section>
        <Input formKey='addressLine1' />
        <Input formKey='addressLine2' />
        <Input formKey='city' />
        <Input formKey='state' />
        <Input formKey='postalCode' />
      </Form.Provider>
    </Form.Provider>
  </Modal.Page>
)

const Payment = () => (
  <Modal.Page formKey='payment'>
    <Select boolean formKey='disablePaymentProcessing' />
    <Select boolean formKey='scheduleAutopayTransactions' />
    <Select boolean formKey='processedByPeach' />

    <Form.Provider formKey='allowedCreditCardTx' section>
      <Select boolean formKey='payment' />
      <Select boolean formKey='downPayment' />
    </Form.Provider>

    <Input formKey='bypassCollisionCheck' />
    <Input
      formKey='transactionLimitAmount'
      label='Transaction Limit Amount'
      placeholder='integer'
    />
    <Input
      formKey='maxNumberNewPaymentMethodsIn30Days'
      label='Max Number New Payment Methods In 30 Days'
      placeholder='number'
    />

    <Form.List add formKey='externalTransactions' remove>
      <Input formKey='transactionType' />
      <Input formKey='statusTransition' />
      <Form.Provider formKey='transitionLogic' section>
        <Input formKey='type' />
        <Input formKey='dayType' />
        <Input formKey='numDays' label='Numer of days' placeholder='Integer' />
      </Form.Provider>
    </Form.List>
  </Modal.Page>
)

const PaymentTypes = () => (
  <Modal.Page formKey='paymentTypes'>
    <Select boolean formKey='ach' />
    <Select boolean formKey='check' />
    <Select boolean formKey='debitCard' />
    <Select boolean formKey='creditCard' />
    <Select boolean formKey='payroll' />
    <Select boolean formKey='paymentNetwork' />
    <Select boolean formKey='moneyOrder' />
    <Select boolean formKey='wire' />

    <Form.Provider formKey='achVerification' section>
      <Select boolean formKey='plaid' />
      <Select boolean formKey='microdeposit' />
    </Form.Provider>

    <Form.Provider formKey='balanceCheck' section>
      <Select boolean formKey='plaid' />
    </Form.Provider>
  </Modal.Page>
)

const OpenHours = () => (
  <Modal.Page formKey='openHours'>
    <Form.Provider formKey='businessHours' section>
      <Input formKey='normal' />
      <Form.List add formKey='holidays' remove>
        <Input formKey='name' label='Name' required />
        <Input formKey='date' label='Date' placeholder='An ISO 8601 date' />
        <Input formKey='hours' />
      </Form.List>
    </Form.Provider>
  </Modal.Page>
)

const Search = () => (
  <Modal.Page formKey='search'>
    <Form.Provider formKey='indexing' section>
      <Select boolean formKey='enabled' />
    </Form.Provider>
  </Modal.Page>
)

const options = [
  { label: 'Show', value: 'visible' },
  { label: 'Hide', value: 'hidden' },
]

const VisibilitySelect = (props) => (
  <Select radio {...props} options={options} />
)

const UI = () => (
  <Modal.Page formKey='ui'>
    <VisibilitySelect formKey='mobileFooter' />
    <VisibilitySelect formKey='mobileHeader' />
    <VisibilitySelect formKey='mobileDisclosures' />

    <Form.Provider formKey='manageAutopayPage' section>
      <VisibilitySelect formKey='minimumPaymentDue' />
      <VisibilitySelect formKey='statementBalance' />
    </Form.Provider>

    <Form.Provider formKey='makePaymentPage' section>
      <VisibilitySelect formKey='remainingBalance' />
    </Form.Provider>

    <Form.Provider formKey='managePaymentMethodsPage' section>
      <Form.Provider formKey='borrower' section>
        <VisibilitySelect formKey='check' />
        <VisibilitySelect formKey='creditCard' />
        <VisibilitySelect formKey='debitCard' />
        <VisibilitySelect formKey='payroll' />
        <VisibilitySelect formKey='paymentNetwork' />
        <VisibilitySelect formKey='wire' />
        <VisibilitySelect formKey='moneyOrder' />
        <VisibilitySelect formKey='deleteButton' />
      </Form.Provider>

      <Form.Provider formKey='agent' section>
        <VisibilitySelect formKey='check' />
        <VisibilitySelect formKey='creditCard' />
        <VisibilitySelect formKey='debitCard' />
        <VisibilitySelect formKey='payroll' />
        <VisibilitySelect formKey='paymentNetwork' />
        <VisibilitySelect formKey='wire' />
        <VisibilitySelect formKey='moneyOrder' />
        <VisibilitySelect formKey='deleteButton' />
      </Form.Provider>
    </Form.Provider>

    <VisibilitySelect formKey='help' />
    <VisibilitySelect formKey='signOut' />
    <VisibilitySelect formKey='accountSettings' />
    <VisibilitySelect formKey='contactUs' />

    <Form.Provider formKey='loanOptions' section>
      <VisibilitySelect formKey='managePaymentMethods' />
      <VisibilitySelect formKey='makePayment' />
      <VisibilitySelect formKey='changeDueDate' />
      <VisibilitySelect formKey='getPayoffStatement' />
      <VisibilitySelect formKey='changeNickname' />
      <VisibilitySelect formKey='manageAutopay' />
      <VisibilitySelect formKey='drawFunds' />
    </Form.Provider>

    <Form.Provider formKey='accountSettingsPage' section>
      <VisibilitySelect formKey='profile' />
      <VisibilitySelect formKey='contactInformation' />
      <VisibilitySelect formKey='commPreferences' />
      <VisibilitySelect formKey='additionalContacts' />
      <VisibilitySelect formKey='paymentMethods' />
      <VisibilitySelect formKey='uploadDocs' />
    </Form.Provider>

    <Form.Provider formKey='accountSummaryPage' section>
      <VisibilitySelect formKey='summary' />
      <VisibilitySelect formKey='loanTerms' />
      <VisibilitySelect formKey='balancesDetails' />
      <VisibilitySelect formKey='promotions' />
      <VisibilitySelect formKey='merchantDetails' />
      <VisibilitySelect formKey='cardDetails' />
    </Form.Provider>

    {/* <Form.Provider formKey='agentDashboardTools' section>
        <Select boolean formKey='enabled' />
      </Form.Provider> */}
  </Modal.Page>
)

const Auth = () => (
  <Modal.Page formKey='auth'>
    <Form.Provider formKey='saml' section>
      <Form.Provider formKey='metadataUrl'>
        <Input formKey='borrower' />
        <Input formKey='agent' />
        <Input formKey='admin' />
      </Form.Provider>
    </Form.Provider>
  </Modal.Page>
)

const Statements = () => (
  <Modal.Page formKey='statements'>
    <Select formKey='defaultStatementChannel' options={['email', 'mail']} />
    <Select boolean formKey='statementsEnabled' />
    <Select boolean formKey='isMailStatementAllowed' />
  </Modal.Page>
)

const Zendesk = () => (
  <Modal.Page formKey='zendesk'>
    <Form.Provider formKey='sso' section>
      <Select boolean formKey='enabled' />
      <Input formKey='subdomain' />
      <Input formKey='sharedSecretToken' />
    </Form.Provider>
  </Modal.Page>
)

const Tasks = () => (
  <Modal.Page formKey='tasks'>
    <Select
      formKey='disableTaskTypes'
      multi
      options={[
        'reviewAutoCreatedCase',
        'handleInboundInteraction',
        'handleUnboundInboundInteraction',
        'reviewDocumentUpload',
        'reviewSnoozedCase',
        'makeScheduledCall',
        'reviewEscalatedCaseAgent',
        'reviewEscalatedCaseAgentUrgent',
        'reviewEscalatedCaseTeam',
        'reviewEscalatedCaseTeamUrgent',
        'reviewAutoUpdatedCase',
        'answerInboundVoiceCall',
        'reviewCaseInstructions',
        'replyToNewConvo',
        'replyToNewText',
        'replyToNewWebChat',
        'replyToAbandonedWebChat',
        'reviewVoicemail',
        'smartReviewCase',
      ]}
    />
    <Select boolean formKey='disableTaskRouter' />

    <Form.Provider formKey='channelCapacities' section>
      <Input
        formKey='default'
        label='Default'
        placeholder='Integer or null >= 0'
      />
      <Input formKey='voice' label='Voice' placeholder='Integer or null >= 0' />
      <Input formKey='chat' label='Chat' placeholder='Integer or null >= 0' />
      <Input formKey='email' label='Email' placeholder='Integer or null >= 0' />
      <Input formKey='text' label='Text' placeholder='Integer or null >= 0' />
    </Form.Provider>
    <Select boolean formKey='enableCaseOwnership' />
  </Modal.Page>
)

const Monitoring = () => (
  <Modal.Page formKey='monitoring'>
    <Select
      formKey='criteria'
      options={['borrowerStatus', 'borrowerAndLoanStatus']}
    />
    <Select
      formKey='scraCaseCreationCriteria'
      options={['borrowerOnly', 'borrowerAndLoan']}
    />
  </Modal.Page>
)

const CompanyConfigForm = ({ value }) => (
  <>
    <BorrowerVerification />
    <Documents />
    <Compliance />
    <Evidence />
    <Communication />
    <Support />
    <Payment />
    <PaymentTypes />
    <Timezone />
    <OpenHours />
    <BrandAssets value={value} />
    <Modal.Page page='Require Borrower Identity'>
      <Select boolean formKey='requireBorrowerIdentity' />
    </Modal.Page>

    <Modal.Page page='Address Validation'>
      <Input formKey='addressValidation' />
    </Modal.Page>

    <Modal.Page page='Allow Partial Address'>
      <Select boolean formKey='allowPartialAddress' />
    </Modal.Page>

    <Modal.Page page='Force Case Id On Agent Actions'>
      <Select boolean formKey='forceCaseIdOnAgentActions' />
    </Modal.Page>

    <Search />

    <Auth />

    <Modal.Page page='Credit Counseling Services Number'>
      <Input formKey='creditCounselingServicesNumber' />
    </Modal.Page>

    <Links />

    <UI />
    <Statements />

    <Zendesk />

    <Tasks />

    <Monitoring />

    <Modal.Page page='Raw Config'>
      <Textarea.Json formKey={false} />
    </Modal.Page>
  </>
)
export default CompanyConfigForm
