import { forwardRef } from 'react'

import { withFormValue, withLabel } from 'peach/decorators'

import { RawInput } from './Input'
import useNumericValue from './useNumericValue'

const NumericInput = forwardRef((props, ref) => {
  const { value, onChange, formKey, nullable, ...rest } = props

  const [str, setStr] = useNumericValue({ value, onChange })

  const $nullable = nullable && value !== null

  return (
    <RawInput
      {...rest}
      value={str}
      onChange={setStr}
      nullable={$nullable}
      delay={0}
      ref={ref}
    />
  )
})

NumericInput.displayName = 'NumericInput'

export default withFormValue(withLabel(NumericInput))
