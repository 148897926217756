import { css } from 'styled-components'

import color from 'peach/helpers/color/color'

const buttonStyles = css<{
  iconLeft?: boolean
  iconRight?: boolean
  primary?: boolean
}>`
  position: relative;
  border: 1px solid
    ${(p) => color.borderify(p.primary ? p.theme.highlight : p.theme.banner)};
  border-radius: 4px;
  background-color: ${(p) => (p.primary ? p.theme.highlight : p.theme.banner)};
  cursor: pointer;
  padding: 6px ${(p) => (p.iconRight ? '12px' : '16px')} 6px
    ${(p) => (p.iconLeft ? '12px' : '16px')};
  color: ${(p) => (p.primary ? p.theme.invertedText : p.theme.text)};
  font-size: 16px;

  &:disabled {
    background-color: ${(p) =>
      color.disableify(p.primary ? p.theme.highlight : p.theme.banner)};
    cursor: default;
    color: ${(p) =>
      color.disableify(p.primary ? p.theme.invertedText : p.theme.text)};
  }

  &:not(:disabled):hover {
    background-color: ${(p) =>
      color.getHoverColor(p.primary ? p.theme.highlight : p.theme.banner)};
  }

  &:focus {
    border: 0 0 0 3px ${(p) => p.theme.highlight};
  }

  &,
  * {
    transition: background-color 0.15s, border-color 0.15s, color 0.05s;
  }

  ${(p) =>
    p.primary &&
    css`
      &:focus {
        box-shadow: none;

        &::after {
          position: absolute;
          inset: -4px;
          border: 1px solid ${(p) => p.theme.highlight};
          border-radius: inherit;
          content: '';
          pointer-events: none;
        }
      }
    `}
`

export default buttonStyles
