import Select from 'peach/components/Select/Select'
import { useBorrowersQuery } from 'peach/data'
import { getName } from 'peach/helpers'

const BorrowerSelect = (props) => {
  const [borrowers, query] = useBorrowersQuery()

  const options = _.map(borrowers, (borrower) => ({
    value: borrower.id,
    label: getName(borrower),
  }))

  return <Select query={query} {...props} options={options} />
}

export default BorrowerSelect
