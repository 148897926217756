import { LoadingContainer, Json, Page } from 'peach/components'
import { useReadData, useApi } from 'peach/hooks'

const Home = () => {
  const api = useApi()

  const [peachy, loadingPeachy, peachyError] = useReadData(() =>
    api.peach.peachy.get(),
  )

  const [pit, loadingPit, pitError] = useReadData(() => api.peach.pit.get())

  const [flags, loadingFlags, flagsError] = useReadData(() =>
    api.peach.flags.get(),
  )

  return (
    <LoadingContainer
      error={peachyError || pitError || flagsError}
      loading={loadingPeachy || loadingPit || loadingFlags}
    >
      <Page>
        <Json.Table json={peachy} title='Peachy' />
        <Json.Table json={pit} title='Pit' />
        <Json.Table json={flags} title='Flags' />
      </Page>
    </LoadingContainer>
  )
}

export default Home
