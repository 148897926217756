import JsonView from 'react-json-view'
import styled, { useTheme } from 'styled-components'

import color from 'peach/helpers/color/color'

import JsonValue from './JsonValue'

const Wrapper = styled.div`
  padding: 8px;
  font-size: 12px;

  & * {
    line-height: 1.3;
  }
`

const Json = ({ name, json, type, jsonKey, collapsed, ...rest }) => {
  const { background } = useTheme()

  const isLight = color.isLight(background)

  return (
    <Wrapper>
      {!_.isObject(json) || _.isEmpty(json) ? (
        <JsonValue type={type} jsonKey={jsonKey} value={json} />
      ) : (
        <JsonView
          src={json}
          name={name || false}
          theme={isLight ? 'rjv-default' : 'monokai'}
          indentWidth={4}
          collapsed={collapsed ?? 4}
          displayDataTypes={false}
          enableClipboard={false}
          collapseStringsAfterLength={50}
          {...rest}
        />
      )}
    </Wrapper>
  )
}

export default Json
