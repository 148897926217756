import { useDataQuery, useQueryApi } from '../useQueryApi'

const usePersonCaseQuery = (options) => {
  const [api, { personId, caseId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['people', personId, 'cases', caseId],

    queryFn: async () =>
      api.person.case.get({
        pathArgs: { personId, caseId },
      }),

    enabled: !!(personId && caseId),
  })
}

export default usePersonCaseQuery
