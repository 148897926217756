// import FullPage from '../Page/FullPage'
import JsonDiff from 'jsondiffpatch-for-react'

import { CompactTabs } from '../../CompactTabs/CompactTabs'
import { useFormValidation } from '../../Form/FormProvider'
import { collectWhere } from '../../Form/FormProvider/FormDebugger'
import Json from '../../Json'
import Page from '../../Page'
import Panels from '../../Panels/Panels'
import JsonTextarea from '../../Textarea/JsonTextarea'

const ModalDebugger = ({ initialValue, value, onChange }) => {
  const validation = useFormValidation()

  const errors = collectWhere(validation, 'errorMessage')

  const hasChanged = collectWhere(validation, 'hasChanged')

  const hasBlurred = collectWhere(validation, 'hasBlurred')

  return (
    <CompactTabs.Container>
      <Panels header={<CompactTabs />}>
        <CompactTabs.Page page='Value'>
          <Page.Full>
            <Json.Table page json={value} compact />
          </Page.Full>
        </CompactTabs.Page>

        <CompactTabs.Page page='Json'>
          <Page.Full>
            <Json json={value} compact collapsed={10} />
          </Page.Full>
        </CompactTabs.Page>

        <CompactTabs.Page page='Raw'>
          <Page.Full>
            <JsonTextarea value={value} onChange={onChange} />
          </Page.Full>
        </CompactTabs.Page>

        <CompactTabs.Page page='Diff'>
          <Page.Full>
            <JsonDiff show left={initialValue} right={value} />
          </Page.Full>
        </CompactTabs.Page>

        <CompactTabs.Page page='Validation'>
          <Page.Full>
            <Json json={validation} compact collapsed={10} />
          </Page.Full>
        </CompactTabs.Page>

        <CompactTabs.Page page='Errors'>
          <Page.Full>
            <Json json={errors} compact collapsed={10} />
          </Page.Full>
        </CompactTabs.Page>

        <CompactTabs.Page page='Changed'>
          <Page.Full>
            <Json json={hasChanged} compact collapsed={10} />
          </Page.Full>
        </CompactTabs.Page>

        <CompactTabs.Page page='Blurred'>
          <Page.Full>
            <Json json={hasBlurred} compact collapsed={10} />
          </Page.Full>
        </CompactTabs.Page>
      </Panels>
    </CompactTabs.Container>
  )
}

export default ModalDebugger
