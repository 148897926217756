import { useReducer, useRef, useEffect, useMemo, useCallback } from 'react'

import useStableCallback from '../useStableCallback/useStableCallback'

const getInitialState = () => ({
  sending: false,
  resp: undefined,
  error: undefined,
})

const reducer = (state, { type, resp, error }) => {
  const { sending } = state

  switch (type) {
    case 'sending':
      return { resp: undefined, sending: true, error: undefined }
    case 'resp':
      return { sending: false, resp: resp || {}, error: undefined }
    case 'error':
      return {
        sending: false,
        resp: undefined,
        error: error || new Error('Unknown Error'),
      }
    case 'clear':
      return !sending ? getInitialState() : state
    default:
      return state
  }
}

const useWrite = (func, options = {}) => {
  const { throwOnError } = options
  const mounted = useRef(true)

  useEffect(() => () => (mounted.current = false), [])

  const [state, dispatch] = useReducer(reducer, undefined, getInitialState)

  const { sending, resp, error } = state

  const send = useStableCallback(async (...args) => {
    if (!sending) {
      dispatch({ type: 'sending' })

      try {
        const resp = await func(...args)

        if (mounted.current) {
          dispatch({ type: 'resp', resp })
        }
        return resp
      } catch (error) {
        if (mounted.current) {
          dispatch({ type: 'error', error })
        }

        if (throwOnError) {
          throw error
        } else {
          console.error('useWrite caught an error', error)
        }
      }
    }
  })

  const clear = useCallback(() => dispatch({ type: 'clear' }), [])

  const result = useMemo(() => {
    const res = [send, sending, resp, error, clear]
    _.extend(res, { send, resp, sending, error, clear })
    return res
  }, [send, sending, resp, error, clear])

  return result
}

export default useWrite
