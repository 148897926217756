import { useParams } from 'react-router-dom'

import { PersonManager } from 'peach/scopes/person'

import Person from './Person'

const PeopleRoutes = () => {
  const { personId } = useParams()

  return (
    <PersonManager personId={personId}>
      <Person />
    </PersonManager>
  )
}

export default PeopleRoutes
