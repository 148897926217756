import { forwardRef } from 'react'

import { styled } from 'styled-components'

import color from 'peach/helpers/color/color'

import { useDarkModeInfo } from 'core/theme/DarkModeProvider'

const colors = {
  object: '#444',
  emptyObject: '#AAA',
  array: '#444',
  emptyArray: '#AAA',
  key: '#222',
  text: '#666',
  boolean: '#573b79',
  string: '#d28140',
  emptyString: '#AAA',
  id: '#7e96c4',
  number: '#7e96c4',
  date: '#1050c0',
  amount: '#3d9970',
  undefined: '#f8b8b7',
  null: '#f8b8b7',
  index: '#888',
  unknown: '#444',
}

const keys = ['text', 'key']

const getColor = (type, darkMode) => {
  const rawColor = colors[type] || colors.unknown
  return darkMode ? color.preventTooDark(rawColor) : rawColor
}

const DisplayValue = styled.span`
  white-space: pre-wrap;
  word-break: ${(p) => (p.type === 'index' ? 'keep-all' : 'break-word')};
  color: ${(p) =>
    keys.includes(p.type) ? p.theme.text : getColor(p.type, p.darkMode)};
  font-family: monospace;
  font-weight: ${(p) => (p.type === 'key ' ? '600' : '')};
`

const JsonDisplayValue = forwardRef((props, ref) => {
  const { darkMode } = useDarkModeInfo()

  return <DisplayValue darkMode={darkMode} {...props} ref={ref} />
})

JsonDisplayValue.displayName = 'JsonDisplayValue'

export default JsonDisplayValue
