import { useParams } from 'peach/router'

import { useAuthState } from 'core/auth/AuthProvider'

const useCombinedParams = (options) => {
  const { companyId: sessionCompanyId } = useAuthState()

  const params = useParams()

  return {
    companyId: sessionCompanyId,
    ...params,
    ...options,
  }
}

export default useCombinedParams
