import { useRouteMatch } from 'react-router-dom'
import { styled } from 'styled-components'

import { humanizeKey } from 'peach/helpers'

import { Crumb } from './Crumb'
import CrumbsList from './CrumbsList'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
`

const chevrons = '⟩⟫❯»›▶︎▷▸☞'.split('')

const Breadcrumbs = () => (
  <CrumbsList separator={'›' || _.sample(chevrons)} Wrapper={Wrapper} />
)

const lastPiece = (str = '') => _.last(str.split('/'))

const idRegex = /([A-Z][A-Z]-)/

const formatString = (str = '') =>
  str.slice(0, 3).match(idRegex)
    ? str
    : _.includes(str, '-')
    ? _.str.titleize(_.str.humanize(str))
    : humanizeKey(str)

const Breadcrumb = (props) => {
  const { url } = useRouteMatch() || {}

  const { title, to, children } = props

  const $to = to || url

  const displayTitle =
    children || title || formatString(lastPiece($to)) || '???'

  return <Crumb title={displayTitle} to={$to} />
}

export { Breadcrumbs, Breadcrumb }
