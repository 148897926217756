const getNewValue = (newValue, prevValue) =>
  _.isFunction(newValue) ? newValue(prevValue) : newValue

const resolve = (newValue, defaultValue) => (prevValue) =>
  getNewValue(newValue, prevValue ?? defaultValue)

const removeKey = (obj, key) => {
  const newObj = { ...obj }
  delete newObj[key]
  return newObj
}

export { getNewValue, resolve, removeKey }
