import { useDataQuery, useQueryApi } from '../useQueryApi'

const useEmployeeQuery = (options) => {
  const [api, { companyId, employeeId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['companies', companyId, 'employees', employeeId],

    queryFn: () =>
      api.company.employee.get({
        pathArgs: { companyId, employeeId },
      }),

    disabled: !companyId || !employeeId,
  })
}

export default useEmployeeQuery
