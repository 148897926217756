import { styled } from 'styled-components'

import { useAnimatedIsOpen } from 'peach/hooks'

const Content = styled.div`
  position: absolute;
  inset: 0;
  transition: 0.2s;
  opacity: 0;

  &.is-in {
    opacity: 1;
  }

  &.is-out {
    opacity: 0;
  }
`

const FadeInOut = ({ children, isOpen }) => {
  const [isMounted, $isOpen] = useAnimatedIsOpen(isOpen)
  const className = $isOpen ? 'is-in' : 'is-out'
  return isMounted ? <Content className={className}>{children}</Content> : null
}

export default FadeInOut
