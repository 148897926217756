import { useDataQuery, useQueryApi } from '../useQueryApi'

const useVerificationTypesQuery = (options) => {
  const [api, { companyId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['companies', companyId, 'verification-types'],

    queryFn: () =>
      api.company.verificationTypes.get({ pathArgs: { companyId } }),
  })
}

export default useVerificationTypesQuery
