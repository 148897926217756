import { createContext, useContext, useMemo } from 'react'

import styled, { css } from 'styled-components'

const MiniTableContext = createContext({ compact: false, bare: false })

const Wrapper = styled.div`
  padding-top: ${(p) => (p.bare ? '0px' : '8px')};
  padding-bottom: ${(p) => (p.bare ? '0px' : '8px')};
  color: ${(p) => p.theme.text};
`

const Content = styled.div`
  border: ${(p) => (p.bare ? 'none' : `1px solid ${p.theme.border}`)};
  border-radius: 2px;
  min-height: 16px;
  overflow: hidden;
  font-size: 12px;
`

const Title = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.border};
  background-color: ${(p) => p.theme.banner};
  padding: ${(p) => (p.compact ? '4px' : '8px')};
  color: ${(p) => p.theme.text};
  font-size: 14px;
  font-weight: 600;
`

const Table = styled.table`
  width: 100%;
  table-layout: ${(p) => (p.fixed ? 'fixed' : 'auto')};
  border-collapse: collapse;
`

const wrapItem = (item, Class, index) =>
  item && item.type === Class ? (
    item
  ) : (
    <Class key={`mini-table-${Class.type}-${item}-${index}`}>{item}</Class>
  )

const wrapList = (children, Class) =>
  React.Children.map(children, (cell, index) => wrapItem(cell, Class, index))

const Tr = styled.tr`
  border-bottom: 1px solid ${(p) => p.theme.border};
  background-color: ${(p) => p.theme.background};

  &:nth-child(even) {
    background-color: ${(p) => p.theme.offset};
  }

  &:last-child {
    border-bottom: none;
  }
`

const topCss = css`
  padding-top: 12px;
  padding-bottom: 12px;
  vertical-align: top;
`

const Td = styled.td`
  border-left: 1px solid ${(p) => p.theme.border};
  padding: ${(p) => (p.bare ? '0' : p.compact ? '4px 6px' : '8px')};
  width: ${(p) => p.width || 'auto'};
  vertical-align: top;
  text-align: left;

  ${(p) => p.top && topCss}

  &:first-child {
    border-left: none;
  }
`

const Cell = (props) => {
  const { compact } = useContext(MiniTableContext)
  return <Td {...props} compact={compact} />
}

const Row = ({ children }) => <Tr>{wrapList(children, Cell)}</Tr>

const Th = styled.th`
  border-bottom: 1px solid ${(p) => p.theme.border};
  border-left: 1px solid ${(p) => p.theme.border};
  background-color: ${(p) => p.theme.offset};
  padding: ${(p) => (p.compact ? '6px' : '12px')};
  text-align: left;

  &:first-child {
    border-left: none;
  }
`

const HeaderCell = (props) => {
  const { compact } = useContext(MiniTableContext)
  return <Th compact={compact} {...props} />
}

const HeaderRow = ({ children }) => (
  <thead>
    <tr>{wrapList(children, HeaderCell)}</tr>
  </thead>
)

const MiniTable = (props) => {
  const {
    columns = [],
    headerRow,
    children = [],
    title,
    message,
    compact,
    bare,
    fixed,
  } = props

  const header = headerRow || (
    <HeaderRow>{wrapList(columns, HeaderCell)}</HeaderRow>
  )

  const contents = !_.isEmpty(children) ? (
    wrapList(children, Row)
  ) : (
    <tr>
      <td colSpan={_.size(columns) || 100}>
        <div style={{ textAlign: 'center', padding: 20, color: '#888' }}>
          {message || 'Empty List'}
        </div>
      </td>
    </tr>
  )

  const settings = useMemo(() => ({ compact, bare }), [compact, bare])

  return (
    <MiniTableContext.Provider value={settings}>
      <Wrapper bare={bare}>
        <Content bare={bare}>
          {title && <Title compact={compact}>{title}</Title>}
          <Table fixed={fixed}>
            {header}
            <tbody>{contents}</tbody>
          </Table>
        </Content>
      </Wrapper>
    </MiniTableContext.Provider>
  )
}

MiniTable.HeaderRow = HeaderRow
MiniTable.HeaderCell = HeaderCell
MiniTable.Cell = Cell
MiniTable.Row = Row

export { Row, Cell, HeaderRow, HeaderCell }

export default MiniTable
