import { useState } from 'react'

import _ from 'lodash'

import {
  Json,
  Page,
  LoadingContainer,
  Panels,
  Bar,
  Select,
  MiniTable,
  Card,
  Form,
} from 'peach/components'
import { useDocumentsQuery } from 'peach/data'
import { humanizeKey, getLink } from 'peach/helpers'
import { useCompanyId } from 'peach/scopes/company'

/*

  - if type is other, than it should be borrower uploaded 
  - otherwise it's likely a service upload
  - in the future the agents may be able to upload on behalf of a borrower 
  
*/

const filterDocuments = (docs, filters) => {
  let $docs = docs

  const { documentType, uploadedByUserType } = filters || {}

  if (documentType) {
    $docs = _.filter($docs, { type: documentType })
  }
  if (uploadedByUserType) {
    $docs = _.filter(
      $docs,
      (doc) => doc.uploadedBy?.userType === uploadedByUserType,
    )
  }

  return $docs
}

const options = [
  { label: 'Documents', value: true },
  { label: 'Include Archived', value: false },
]

const Documents = () => {
  const companyId = useCompanyId()

  const [filters, setFilters] = useState({
    documentType: undefined,
    uploadedByUserType: 'borrower',
    includeArchived: true,
  })

  const [documents, query] = useDocumentsQuery({
    includeArchived: filters.includeArchived,
  })

  const sortedDocuments = _.sortBy(documents, 'createdAt').reverse()

  const types = _.uniq(_.map(documents, 'type'))

  const filteredDocs = filterDocuments(sortedDocuments, filters)

  const header = (
    <Bar
      right={
        <Form.Provider onChange={setFilters} value={filters}>
          <Select bare formKey='documentType' nullable options={types} />
          <Select
            bare
            formKey='uploadedByUserType'
            nullable
            options={['borrower', 'agent', 'service']}
          />
          <Select bare formKey='includeArchived' options={options} />
        </Form.Provider>
      }
    />
  )

  const columns = ['Document', 'Status', 'Loan ID', 'Timestamps']

  const renderRows = (docs) =>
    _.map(docs, (doc) => {
      const {
        id,
        status,
        archived,
        loanId,
        type,
        uploadedBy,
        createdAt,
        updatedAt,
      } = doc
      const { userType } = uploadedBy || {}
      const displayType = humanizeKey(type)
      const link = getLink({ companyId, ...doc })
      return (
        <MiniTable.Row key={id}>
          <Card model={doc} subtitle={displayType} to={link} />
          <Json.List
            humanizeKeys
            json={{ status, archived, uploadedBy: userType }}
          />
          {loanId}
          <Json.List humanizeKeys json={{ createdAt, updatedAt }} />
        </MiniTable.Row>
      )
    })

  const grouped = _.groupBy(filteredDocs, 'type')
  const renderTable = (docs, type) => (
    <MiniTable
      columns={columns}
      key={type}
      title={humanizeKey(type) + ` (${_.size(docs)})`}
    >
      {renderRows(_.take(docs, 1000))}
    </MiniTable>
  )

  const content =
    _.size(grouped) > 1
      ? _.map(grouped, (group, type) => renderTable(group, type))
      : renderTable(filteredDocs, 'Documents')

  return (
    <Panels header={header}>
      <LoadingContainer query={query}>
        <Page.Full>{content}</Page.Full>
      </LoadingContainer>
    </Panels>
  )
}

export default Documents
