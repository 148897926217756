import { useMemo } from 'react'

import executeRequest from './executeRequest'

const { getFetchOptions } = executeRequest

const useAuthedRequest = (options) => {
  const { apiBase, token, apiKey, on401 } = options

  return useMemo(() => {
    const executeAuthedRequest = (options = {}) => {
      const { unauthorized, ...rest } = options

      if (unauthorized) {
        return executeRequest(rest)
      } else {
        return executeRequest({ apiBase, apiKey, token, ...rest }).catch(
          (error) => {
            if (error.status === 401) {
              on401(error)
            }
            throw error
          },
        )
      }
    }

    executeAuthedRequest.getFetchOptions = (options) =>
      getFetchOptions({ apiBase, apiKey, token, ...options })

    return [executeAuthedRequest]
  }, [token, apiKey, on401, apiBase])
}

export default useAuthedRequest
