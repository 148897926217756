import _ from 'lodash'

import { Json, Page, Button } from 'peach/components'
import { formatMilliseconds } from 'peach/helpers'
import { useReadData, useApi, useNow } from 'peach/hooks'

import { useAuthApi, useAuthState } from 'core/auth/AuthProvider'

const Settings = () => {
  const authState = useAuthState()
  const { renewSession } = useAuthApi()

  const { companyId, personId, userId, employeeId } = authState || {}

  const api = useApi()

  const [permissionsHash] = useReadData(() => api.auth.permissions.get(), [])

  const [person] = useReadData(
    () =>
      api.people.person.get({
        pathArgs: { personId },
      }),
    [personId],
  )

  const [user] = useReadData(
    () =>
      api.company.user.get({
        pathArgs: { companyId, userId },
      }),
    [companyId, userId],
  )

  const [employee] = useReadData(
    () =>
      api.employees.employee.get({
        pathArgs: { employeeId },
      }),
    [employeeId],
  )

  const displayPermissions = _.map(permissionsHash, (permissions, id) => {
    const split = _.map(permissions, (str) => {
      const [type, action] = str.split(':')
      return { type, action }
    })

    const byCategory = _.mapValues(_.groupBy(split, 'type'), (list) =>
      _.map(list, 'action').join(', '),
    )

    return { companyId: id, permissions: byCategory }
  })

  const { expiresAt } = authState
  const now = useNow()

  const expiresIn = formatMilliseconds(expiresAt - now)

  return (
    <Page.Centered>
      <Json.Table json={{ ...authState, expiresIn }} title='Auth State' />
      <Button onClick={renewSession}>Renew Session</Button>
      <Json.Table json={user} title='Session User' />
      <Json.Table json={person} title='Session Person' />
      <Json.Table json={employee} title='Session Employee' />

      <Json.Table json={displayPermissions} title='Session Permissions' />
    </Page.Centered>
  )
}

export default Settings
