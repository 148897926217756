import { styled } from 'styled-components'

import Json from '../../Json'

import { useFormValue, useFormValidation } from './useFormState'

const Wrap = styled.div`
  margin-bottom: 8px;
  border: 1px solid ${(p) => p.theme.border};
  border-radius: 4px;
  background-color: ${(p) => p.theme.offset};
  padding: 4px;
`

const collectWhere = (validation, condition, key) => {
  const fn = (meta) => meta?.[condition]

  const list = []
  const { meta, nested } = validation || {}

  if (fn(meta)) list.push({ key, ...meta })

  _.each(nested, (item, key) => {
    list.push(...collectWhere(item, condition, key))
  })

  return list
}

const collectValidationErrors = (validation) =>
  collectWhere(validation, 'errorMessage')

const FormDebugger = () => {
  const value = useFormValue()
  const validation = useFormValidation()

  const errors = collectValidationErrors(validation)

  return (
    <>
      <Wrap>
        <Json name='errors' json={errors} collapsed={false} />
      </Wrap>
      <Wrap>
        <Json name='validation' json={validation} collapsed={false} />
      </Wrap>
      <Wrap>
        <Json name='nested' json={value} collapsed={false} />
      </Wrap>
    </>
  )
}

export { FormDebugger, collectWhere, collectValidationErrors }
