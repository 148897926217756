import {
  useState,
  useCallback,
  createContext,
  useContext,
  useMemo,
  useEffect,
  forwardRef,
} from 'react'

import { getComponentName } from 'peach/helpers'
import { useStableCallback } from 'peach/hooks'

import useIsOpen from './useIsOpen'

const ActionsContext = createContext({
  actions: [],
  addAction: () => {},
  removeAction: () => {},
  isUnderContainer: false,
})

const ActionsContainer = ({ children }) => {
  const [actions, setActions] = useState([])

  const addAction = useCallback((action) => {
    setActions((actions) => _.uniq(actions.concat(action)), 'id')
  }, [])

  const removeAction = useCallback((id) => {
    setActions((actions) => _.reject(actions, { id }))
  }, [])

  const value = useMemo(
    () => ({ actions, addAction, removeAction, isUnderContainer: true }),
    [actions, addAction, removeAction],
  )

  return (
    <ActionsContext.Provider value={value}>{children}</ActionsContext.Provider>
  )
}

const useActions = () => useContext(ActionsContext)

const useClickAction = ({ label, onClick, disabled, ...rest } = {}) => {
  const [id] = useState(() => _.uniqueId('action-'))

  const hide = _.has(rest, 'show') && !rest.show

  const { addAction, removeAction, isUnderContainer } = useActions()

  const showAction = !hide && label && isUnderContainer

  const $onClick = useStableCallback(onClick)

  useEffect(() => {
    if (showAction) addAction({ id, label, disabled, onClick: $onClick })
    return () => removeAction(id)
  }, [addAction, removeAction, id, showAction, label, disabled, $onClick])
}

const useOpenAction = ({ label, disabled, ...rest } = {}) => {
  // todo: extract onClick so can work for links and regular buttons
  const { isOpen, onOpen, onClose } = useIsOpen(rest)

  useClickAction({ label, onClick: onOpen, disabled })

  return { isOpen, onOpen, onClose }
}

const withOpenAction = (Component) => {
  // todo: merge the functionality of withTrigger into this for case of no under an actions container
  const displayName = getComponentName(Component, 'withActionTrigger')

  const WithActionTrigger = forwardRef((props, ref) => {
    const { action, actionLabel, title, disabled, ...rest } = props

    const label = actionLabel || (title ? title + '…' : null)

    const { isOpen, onOpen, onClose } = useOpenAction({
      ...props,
      label,
    })

    const renderProps = { ref, ...rest, title, isOpen, onOpen, onClose }

    return <Component {...renderProps} />
  })

  WithActionTrigger.displayName = displayName

  return WithActionTrigger
}

export { ActionsContainer, useClickAction, withOpenAction, useActions }
