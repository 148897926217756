import { styled } from 'styled-components'

const Label = styled.label`
  color: ${(p) => p.theme.labelText};
  font-size: 12px;
  display: block;
  font-weight: bold;
  padding: 0 12px 4px;
`

export default Label
