import { Modal, Page, Model, LoadingContainer } from 'peach/components'
import { useCompanyConfigQuery } from 'peach/data'
import { useApi } from 'peach/hooks'
import { useCompanyId } from 'peach/scopes/company'

import CompanyConfigForm from './CompanyConfig/CompanyConfigForm'

const CompanyConfig = () => {
  const companyId = useCompanyId()

  const api = useApi()

  const [config, query] = useCompanyConfigQuery()

  const sendUpdateConfig = async (value) => {
    // If one url is empty, we want to set both to the same url
    const borrowerUrl =
      value?.auth?.saml?.metadataUrl?.borrower ||
      value?.auth?.saml?.metadataUrl?.agent
    const agentUrl = value?.auth?.saml?.metadataUrl?.agent || borrowerUrl
    const adminUrl = value?.auth?.saml?.metadataUrl?.admin || agentUrl

    const updatedBody = {
      ...value,
      auth: {
        saml: {
          metadataUrl: {
            agent: agentUrl,
            borrower: borrowerUrl,
            admin: adminUrl,
          },
        },
      },
    }

    await api.company.config.put({ pathArgs: { companyId }, body: updatedBody })
    query.refetch()
  }

  const updateConfig = (
    <Modal
      initialValue={config}
      onSubmit={sendUpdateConfig}
      render={({ value }) => <CompanyConfigForm value={value} />}
      tabs
      title='Edit Config'
    />
  )

  return (
    <LoadingContainer query={query}>
      <Page.Full>
        {updateConfig}
        <Model.Item json={config} title='Config' />
      </Page.Full>
    </LoadingContainer>
  )
}

export default CompanyConfig
