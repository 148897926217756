import _ from 'lodash'

import {
  Model,
  Page,
  LoadingContainer,
  Panels,
  Bar,
  Modal,
} from 'peach/components'
import { useLoanStatementsQuery, useLoanPeriodsQuery } from 'peach/data'
import { useApi } from 'peach/hooks'
import { useParams } from 'peach/router'

const LoanPeriod = () => {
  const { periodId } = useParams()

  const api = useApi()

  const [periods, periodsQuery] = useLoanPeriodsQuery()

  const [statements, statementsQuery] = useLoanStatementsQuery()

  const period = _.find(periods, { id: periodId })

  const statement = _.find(statements, { periodId })

  const sendGenerateStatement = async () => {
    await api.periods.statement.post({
      pathArgs: { periodId },
    })
    _.delay(() => statementsQuery.refech(), 1000)
  }

  const generateStatement = (
    <Modal
      initialValue={{}}
      onSubmit={sendGenerateStatement}
      title='Generate Statement'
    />
  )

  const header = <Bar title='Period' />

  return (
    <Panels header={header}>
      {generateStatement}
      <LoadingContainer queries={[periodsQuery, statementsQuery]}>
        <Page.Full>
          <Model.Item json={period} title='Period' />
          {statement && <Model.Item json={statement} title='Statement' />}
        </Page.Full>
      </LoadingContainer>
    </Panels>
  )
}

export default LoanPeriod
