import { styled } from 'styled-components'

const Main = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.border};
  background-color: ${(p) => p.theme.offset};
`

const Footer = styled.div`
  border-top: 1px solid ${(p) => p.theme.border};
  background-color: ${(p) => p.theme.offset};
`

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: hidden;
`

const LeftPanel = styled.div`
  border-right: 1px solid ${(p) => p.theme.border};
  background-color: ${(p) => p.theme.offset};
  flex-grow: 0;
  flex-shrink: 0;
  width: ${(p) => p.width};
  overflow: auto;
`

const RightPanel = styled.div`
  border-left: 1px solid ${(p) => p.theme.border};
  background-color: ${(p) => p.theme.offset};
  flex-grow: 0;
  flex-shrink: 0;
  width: ${(p) => p.width};
  overflow: auto;
`

const Center = styled.div`
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
`

const Panels = (props) => {
  const {
    children,
    header,
    footer,
    left,
    right,
    leftWidth = '300px',
    rightWidth = '300px',
  } = props

  return (
    <Main>
      {header && <Header>{header}</Header>}

      <Wrap>
        {left && <LeftPanel width={leftWidth}>{left}</LeftPanel>}

        <Center>{children}</Center>

        {right && <RightPanel width={rightWidth}>{right}</RightPanel>}
      </Wrap>

      {footer && <Footer>{footer}</Footer>}
    </Main>
  )
}

export default Panels
