import { createContext, useContext } from 'react'

import { NavLink as RouterNavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { humanizeKey } from 'peach/helpers'
const BasePathContext = createContext('')
const ActiveNameContext = createContext(undefined)
const TabSelectContext = createContext(() => {})

const DevNavLink = ({ to, name, className, onClick, disabled, ...rest }) => {
  const basePath = useContext(BasePathContext)
  const isActive = useContext(ActiveNameContext) === name
  const onSelect = useContext(TabSelectContext)

  if (to !== undefined) {
    const path = basePath + to
    return (
      <RouterNavLink
        to={path}
        className={className}
        disabled={disabled}
        {...rest}
      />
    )
  } else {
    const handleClick = (e) => {
      onClick?.(e)
      onSelect?.(name, e)
    }
    return (
      <button
        className={className + ' ' + (isActive ? 'active' : '')}
        onClick={handleClick}
        disabled={disabled || (!name && !onClick)}
        {...rest}
      />
    )
  }
}

const activeStyles = css`
  color: ${(p) => p.theme.text};

  &::after {
    border-color: ${(p) => p.theme.highlight};
  }
`

const tabStyles = css`
  display: inline-block;
  position: relative;
  margin: 0;
  margin-right: 6px;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  background-color: transparent;
  cursor: default;
  padding: 7px 10px;
  text-decoration: none;
  line-height: 1;
  color: ${(p) => p.theme.mutedText};
  font-size: 16px;
  font-weight: 500;

  &::after {
    position: absolute;
    right: 8px;
    bottom: 4px;
    left: 8px;
    transition: border-color 0.2s;
    border: 1px solid transparent;
    border-radius: 2px;
    height: 2px;
    content: '';
  }

  &:last-child {
    margin-right: 0;
    border-right: none;
  }

  &:hover {
    cursor: pointer;

    &::after {
      border-color: ${(p) => !p.isActive && p.theme.border};
    }
  }

  &:disabled {
    color: ${(p) => p.theme.disabledText};
  }

  &.active,
  &.active:hover {
    ${activeStyles};
  }

  ${(p) => p.isActive && activeStyles};
`

const Tab = styled(DevNavLink)`
  ${tabStyles};
`

const TabButton = styled.button`
  ${tabStyles};
`
const DevTabs = styled.div`
  display: inline-block;
  border: 1px solid ${(p) => p.theme.border};
  border-radius: 4px;
  background-color: ${(p) => p.theme.background};
  padding: 4px;
  overflow: hidden;
`

const Tabs = ({
  basePath = '',
  activeName,
  onSelect,
  children,
  value,
  onChange,
  options,
  ...rest
}) => {
  if (options) {
    const displayOptions = _.map(options, (option) => {
      const isActive = option === value
      return (
        <TabButton
          key={option}
          isActive={isActive}
          onClick={() => onChange(option)}
        >
          {humanizeKey(option)}
        </TabButton>
      )
    })
    return <DevTabs>{displayOptions}</DevTabs>
  }
  return (
    <BasePathContext.Provider value={basePath}>
      <ActiveNameContext.Provider value={activeName}>
        <TabSelectContext.Provider value={onSelect}>
          <DevTabs>{children}</DevTabs>
        </TabSelectContext.Provider>
      </ActiveNameContext.Provider>
    </BasePathContext.Provider>
  )
}

Tabs.Tab = Tab

export { Tabs, Tab }
