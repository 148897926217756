import { useDataQuery, useQueryApi } from '../useQueryApi'

const usePaymentInstrumentsQuery = ({ isExternal, ...options } = {}) => {
  const [api, { companyId, personId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: [
      'companies',
      companyId,
      'borrowers',
      personId,
      'payment-instruments',
      { isExternal },
    ],

    queryFn: async () =>
      api.person.paymentInstruments.get({
        pathArgs: { personId },
        queryParams: { isExternal },
      }),

    enabled: !!personId,
  })
}

export default usePaymentInstrumentsQuery
