import { useMemo } from 'react'

import { searchObjects, getName } from 'peach/helpers'

import appPages from './pages/appPages'
import getCompanyPages from './pages/companyPages'

const personPages = []

const loanPages = []

const usePagesSearch = (query, params) => {
  const { companyId, company } = params || {}

  const companyPages = useMemo(() => {
    const companyName = getName(company)
    return getCompanyPages({ companyId, companyName })
  }, [companyId, company])

  const pages = useMemo(
    () => [...appPages, ...companyPages, ...personPages, ...loanPages],
    [companyPages],
  )

  const filteredPages = searchObjects(pages, query)

  return filteredPages
}

export default usePagesSearch
