import { useMemo } from 'react'

const useCompanyApi = (api, companyId) => {
  const companyApi = useMemo(
    () =>
      _.mapValues(api, (section) =>
        _.mapValues(section, (path) =>
          _.mapValues(path, (caller) => (options = {}) => {
            const { pathArgs, ...rest } = options
            const newPathArgs = { companyId, ...pathArgs }
            const newOptions = { ...rest, pathArgs: newPathArgs }
            return caller(newOptions)
          }),
        ),
      ),
    [api, companyId],
  )

  return companyApi
}

export default useCompanyApi
