import { FC, useState } from 'react'

import { FormProvider, useForm } from 'react-hook-form'
import * as R from 'remeda'
import { styled } from 'styled-components'

import { LoadingContainer } from 'peach/components'
import { StyledButton } from 'peach/components/Button/Button'
import Input from 'peach/components/Input'
import { useApi, useReadData, useWrite } from 'peach/hooks'

import {
  Error as ErrorComponent,
  FormActions,
  Section,
  SectionLabel,
  Title,
} from './Components'
import RoleConfig from './RoleConfig'
import { Company } from './types'

type CreateRoleFormValues = {
  alias?: string
  actions?: Array<string>
}

type CreateRoleFormProps = {
  company: Company
  updateCompany: (obj: Company) => void
  title: string
  alias?: string
  rolesQuery?: any
}

const CreateRoleForm: FC<CreateRoleFormProps> = ({
  company,
  updateCompany,
  title,
  alias,
  rolesQuery,
}) => {
  const api: any = useApi()

  const [editActions, setEditActions] = useState<boolean>(false)
  const form = useForm<CreateRoleFormValues>({
    values: alias ? RoleConfig[alias] : {},
  })

  const { actions } = form.watch()

  const [permissionsHash] = useReadData(() => api.auth.permissions.get(), [])

  const hasPermission = !!R.find(
    permissionsHash?.[company?.companyId ?? ''] ?? [],
    (permission) => permission === 'role:list',
  )

  const [onCreate, isPending, , error, clear] = useWrite(async (values) => {
    const resp = await api.company.roles.post({
      body: values,
      pathArgs: { companyId: company?.companyId },
    })

    if (resp?.status === 201 && !R.isEmpty(resp?.data)) {
      updateCompany({
        ...company,
        roles: [
          ...R.pipe(
            company?.roles ?? [],
            R.reject((role) => role?.alias === role),
          ),
          {
            alias,
            id: resp.data.id,
          },
        ],
      })

      rolesQuery?.refetch?.()
    }

    return resp
  })

  return (
    <>
      {rolesQuery.isLoading ? (
        <Loading>Loading...</Loading>
      ) : rolesQuery?.isError || !rolesQuery?.isSuccess ? (
        <>
          <ErrorMessage>
            {hasPermission && rolesQuery?.error?.status === 403
              ? `Whoops! Error fetching roles. This new company may not have all the roles setup yet, even though your user indicates it does have the correct permission to access this company's roles. You may need to wait a few minutes to proceed.`
              : `Whoops! Error fetching roles. Looks like you don't have the right permissions to continue.`}
          </ErrorMessage>
          <StyledButton onClick={() => rolesQuery?.refetch?.()}>
            Refetch roles
          </StyledButton>
        </>
      ) : (
        <LoadingContainer loading={isPending}>
          <Title>{title}</Title>
          <form
            onSubmit={form.handleSubmit(async (values) => {
              try {
                clear()
                await onCreate(values)
              } catch (e) {
                console.error('Error with create actions: ', e)
              }
            })}
          >
            <FormProvider {...form}>
              <Input
                label='Alias'
                required
                type='text'
                {...form.register('alias')}
              />

              {!editActions && <Code>{JSON.stringify(actions ?? [])}</Code>}

              {editActions && (
                <>
                  <StyledFormToggle
                    onClick={() => setEditActions(!editActions)}
                    type='button'
                  >
                    {editActions ? 'Cancel Edit Actions' : 'Edit Actions'}
                  </StyledFormToggle>

                  <Section>
                    <SectionLabel>Actions</SectionLabel>

                    {R.pipe(
                      actions ?? [],
                      R.map.indexed((_action, i) => (
                        <Row key={i}>
                          <StyledInput
                            type='text'
                            {...form.register(`actions.${i}`)}
                          />
                          <StyledButton
                            onClick={() =>
                              form.setValue(
                                'actions',
                                R.pipe(
                                  // @ts-ignore
                                  actions ?? [],
                                  R.splice(i, 1, []),
                                ),
                              )
                            }
                            type='button'
                          >
                            Delete
                          </StyledButton>
                        </Row>
                      )),
                    )}
                    <StyledButton
                      onClick={() =>
                        form.setValue('actions', [...(actions || []), ''])
                      }
                      type='button'
                    >
                      Add Action
                    </StyledButton>
                  </Section>
                </>
              )}

              <StyledButton
                onClick={() => setEditActions(!editActions)}
                type='button'
              >
                {editActions ? 'Cancel Edit Actions' : 'Edit Actions'}
              </StyledButton>

              {error && <ErrorComponent>{error.message}</ErrorComponent>}
              <FormActions>
                <StyledButton primary type='submit'>
                  Submit
                </StyledButton>
              </FormActions>
            </FormProvider>
          </form>
        </LoadingContainer>
      )}
    </>
  )
}

export default CreateRoleForm

const Loading = styled.div`
  color: ${(p) => p.theme.text};
`

const ErrorMessage = styled.div`
  margin-bottom: 16px;
  color: ${(p) => p.theme.text};
`

const Row = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

const StyledInput = styled(Input)`
  flex: 1 auto;
`

const StyledFormToggle = styled(StyledButton)`
  margin-bottom: 16px;
`

const Code = styled.code`
  display: block;
  margin-bottom: 16px;
  word-break: break-all;
  color: ${(p) => p.theme.text};
  font-size: 12px;
`
