import { FC } from 'react'

import { FormProvider, useForm } from 'react-hook-form'
import * as R from 'remeda'
import { styled } from 'styled-components'

import { LoadingContainer } from 'peach/components'
import { StyledButton as Button } from 'peach/components/Button/Button'
import Input from 'peach/components/Input'
import { useApi, useWrite } from 'peach/hooks'

import { Error as ErrorComponent, FormActions, Title } from './Components'
import { Company } from './types'

const profiles = [
  {
    email: 'eddie@peachfinance.com',
    firstName: 'Eddie',
    lastName: 'Oistacher',
  },
  {
    email: 'eran@peachfinance.com',
    firstName: 'Eran',
    lastName: 'Sandler',
  },
  {
    email: 'brad.king@peachfinance.com',
    firstName: 'Brad',
    lastName: 'King',
  },
  {
    email: 'russell.braden@peachfinance.com',
    firstName: 'Russell',
    lastName: 'Braden',
  },
  {
    email: 'chris.kruse@peachfinance.com',
    firstName: 'Chris',
    lastName: 'Kruse',
  },
  {
    email: 'stephen.dolan@peachfinance.com',
    firstName: 'Stephen',
    lastName: 'Dolan',
  },
  {
    email: 'amanda.cotter@peachfinance.com',
    firstName: 'Amanda',
    lastName: 'Cotter',
  },
  {
    email: 'elena.sawchuk@peachfinance.com',
    firstName: 'Elena',
    lastName: 'Sawchuk',
  },
  {
    email: 'alejandra.bennett@peachfinance.com',
    firstName: 'Alejandra',
    lastName: 'Bennett',
  },
  {
    email: 'julie.fisher@peachfinance.com',
    firstName: 'Julie',
    lastName: 'Fisher',
  },
]

type User = {
  type?: 'agent' | 'borrower' | 'service'
  roleIds?: Array<string>
  authType: {
    email?: string
  }
  firstName?: string
  lastName?: string
}

type CreateUsersFormValues = {
  users: Array<User>
}

type CreateUsersFormProps = {
  company: Company
  updateCompany: (obj: Company) => void
  title: string
}

const CreateUsersForm: FC<CreateUsersFormProps> = ({
  company,
  updateCompany,
  title,
}) => {
  const api: any = useApi()

  const form = useForm<CreateUsersFormValues>({
    values: {
      users: R.map(profiles, (profile) => ({
        type: 'agent',
        roleIds: R.pipe(
          company?.roles ?? [],
          R.reject(
            (role) => role.alias === 'api' || role.alias === 'peach-support',
          ),
          R.map((role) => role.id),
        ) as Array<string>,
        authType: {
          email: profile.email,
        },
        firstName: profile.firstName,
        lastName: profile.lastName,
      })),
    },
  })

  const { users } = form.watch()

  const [onCreate, isPending, , error, clear] = useWrite(
    async (values: { users: Array<User> }) => {
      const userResponses = await Promise.all(
        R.map(values.users, (user) =>
          api.company.users.post({
            body: {
              type: user.type,
              roleIds: user.roleIds,
              authType: user.authType,
            },
            pathArgs: { companyId: company?.companyId },
          }),
        ),
      )

      const employeeResponses = await Promise.all(
        R.pipe(
          userResponses ?? [],
          R.reject((response) => response.status !== 201),
          R.map((response) =>
            api.company.employees.post({
              body: {
                userId: response.data.id,
                firstName: R.find(
                  profiles,
                  (profile) => profile.email === response.data.userName,
                )?.firstName,
                lastName: R.find(
                  profiles,
                  (profile) => profile.email === response.data.userName,
                )?.lastName,
                teams: R.map(company?.teams ?? [], (team) => ({
                  name: team.name,
                })),
              },
              pathArgs: { companyId: company?.companyId },
            }),
          ),
        ),
      )

      await Promise.all(
        R.pipe(
          employeeResponses ?? [],
          R.reject(
            (response) => response.status !== 200 && !!company?.teams?.[0]?.id,
          ),
          R.map((response) =>
            api.employees.team.put({
              pathArgs: {
                employeeId: response?.data?.id,
                teamId: company?.teams?.[0]?.id,
              },
            }),
          ),
        ),
      )

      updateCompany({
        ...company,
        users: [
          ...(company?.users ?? []),
          ...R.pipe(
            userResponses ?? [],
            R.reject((response) => response.status !== 201),
            R.map((response) => ({
              type: response.data.type,
              userId: response.data.userId,
              id: response.data.id,
            })),
          ),
        ],
        employees: [
          ...(company?.employees ?? []),
          ...(R.map(employeeResponses, (response) => ({
            id: response.data.id,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            teams: [{ name: company?.teams?.[0]?.name ?? '' }],
          })) ?? []),
        ],
      })
    },
  )

  return (
    <LoadingContainer loading={isPending}>
      <Title>{title}</Title>
      <form
        onSubmit={form.handleSubmit(async (values) => {
          try {
            clear()
            await onCreate(values)
          } catch (e) {
            console.error('Error with create actions: ', e)
          }
        })}
      >
        <FormProvider {...form}>
          {R.map.indexed(users, (user, index) => (
            <Row key={`${index}-${user.authType.email}`}>
              <Input
                label='email'
                required
                type='text'
                {...form.register(`users.${index}.authType.email`)}
              />
              <Input
                label='first name'
                required
                type='text'
                {...form.register(`users.${index}.firstName`)}
              />
              <Input
                label='last name'
                required
                type='text'
                {...form.register(`users.${index}.lastName`)}
              />
              <RemoveButton
                onClick={() =>
                  form.setValue(
                    'users',
                    R.reject.indexed(users, (_, i) => i === index),
                  )
                }
              >
                remove
              </RemoveButton>
            </Row>
          ))}

          <AddButton
            onClick={() =>
              form.setValue('users', [
                ...users,
                {
                  type: 'agent',
                  roleIds: R.pipe(
                    company?.roles ?? [],
                    R.reject(
                      (role) =>
                        role.alias === 'api' || role.alias === 'peach-support',
                    ),
                    R.map((role) => role.id),
                  ) as Array<string>,
                  authType: {
                    email: '',
                  },
                },
              ])
            }
          >
            add
          </AddButton>

          {error && <ErrorComponent>{error.message}</ErrorComponent>}
          <FormActions>
            <Button primary type='submit'>
              Submit
            </Button>
          </FormActions>
        </FormProvider>
      </form>
    </LoadingContainer>
  )
}

export default CreateUsersForm

const Row = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: start;
`

const RemoveButton = styled(Button)`
  margin-top: 18px;
`

const AddButton = styled(Button)`
  margin-top: 8px;
`
