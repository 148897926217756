import { useDataQuery, useQueryApi } from '../useQueryApi'

const useDrawPurchasesQuery = (options) => {
  const [api, { companyId, personId, loanId, drawId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: [
      'company',
      companyId,
      'people',
      personId,
      'loans',
      loanId,
      'draws',
      drawId,
      'purchases',
    ],

    queryFn: () =>
      api.companyDraw.purchases.get({
        pathArgs: { companyId, personId, loanId, drawId },
      }),

    enabled: !!(companyId && personId && loanId && drawId),
  })
}

export default useDrawPurchasesQuery
