import _ from 'lodash'

import { Input, Select, Modal, Menu } from 'peach/components'
import useIsOpen from 'peach/decorators/withActionTrigger/useIsOpen'

import { useAuthApi, useAuthState } from 'core/auth/AuthProvider'
import { useEnvApi } from 'core/env/useEnv'
import { useDarkModeInfo } from 'core/theme/DarkModeProvider'

import EnvTable from '../env/EnvTable'

const Login = () => {
  const { darkMode, setDarkMode } = useDarkModeInfo()

  const { envs, env, activeId, setActiveId, addEnv, editEnv, removeEnvId } =
    useEnvApi()

  const authState = useAuthState()
  const { requiresReauth, isReauthing } = authState
  const authApi = useAuthApi()
  const { logout, showAuthPrompt } = authApi

  const sendAddEnv = (envData) => {
    const newEnv = addEnv(envData)
    if (newEnv?.id) setActiveId(newEnv.id)
  }

  const sendEditEnv = (envData) => {
    editEnv(activeId, envData)
  }

  const envDefaults = {
    label: 'Local',
    apiBase: 'http://localhost:8000/api',
    companyId: 'CP-L9BN-5J52',
    userType: 'agent',
  }

  // todo: remove this hook and just us regular state
  const addEnvIsOpen = useIsOpen()
  const editEnvIsOpen = useIsOpen()
  const removeEnvIsOpen = useIsOpen()

  const addEnvModal = (
    <Modal
      initialValue={envDefaults}
      onSubmit={sendAddEnv}
      title='Add Environment'
      {...addEnvIsOpen}
    >
      <Input formKey='label' />
      <Input formKey='apiBase' />
      <Input formKey='companyId' />
      <Select formKey='userType' options={['agent', 'borrower']} />
    </Modal>
  )

  const editEnvModal = (
    <Modal
      initialValue={env}
      onSubmit={(env) => sendEditEnv(env)}
      submitLabel='Submit'
      title='Edit Environment'
      {...editEnvIsOpen}
    >
      <EnvTable env={env} />
      <Input formKey='label' />
    </Modal>
  )

  const removeEnvModal = (
    <Modal
      initialValue={env}
      onSubmit={() => removeEnvId(activeId)}
      submitLabel='Remove Environment'
      title='Remove Environment'
      {...removeEnvIsOpen}
    >
      <EnvTable env={env} />
    </Modal>
  )

  const envLabel = env?.label || 'Environment'

  const envMenu = (
    <Menu label='👨‍💻' selectable>
      {_.map(envs, (env) => {
        const { label, id } = env
        const checked = id === activeId ? '› ' : ''
        return (
          <Menu.Item
            checked={checked}
            key={id}
            onSelect={() => setActiveId(id)}
          >
            {label}
          </Menu.Item>
        )
      })}

      {_.isEmpty(envs) && <Menu.Item disabled>No Environments</Menu.Item>}

      <Menu.Divider />
      <Menu.Item {...addEnvIsOpen}>Add Environment</Menu.Item>

      <Menu.Divider />
      {requiresReauth ? (
        <Menu.Item disabled={isReauthing} onSelect={() => showAuthPrompt()}>
          Login
        </Menu.Item>
      ) : (
        <Menu.Item disabled={isReauthing} onSelect={() => logout()}>
          {`Logout of ${envLabel}`}
        </Menu.Item>
      )}
      <Menu.Item {...editEnvIsOpen} disabled={!env}>
        {`Edit ${envLabel}`}
      </Menu.Item>

      <Menu.Item {...removeEnvIsOpen} disabled={!env}>
        {`Remove ${envLabel}`}
      </Menu.Item>

      <Menu.Divider />
      <Menu.Item
        onSelect={() => {
          setDarkMode(darkMode === 'dark' ? 'light' : 'dark')
        }}
      >
        {darkMode === 'dark' ? '🌞 Light Mode' : '🌚 Dark Mode'}
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      {addEnvModal}
      {editEnvModal}
      {removeEnvModal}
      {envMenu}
    </>
  )
}

export default Login
