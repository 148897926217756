const makeWithPrefix = (prefix) => (strOrHash) => {
  if (_.isString(strOrHash)) return prefix + strOrHash
  if (_.isObject(strOrHash))
    return _.mapValues(strOrHash, (str) => prefix + str)
}

const withCompany = makeWithPrefix('/companies/{companyId}')

const withPerson = makeWithPrefix('/people/{personId}')

const withCompanyPerson = makeWithPrefix(
  '/companies/{companyId}/people/{personId}',
)

const withLoan = makeWithPrefix('/people/{personId}/loans/{loanId}')

const withDraw = makeWithPrefix(
  '/people/{personId}/loans/{loanId}/draws/{drawId}',
)

const withCompanyDraw = makeWithPrefix(
  '/companies/{companyId}/people/{personId}/loans/{loanId}/draws/{drawId}',
)

const apiConfig = {
  auth: {
    permissions: '/auth/permissions',
    oneTimeCode: '/company/{companyId}/auth/send-one-time-code',
    user: '/auth/user',
  },
  peach: {
    peachy: '/peachy',
    pit: '/peachy/pit',
    bootstrap: '/bootstrap',
    periods: '/loan-periods',
    periodStatement: '/loan-periods/{periodId}/statement',
    routingNumber: `/routing-numbers/{routingNumber}`,
    flags: '/flags',
  },
  companies: {
    companies: '/companies',
    company: '/companies/{companyId}',
  },
  company: withCompany({
    config: '/config',
    permissions: '/permissions',
    keys: '/keys',
    appDomains: '/app-domains',
    investors: '/investors',
    investor: '/investors/{investorId}',
    loanTypes: '/loan-types',
    loanType: '/loan-types/{loanTypeId}',
    loanTypeFeeTypes: '/loan-types/{loanTypeId}/fee-types',
    dynamicFeeTypes: '/loan-types/{loanTypeId}/dynamic-fee-types',
    dynamicFeeType:
      '/loan-types/{loanTypeId}/dynamic-fee-types/{dynamicFeeTypeId}',
    repaymentEngineNotifications:
      '/loan-types/{loanTypeId}/repayment-engine/notifications',
    workflowTypes: '/workflow-types',
    workflowType: '/workflow-types/{workflowTypeId}',
    workflowStepTypes: '/workflow-step-types',
    workflowStepType: '/workflow-step-types/{workflowStepTypeId}',
    verificationTypes: '/verification-types',
    verificationType: '/verification-types/{verificationTypeId}',
    roles: '/roles',
    role: '/roles/{roleId}',
    rolePermissions: '/roles/{roleId}/permissions',
    users: '/users',
    user: '/users/{userId}',
    userRoles: '/users/{userId}/roles',
    userRole: '/users/{userId}/roles/{roleId}',
    employees: '/employees',
    employee: '/employees/{employeeId}',
    employeeTeams: '/employees/{employeeId}/teams',
    employeeTeam: '/employees/{employeeId}/teams/{teamId}',
    teams: '/teams',
    team: '/teams/{teamId}',
    people: '/people',
    person: '/people/{personId}',
  }),
  communicator: {
    templates: '/communicator/templates',
    templateDescriptors: '/communicator/templatedescriptors',
    templateDescriptor:
      '/communicator/templatedescriptors/{templateDesriptorId}',
    templatesImport: '/communicator/templates/import',
    template: '/communicator/templates/{templateId}',
    render: '/communicator/render',
    preview: '/communicator/preview',
    subjects: '/communicator/subjects',
  },
  people: {
    people: '/people',
    person: '/people/{personId}',
    search: '/people/search',
  },
  person: withPerson({
    contacts: '/contacts',
    contact: '/contacts/{contactId}',
    paymentInstruments: '/payment-instruments',
    paymentInstrument: '/payment-instruments/{paymentInstrumentId}',
    verifyPaymentInstrument:
      '/payment-instruments/{paymentInstrumentId}/verify',
    consents: '/consents',
    cases: '/cases',
    case: '/cases/{caseId}',
    documents: '/documents',
    document: '/documents/{documentDescriptorId}',
    documentContent: '/documents/{documentDescriptorId}/content',
    interactions: '/interactions',
    interaction: '/interactions/{interactionId}',
    interactionsSearch: '/interactions/search',
    loans: '/loans',
    loan: '/loans/{loanId}',
  }),
  loan: withLoan({
    advances: '/advances',
    rates: '/rates',
    rate: '/rates/{rateId}',
    expectedPayments: '/expected-payments',
    obligations: '/obligations',
    autopay: '/autopay',
    balances: '/balances',
    draws: '/draws',
    draw: '/draws/{drawId}',
    refunds: '/refunds',
    periods: '/periods',
    fees: '/fees',
    cancelFee: '/fees/{loanFeeId}/cancel',
    documents: '/documents',
    statements: '/statements',
    deferSchedule: '/defer-schedule',
    transactions: '/transactions',
    transaction: '/transactions/{transactionId}',
    reverseTransaction: '/transactions/{transactionId}/reverse',
    backdateTransaction: '/transactions/{transactionId}/backdate',
    cancelTransaction: '/transactions/{transactionId}/cancel',
    refresh: '/refresh',
    activate: '/activate',
  }),
  draw: withDraw({
    activate: '/activate',
    balances: '/balances',
    rates: '/rates',
    purchases: '/purchases',
    purchase: '/purchases/{purchaseId}',
  }),
  companyPerson: withCompanyPerson({
    ledger: '/loans/{loanId}/ledger-debugger',
    workflow: '/cases/{caseId}/workflow',
    workflowStep: '/cases/{caseId}/workflow/steps/{workflowStepId}',
    workflowStepItem:
      '/cases/{caseId}/workflow/steps/{workflowStepId}/workitems/{workflowWorkitemId}',
  }),
  companyDraw: withCompanyDraw({
    purchases: '/purchases',
    purchase: '/purchases/{purchaseId}',
  }),
  periods: {
    statement: '/loan-periods/{periodId}/statement',
  },
  caseTypes: {
    caseTypes: '/case-types',
    caseType: '/case-types/{caseTypeId}',
  },
  employees: {
    teams: '/employees/{employeeId}/teams',
    team: '/employees/{employeeId}/teams/{teamId}',
  },
}
export default apiConfig
