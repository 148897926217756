import { styled } from 'styled-components'

const Wrapper = styled.div`
  padding: 12px;
  text-align: center;
`

const Content = styled.div`
  margin: 0 auto;
  max-width: 800px;
  text-align: left;
`

const Page = ({ children }) => (
  <Wrapper>
    <Content>{children}</Content>
  </Wrapper>
)

export default Page
