import { useState, useMemo, useRef } from 'react'

import { styled } from 'styled-components'

import { withLabel, withFormValue } from 'peach/decorators'
import { humanizeKey } from 'peach/helpers'
import { useOnChangeEffect } from 'peach/hooks'

import RawTextarea from './RawTextarea'

const Wrapper = styled.div`
  border: 1px solid ${(p) => p.theme.border};
  border-radius: 5px;
  background-color: ${(p) => p.theme.offset};
  width: 100%;
  overflow: hidden;
`

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  padding-bottom: 0;
`
const ActionsArea = styled.div`
  display: inline-block;
`
const Action = styled.button`
  margin-left: 8px;
  border: none;
  border: 1px solid ${(p) => p.theme.border};
  border-radius: 3px;
  background-color: ${(p) => p.theme.background};
  cursor: pointer;
  cursor: default;
  padding: 2px 8px;
  color: ${(p) => p.theme.mutedText};
  font-size: 12px;

  &:not(:disabled):hover {
    background-color: transparent;
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
    border-color: transparent;
    background-color: transparent;
  }
`

const Status = styled.span`
  display: inline-block;
  padding-left: 8px;
  color: ${(p) => p.theme.mutedText};
  font-size: 12px;
`

const TextareaArea = styled.div`
  padding: 8px;
`

const Err = { error: 'parseError' }

const parse = (value) => {
  try {
    return JSON.parse(value)
  } catch (err) {
    return Err
  }
}

const stringify = (string) => {
  try {
    return JSON.stringify(string, null, 2)
  } catch (err) {
    return ''
  }
}

const JsonTextarea = (props) => {
  const {
    formKey,
    onChange,
    width,
    value,
    onEnter,
    onKeyPress,
    placeholder,
    type,
    minRows,
    ...rest
  } = props

  const nextValueRef = useRef(value)

  const valueString = useMemo(() => stringify(value), [value])

  const [localString, setLocalString] = useState(valueString)

  const newValue = useMemo(() => parse(localString), [localString])

  const formattedString = useMemo(
    () => stringify(parse(localString)),
    [localString],
  )

  const isValid = newValue !== Err

  const canFormat = formattedString !== localString && isValid

  const handleChange = (str) => {
    setLocalString(str)
    if (onChange) {
      const newValue = parse(str)
      if (newValue !== Err) {
        nextValueRef.current = newValue
        onChange(newValue)
      }
    }
  }

  useOnChangeEffect(() => {
    if (value !== nextValueRef.current) {
      setLocalString(stringify(value))
    }
  }, [value])

  const onReformat = () => setLocalString(formattedString)

  const handleKeyPress = (event) => {
    if (onEnter && event.key === 'Enter') onEnter(event)
    if (onKeyPress) onKeyPress(event)
  }

  return (
    <Wrapper>
      <Controls>
        <Status isValid={isValid}>
          {isValid ? '✅ Valid JSON' : '❌ Invalid JSON'}
        </Status>
        <ActionsArea>
          <Action onClick={onReformat} disabled={!canFormat}>
            Reformat
          </Action>
        </ActionsArea>
      </Controls>
      <TextareaArea>
        <RawTextarea
          {...rest}
          width={width || '100%'}
          minRows={minRows ?? 3}
          onChange={handleChange}
          value={localString || ''}
          placeholder={placeholder || humanizeKey(formKey)}
          onKeyPress={handleKeyPress}
        />
      </TextareaArea>
    </Wrapper>
  )
}

export default withFormValue(withLabel(JsonTextarea))
