import { useState } from 'react'

import { Panels, LoadingContainer, Modal } from 'peach/components'
import { withTrigger } from 'peach/decorators'
import { getName } from 'peach/helpers'
import { useApi, useWrite } from 'peach/hooks'

import { useAuthState } from 'core/auth/AuthProvider'

import TemplateCodeEditor from './TemplateCodeEditor'
import TemplateRenderOptions from './TemplateRenderOptions'
import TemplateRenderPreview from './TemplateRenderPreview'

const TemplateEditor = ({ template, descriptor, onSaveTemplate, ...rest }) => {
  // const companyId = useCompanyId()
  const authState = useAuthState()

  const { subject, channel } = descriptor || {}
  const api = useApi()

  const [state, setState] = useState(() => ({
    template,
    renderOptions: { fmt: 'html', personId: authState.personId },
  }))

  const [onRender, rendering, preview, renderError] = useWrite(() => {
    const { renderOptions } = state
    const { fmt, ...rest } = renderOptions
    return api.communicator.preview.post({
      queryParams: { fmt },
      body: { subject, channel, ...rest },
    })
  })

  return (
    <Modal
      fullContent
      initialValue={state}
      onChange={setState}
      onSubmit={onSaveTemplate}
      submitLabel='Save'
      title={getName(descriptor)}
      value={state}
      width='90%'
      {...rest}
      hideTrigger
    >
      <Panels
        left={<TemplateCodeEditor formKey='template' />}
        leftWidth='40%'
        right={<TemplateRenderOptions formKey='renderOptions' />}
      >
        <LoadingContainer error={renderError} loading={rendering}>
          <TemplateRenderPreview onRender={onRender} preview={preview} />
        </LoadingContainer>
      </Panels>
    </Modal>
  )
}

export default withTrigger(TemplateEditor)
