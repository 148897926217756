import { LoadingContainer } from 'peach/components'
import { useReadData, useApi } from 'peach/hooks'
import { ListSubroutes } from 'peach/router'

const CompanyTemplatesRoutes = () => {
  const api = useApi()

  const [templates, loading, error] = useReadData(() =>
    api.communicator.templates.get(),
  )

  return (
    <LoadingContainer error={error} loading={loading}>
      <ListSubroutes
        json={templates}
        page
        paramKey='templateId'
        title='Templates'
      />
    </LoadingContainer>
  )
}

export default CompanyTemplatesRoutes
