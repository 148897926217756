import { useDataQuery, useQueryApi } from '../useQueryApi'

const useCompanyConfigQuery = (options) => {
  const [api, { companyId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['companies', companyId, 'config'],

    queryFn: () => api.company.config.get({ pathArgs: { companyId } }),
  })
}

export default useCompanyConfigQuery
