import { Switch, Route, Redirect } from 'react-router-dom'

import Environments from './auth/Environments'
import Logout from './auth/Logout'

const UNAUTHENTICATED_ROUTES = ['/logout', '/environments']

const UnauthenticatedRoutes = () => (
  <Switch>
    <Route exact path='/environments'>
      <Environments />
    </Route>

    <Route exact path='/logout'>
      <Logout />
    </Route>
    <Redirect to='/environments' />
  </Switch>
)

export { UNAUTHENTICATED_ROUTES }

export default UnauthenticatedRoutes
