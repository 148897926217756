import _ from 'lodash'
import parserBabel from 'prettier/parser-babel'
import prettier from 'prettier/standalone'

import { Page, Markdown } from 'peach/components'

import { useAuthState } from 'core/auth/AuthProvider'

const prettierConfig = {
  trailingComma: 'all',
  tabWidth: 2,
  useTabs: false,
  singleQuote: true,
  arrowParens: 'always',
  semi: false,
  jsxBracketSameLine: false,
  jsxSingleQuote: true,
}

const format = (js) => {
  try {
    return prettier.format(js, {
      ...prettierConfig,
      parser: 'babel',
      plugins: [parserBabel],
    })
  } catch (_e) {
    return js
  }
}

const stringify = (obj) => {
  if (_.isEmpty(obj)) return ''

  return JSON.stringify(obj, null, 2)
}

const code = (str, lang = '') => '```' + lang + '\n' + str + '\n```'

const verbify = (verb, item) =>
  verb + item.charAt(0).toUpperCase() + item.slice(1)

const ApiCallRequest = (props) => {
  const { callOptions, category, action, method, fetchArgs } = props

  const { companyId, userId, personId, decodedToken } = useAuthState()

  const { userType } = decodedToken

  const { pathArgs, body, queryParams } = callOptions || {}

  const [fetchUrl, fetchOptions] = fetchArgs || []

  const callerFnString = `api.${category}.${action}.${method}`
  const options = { pathArgs, queryParams, body }
  const usedOptions = _.pickBy(options, (val) => !_.isEmpty(val))

  const optionsString = stringify(usedOptions)

  const wrapper = (apiCall) => {
    if (['put', 'patch', 'post', 'delete'].includes(method.toLowerCase())) {
      const verb =
        method === 'post'
          ? verbify('create', action)
          : method === 'delete'
          ? verbify('delete', action)
          : verbify('update', action)

      return `const [${verb}, sending, resp, error, clear] = useWrite(${apiCall})`
    }
    const deps = [..._.keys(pathArgs), ..._.keys(queryParams)].join(', ')
    return `const [${action}, loading, error, refresh] = useRead(${apiCall}, [${deps}])`
  }
  const apiCall = `${callerFnString}(${optionsString})`

  const apiCallUsage = wrapper(`async () => { return ${apiCall} }`)

  const codeString = `
    // jwt: ${userType}, ${companyId}, ${userId}, ${personId}


    // API call
    
    ${apiCall}

    // Equivalent fetch call
    
    const fetchUrl = '${fetchUrl}'
    
    const resp = fetch(fetchUrl, ${stringify(fetchOptions)})

    // In-App example 

    ${apiCallUsage}
  `

  const markdown = code(format(codeString), 'js')

  return (
    <Page.Full>
      <Markdown markdown={markdown} wrap />
    </Page.Full>
  )
}

export default ApiCallRequest
