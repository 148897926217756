import Auth from 'pages/auth/Auth'
import Companies from 'pages/companies/Companies'
import CreateCompany from 'pages/companies/CreateCompany'
import Env from 'pages/env/Env'
import AllRoles from 'pages/home/AllRoles'
import { Markdown, ListNav2, Panels } from 'peach/components'
import { Switch, Route, Redirect } from 'peach/router'

import development from './development.md'
import localData from './local-data.md'
import notes from './notes.md'
import Status from './Status'

const markdown = `
  # Welcome to 🍑 Dev Tools\n
  Read the [readme.md](https://github.com/peachfinance/dev-tools/blob/master/README.md) for more.
`

// but in this particular case though, we are importing from outside of the /src folder and that doesn't work, BUT the macro plugin does, so we improt the readme this way.
const items = [
  ['Home', '', true],
  '/status',
  '/local-data',
  '/notes',
  '/development',
  {
    label: 'Sections',
    items: ['/companies', '/create-company', '/all-roles', '/auth', '/env'],
  },
]

const nav = <ListNav2 items={items} />

const Home = () => (
  <Panels left={nav}>
    <Switch>
      <Route crumb exact path='/home'>
        <Markdown.Page markdown={markdown} />
      </Route>

      <Route crumb path='/status'>
        <Status />
      </Route>

      <Route crumb path='/local-data'>
        <Markdown.Page url={localData} />
      </Route>

      <Route crumb path='/development'>
        <Markdown.Page url={development} />
      </Route>

      <Route crumb path='/notes'>
        <Markdown.Page url={notes} />
      </Route>

      <Route crumb exact path='/companies'>
        <Companies />
      </Route>

      <Route crumb exact path='/create-company'>
        <CreateCompany />
      </Route>

      <Route crumb path='/all-roles' title='All Roles'>
        <AllRoles />
      </Route>

      <Route crumb path='/auth'>
        <Auth />
      </Route>

      <Route crumb path='/env'>
        <Env />
      </Route>

      <Redirect to='/home' />
    </Switch>
  </Panels>
)

export default Home
