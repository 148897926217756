import CodeMirror from '@uiw/react-codemirror'
import { styled } from 'styled-components'

import { withFormValue, withLabel } from 'peach/decorators'

const Wrapper = styled.div`
  border: 1px solid ${(p) => p.theme.border};
  border-radius: 4px;
  background-color: ${(p) => p.theme.offset};
  width: 100%;
`

const TopSpacer = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.border};
  height: 16px;
`
const BottomSpacer = styled.div`
  border-top: 1px solid ${(p) => p.theme.border};
  height: 8px;
`

const Mirror = styled(CodeMirror)`
  & > .codemirror {
    z-index: 0;
    height: auto;
    line-height: 1.6;
    font-size: 14px;

    pre {
      z-index: 1;
    }
  }
`

const CodeEditor = ({ value, onChange }) => {
  const options = {
    mode: 'jinja2',
    lineNumbers: true,
    lineWrapping: true,
    viewportMargin: Number.POSITIVE_INFINITY,
    height: 'auto',
  }
  return (
    <Wrapper>
      <TopSpacer />
      <Mirror
        onChange={(val) => {
          console.info('val', val)
          onChange(val)
        }}
        options={options}
        value={value}
      />
      <BottomSpacer />
    </Wrapper>
  )
}

export default withFormValue(withLabel(CodeEditor))
