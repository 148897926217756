import { Select } from 'peach/components'
import { useUsersQuery } from 'peach/data'
import { getName } from 'peach/helpers'

const CompanyAgentUserSelect = (props) => {
  const [agents] = useUsersQuery({ userType: 'agent' })

  const options = _.map(agents, (user) => ({
    value: user.id,
    label: getName(user),
  }))

  return <Select label='User' searchable {...props} options={options} />
}

export default CompanyAgentUserSelect
