import { useRef, useMemo, useEffect } from 'react'

const useStableCallback = (func) => {
  const funcRef = useRef(func)

  const stableFunc = useMemo(
    () =>
      (...args) =>
        funcRef.current && funcRef.current(...args),
    [],
  )

  useEffect(() => {
    funcRef.current = func
    return undefined
  }, [func])

  useEffect(() => () => (funcRef.current = undefined), [])

  return stableFunc
}

export default useStableCallback
