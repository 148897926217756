import { useParams } from 'react-router-dom'

const byObject = {
  company: ({ id }) => `/companies/${id}`,
  person: ({ companyId, id }) => `/companies/${companyId}/borrowers/${id}`,
  draw: ({ companyId, personId, loanId, id }) =>
    `/companies/${companyId}/borrowers/${personId}/loans/${loanId}/draws/${id}`,
}

const byPrefix = {
  LT: ({ companyId, id }) => `/companies/${companyId}/loan-types/${id}`,
  RL: ({ companyId, id }) => `/companies/${companyId}/roles/${id}`,
  BO: ({ companyId, id }) => `/companies/${companyId}/borrowers/${id}`,
  UR: ({ companyId, id }) => `/companies/${companyId}/users/${id}`,
  EM: ({ companyId, id }) => `/companies/${companyId}/employees/${id}`,
  TM: ({ companyId, id }) => `/companies/${companyId}/teams/${id}`,
  LN: ({ companyId, mainBorrowerId, id }) =>
    `/companies/${companyId}/borrowers/${mainBorrowerId}/loans/${id}`,
  TX: ({ companyId, loanId, mainBorrowerId, id }) =>
    `/companies/${companyId}/borrowers/${mainBorrowerId}/loans/${loanId}/transactions/${id}`,
  DD: ({ companyId, personId, id }) =>
    `/companies/${companyId}/borrowers/${personId}/documents/${id}`,
}

const getLink = (item) => {
  const { id, object } = item || {}

  const prefix = id && id.slice(0, 2)

  const fn = byObject[object] || byPrefix[prefix]

  if (fn) return fn(item)

  return ''
}

const useLink = (item, options = {}) => {
  const params = useParams()

  return getLink({ ...params, ...item, ...options })
}

export { useLink }

export default getLink
