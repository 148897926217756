import { forwardRef, useState } from 'react'

import { styled } from 'styled-components'

import { withLabel, withFormValue } from 'peach/decorators'

// import KeyboardNavArea from './KeyboardNavArea'
import Checkbox from './Checkbox'
import filterOptions from './filterOptions'
import formatOptions from './formatOptions'
import SelectBottomControls from './SelectBottomControls'
import SelectFrame from './SelectFrame'
import SelectItem from './SelectItem'
import SelectTopSearch from './SelectTopSearch'

const Content = styled.div`
  padding: 4px;
  overflow-y: auto;
`

const renderTriggerLabel = (selectedValues, formattedOptions, label) => {
  const selectedCount = _.size(selectedValues)

  if (selectedCount === 0) {
    const item = label ? ` ${label}` : ''
    return `Select${item}…`
  }

  if (selectedCount > 2) return `${selectedCount} Selected Items`

  const selectedOptions = _.filter(formattedOptions, ({ value }) =>
    selectedValues.includes(value),
  )

  return (
    _.map(selectedOptions, 'label').join(', ').trim() ||
    `${selectedCount} Selected Items`
  )
}

const MultiSelect = forwardRef((props, ref) => {
  const {
    options,
    value,
    onChange,
    contentRef,
    searchable,
    notes,
    loading,
    disabled,
    label,
    ...rest
  } = props

  const selectedValues = _.isArray(value) ? value : []

  const formattedOptions = formatOptions(options, { notes })

  const [query, setQuery] = useState('')

  const filteredOptions = filterOptions(formattedOptions, query)

  const contents = _.map(filteredOptions, (option) => {
    const { value, label, note } = option
    const isChecked = selectedValues.includes(value)

    const handleChange = () => {
      if (!isChecked) {
        onChange(selectedValues.concat(value))
      } else {
        onChange(_.without(selectedValues, value))
      }
    }

    return (
      <SelectItem
        key={value}
        left={<Checkbox value={isChecked} onChange={handleChange} />}
        onClick={handleChange}
        isSelected={isChecked}
        label={label}
        note={note}
      />
    )
  })

  const triggerLabel = loading
    ? 'Loading...'
    : renderTriggerLabel(selectedValues, formattedOptions, label)

  const optionsCount = _.size(formattedOptions)

  const handleSelectAll = () => {
    const allCurrentvalues = _.map(filteredOptions, 'value')
    const newValues = _.uniq([...selectedValues, ...allCurrentvalues])
    _.defer(() => onChange(newValues))
  }

  const searchControls = searchable && (
    <SelectTopSearch value={query} onChange={setQuery} />
  )

  const hasValue = !_.isEmpty(value)

  const controls = optionsCount > 5 && (
    <SelectBottomControls
      onClear={() => onChange([])}
      canClear={hasValue}
      onSelectAll={handleSelectAll}
      canSelectAll={optionsCount > 0}
    />
  )

  return (
    <SelectFrame
      {...rest}
      disabled={loading || disabled}
      triggerLabel={triggerLabel}
      searchControls={searchControls}
      controls={controls}
      ref={ref}
      label={label}
      triggerHasValue={hasValue}
    >
      <Content contentRef={contentRef}>{contents}</Content>
    </SelectFrame>
  )
})

MultiSelect.displayName = 'MultiSelect'

export default withFormValue(withLabel(MultiSelect))
