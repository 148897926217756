import tinycolor from 'tinycolor2'

// https://github.com/bgrins/TinyColor#readme
const CONTRAST_LIMIT = 84

const preventTooDark = (rawColor) => {
  if (!rawColor) return rawColor
  const color = tinycolor(rawColor)
  const brightness = color.getBrightness()
  if (brightness < CONTRAST_LIMIT) {
    return color.brighten(CONTRAST_LIMIT - brightness).toString('rgb')
  }
  return color.toString('rgb')
}

const preventTooLight = (rawColor) => {
  if (!rawColor) return rawColor
  const limit = 255 - CONTRAST_LIMIT
  const color = tinycolor(rawColor)
  const brightness = color.getBrightness()
  if (brightness > limit) {
    return color.darken(brightness - limit).toString('rgb')
  }
  return color.toString('rgb')
}

const fade = (rawColor, fadeValue = 0.5) => {
  const color = tinycolor(rawColor)
  const alpha = color.getAlpha()
  return color.setAlpha(alpha * fadeValue).toString('prgb')
}

const borderify = (rawColor) =>
  tinycolor.mix(rawColor, '#888', 30).toString('prgb')

const disableify = (rawColor) => fade(rawColor, 0.5)

const isLight = (rawColor) => tinycolor(rawColor).isLight()

const deepen = (rawColor, amount = 30) =>
  tinycolor.mix(rawColor, '#888', amount).toString('prgb')

const getHoverColor = (rawColor) => {
  const color = tinycolor(rawColor)
  if (color.isLight()) {
    return color.darken(6).toString('prgb')
  } else {
    return color.lighten(6).toString('prgb')
  }
}

const color = {
  fade,
  borderify,
  disableify,
  preventTooDark,
  preventTooLight,
  isLight,
  deepen,
  getHoverColor,
}

export default color
