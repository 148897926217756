import { styled } from 'styled-components'

const Wrapper = styled.div`
  padding: 12px;
`

const Content = styled.div``

const FullPage = ({ children }) => (
  <Wrapper>
    <Content>{children}</Content>
  </Wrapper>
)

export default FullPage
