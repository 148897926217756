import { useParams, useRouteMatch } from 'react-router-dom'

import {
  Model,
  LoadingContainer,
  Modal,
  Panels,
  Bar,
  Input,
  Page,
  Breadcrumb,
} from 'peach/components'
import { getName } from 'peach/helpers'
import { useReadData, useApi } from 'peach/hooks'
import { CrossCompanyRolesSelect } from 'peach/scopes/session'

const CompanyUser = () => {
  const { url } = useRouteMatch()
  const { companyId, userId } = useParams()

  const api = useApi()

  const [user, loading, error, refresh] = useReadData(
    () => api.company.user.get({ pathArgs: { companyId, userId } }),
    [companyId, userId],
  )

  const sendEditUser = async (_user, diff) => {
    await api.company.user.put({
      pathArgs: { companyId, userId },
      body: diff,
    })
    refresh()
  }

  const initialValue = { roles: user?.roleIds || [], ...user }

  const editUser = (
    <Modal
      initialValue={initialValue}
      onSubmit={sendEditUser}
      title='Edit User'
      trigger
    >
      <Input formKey='associatedPersonId' />

      <CrossCompanyRolesSelect companyId={companyId} formKey='roles' />
    </Modal>
  )

  const header = <Bar right={editUser} title={getName(user)} />

  return (
    <Panels header={header}>
      <Breadcrumb title={getName(user)} to={url} />
      <LoadingContainer error={error} loading={loading}>
        <Page.Full>
          <Model.Item json={user} />
        </Page.Full>
      </LoadingContainer>
    </Panels>
  )
}

export default CompanyUser
