import { useDataQuery, useQueryApi } from '../useQueryApi'

const usePersonConsentsQuery = (options) => {
  const [api, { personId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['people', personId, 'consents'],

    queryFn: async () => api.person.consents.get({ pathArgs: { personId } }),
  })
}

export default usePersonConsentsQuery
