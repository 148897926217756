import { useEffect } from 'react'

import { styled } from 'styled-components'

import { humanizeKey } from 'peach/helpers'
import { useDebounceValue } from 'peach/hooks'

import { FormProvider } from '../../Form/FormProvider'
import { FormIsHiddenProvider } from '../../Form/FormProvider/useFormState'

import { useModalPages } from './useModalPages'

const FormPage = styled.div`
  display: ${(p) => (p.isVisible ? 'block' : 'none')};
  padding: 16px;
  height: 100%;
`

const useModalPage = ({ page, isDefault }) => {
  const { addPage, removePage, activePage } = useModalPages()

  useEffect(() => {
    addPage({ page, isDefault })
    return () => removePage(page)
  }, [page, addPage, removePage, isDefault])

  const isVisible = page && activePage === page

  return { isVisible }
}

const ModalPage = ({ page, formKey, isDefault, children }) => {
  const $page = page || humanizeKey(formKey)

  const { isVisible } = useModalPage({ page: $page, isDefault })

  const [debouncedIsVisible] = useDebounceValue(isVisible, 0)

  const $isVisible = isVisible && debouncedIsVisible

  const contents = formKey ? (
    <FormProvider formKey={formKey}>{children}</FormProvider>
  ) : (
    children
  )

  return (
    <FormPage isVisible={$isVisible}>
      <FormIsHiddenProvider isHidden={!isVisible}>
        {contents}
      </FormIsHiddenProvider>
    </FormPage>
  )
}

export default ModalPage
