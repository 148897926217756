import { useDataQuery, useQueryApi } from '../useQueryApi'

const useTeamsQuery = (options) => {
  const [api, { companyId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['companies', companyId, 'teams'],

    queryFn: () => api.company.teams.get({ pathArgs: { companyId } }),
  })
}

export default useTeamsQuery
