import ReactMarkdown from 'react-markdown'
import { styled } from 'styled-components'

import { useRead } from 'peach/hooks'

import CodeBlock from './CodeBlock'
import 'github-markdown-css/github-markdown.css'

const StyledDiv = styled.div`
  margin: 8px;
  border-radius: 4px;
  background-color: ${(p) => p.theme.background};
  padding: 8px;

  * {
    border-color: ${(p) => p.theme.border} !important;
    color: ${(p) => p.theme.text};
  }

  h2 {
    border-bottom: none !important;
  }

  /* begin hacks to deal with react-markdown changes in v6 */

  /* stylelint-disable selector-class-pattern */
  .react-syntax-highlighter--codeblock {
    margin: 0 !important;
  }

  pre {
    border: 1px solid ${(p) => p.theme.banner} !important;
    background-color: transparent;
    padding: 0;
  }
`

const components = {
  code: CodeBlock,
}

const Markdown = ({ markdown = '', js, url, wrap }) => {
  const [resp, loading] = useRead(async () => {
    if (url) {
      const resp = await fetch(url)
      const text = await resp.text()
      return text.slice(0, 15) !== `<!DOCTYPE html>` ? text : ''
    } else {
      return ''
    }
  }, [url])

  const text = url ? resp : js ? '\n```js\n' + js + '\n```' : markdown

  return (
    <div className='markdown markdown-body'>
      <StyledDiv>
        {loading ? (
          <span>Loading...</span>
        ) : _.isString(text) ? (
          <ReactMarkdown
            parserOptions={{ commonmark: true }}
            components={components}
          >
            {text || ''}
          </ReactMarkdown>
        ) : (
          <span>not a string...</span>
        )}
      </StyledDiv>
    </div>
  )
}

export default Markdown
