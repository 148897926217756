import { useDataQuery, useQueryApi } from '../useQueryApi'

const useInvestorQuery = (options) => {
  const [api, { companyId, investorId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['companies', companyId, 'investors', investorId],

    queryFn: () =>
      api.company.investor.get({
        pathArgs: { companyId, investorId },
      }),

    enabled: !!(companyId && investorId),
  })
}

export default useInvestorQuery
