import styled, { css } from 'styled-components'

import color from 'peach/helpers/color/color'

const Bar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${(p) => p.theme.offset};
  padding: 8px 12px;
`
const Title = styled.span`
  display: inline-block;
  cursor: default;
  color: ${(p) => p.theme.text};
  font-size: 24px;
  font-weight: bold;
`
const Left = styled.span``

const Right = styled.span`
  display: inline-flex;
  align-items: center;
`

const buttonStyles = css`
  margin: 0;
  margin-left: 16px;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  padding: 4px 12px;
  line-height: 1;
  color: ${(p) => p.theme.text};
  font-size: ${(p) => p.fontSize || '24px'};
  font-weight: bold;

  &:hover {
    background-color: ${(p) => color.getHoverColor(p.theme.offset)};
  }
`

const CloseButton = styled.button`
  ${buttonStyles}
  font-size: 24px;
`
const DebugWrapper = styled.span`
  display: inline-block;
`
const LittleButton = styled.button`
  ${buttonStyles}
  font-size: 24px;
`

const ModalTitleBar = (props) => {
  const { title, onClose, onToggleDebugger } = props
  // ⊗×⨂⨷
  return (
    <Bar>
      <Left>
        <Title onDoubleClick={onToggleDebugger}>{title}</Title>
      </Left>
      <Right>
        <DebugWrapper>
          <LittleButton onClick={onToggleDebugger} title='Debugger'>
            ☕️
          </LittleButton>
        </DebugWrapper>
        <CloseButton onClick={onClose} title='Close'>
          ✗
        </CloseButton>
      </Right>
    </Bar>
  )
}

export default ModalTitleBar
