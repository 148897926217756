import { Fragment } from 'react'

import styled, { css } from 'styled-components'

const Main = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
`

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Center = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const sectionStyles = css`
  display: flex;
  flex-basis: auto;
  flex-flow: row no-wrap;
  flex-grow: 1;
  flex-wrap: nowrap;
  align-items: center;
  width: auto;
`

const Title = styled.span`
  display: inline-block;
  flex-grow: 0;
  align-items: center;
  margin-right: 12px;
  color: ${(p) => p.theme.text};
  font-size: 18px;
`

const LeftSection = styled.div`
  ${sectionStyles}
  justify-content: flex-start;

  & > * {
    margin-right: ${(p) => p.gap};

    &:last-child {
      margin-right: none;
    }
  }
`
const RightSection = styled.div`
  ${sectionStyles}
  justify-content: flex-end;

  & > * {
    margin-left: ${(p) => p.gap};

    &:first-child {
      margin-left: none;
    }
  }
`

const CenterSection = styled.div`
  ${sectionStyles}
  justify-content: center;

  & > * {
    margin-left: ${(p) => p.gap};

    &:first-child {
      margin-left: none;
    }
  }
`

const Spacer = styled.span`
  display: inline-block;
  align-self: stretch;
  width: 16px;
`

const Bar = ({ title, left, right, center, gap = '12px' }) => (
  <Main>
    <Left>
      {title && <Title>{title}</Title>}
      {left && <LeftSection gap={gap}>{left}</LeftSection>}
    </Left>

    {center && (
      <Center>
        <CenterSection>{center}</CenterSection>
      </Center>
    )}

    {right && (
      <Right>
        <RightSection gap={gap}>{right}</RightSection>
      </Right>
    )}
  </Main>
)

Bar.Section = Fragment

export { Spacer, Fragment as Section }

export default Bar
