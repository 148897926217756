import { createContext, useContext } from 'react'

const DEBUG = false

const log = (...args) => {
  if (DEBUG) {
    console.info(...args)
  }
}

const defaultFormState = {
  value: {},
  onChange: (valOrFn) => {
    const newVal = _.isFunction(valOrFn) ? valOrFn({}) : valOrFn
    log(`onChange() called without a <FormProvider/>`, newVal)
  },

  clearKey: (key) => {
    log(`clearKey() called without a <FormProvider/>`)
  },
  validation: {},
  onValidationChange: ({ meta, nested } = {}) => {
    log(`onValidationChange() called with a <FormProvider/>`, nested, meta)
  },
}

const ValueCxt = createContext(defaultFormState.value)
const OnChangeCxt = createContext(defaultFormState.onChange)
const ClearKeyCxt = createContext(defaultFormState.clearKey)
const ValidationCxt = createContext(defaultFormState.validation)
const OnValidationChangeCxt = createContext(defaultFormState.onValidationChange)
const IsHiddenCxt = createContext(false)

const useFormValue = () => useContext(ValueCxt)
const useFormOnChange = () => useContext(OnChangeCxt)
const useFormClearKey = () => useContext(ClearKeyCxt)
const useFormValidation = () => useContext(ValidationCxt)
const useFormOnValidationChange = () => useContext(OnValidationChangeCxt)
const useFormIsHidden = () => useContext(IsHiddenCxt)

const FormStateProvider = (props) => {
  const {
    value,
    onChange,
    clearKey,
    validation,
    onValidationChange,
    isHidden,
    children,
  } = props

  return (
    <ValueCxt.Provider value={value}>
      <OnChangeCxt.Provider value={onChange}>
        <ClearKeyCxt.Provider value={clearKey}>
          <ValidationCxt.Provider value={validation}>
            <OnValidationChangeCxt.Provider value={onValidationChange}>
              <IsHiddenCxt.Provider value={isHidden}>
                {children}
              </IsHiddenCxt.Provider>
            </OnValidationChangeCxt.Provider>
          </ValidationCxt.Provider>
        </ClearKeyCxt.Provider>
      </OnChangeCxt.Provider>
    </ValueCxt.Provider>
  )
}

const FormIsHiddenProvider = ({ isHidden, children }) => (
  <IsHiddenCxt.Provider value={isHidden}>{children}</IsHiddenCxt.Provider>
)

export {
  FormStateProvider,
  FormIsHiddenProvider,
  useFormValue,
  useFormOnChange,
  useFormClearKey,
  useFormValidation,
  useFormOnValidationChange,
  useFormIsHidden,
}
