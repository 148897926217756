import {
  createContext,
  useContext,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react'

import { useStorageState } from 'peach/hooks'

import { useAuthState } from 'core/auth/AuthProvider'

const LastCompanyIdContext = createContext({
  lastCompanyId: null,
  setLastCompanyId: () => {},
})

const LastIdsContext = createContext({
  lastIds: {},
  setLastIds: () => {},
  setLastId: () => {},
})

const LastCompanyIdProvider = ({ children }) => {
  const [lastCompanyId, setLastCompanyId] = useState(null)

  const [lastIds, setLastIds] = useStorageState('lastIds', {})

  const { companyId } = useAuthState()

  const $lastCompanyId = lastCompanyId || companyId

  const value = useMemo(
    () => ({ lastCompanyId: $lastCompanyId, setLastCompanyId }),
    [$lastCompanyId],
  )

  const setLastId = useCallback(
    (key, value) => {
      setLastIds((lastIds) => ({ ...lastIds, [key]: value }))
    },
    [setLastIds],
  )

  const lastIdsValue = useMemo(
    () => ({ lastIds, setLastIds, setLastId }),
    [lastIds, setLastIds, setLastId],
  )

  return (
    <LastIdsContext.Provider value={lastIdsValue}>
      <LastCompanyIdContext.Provider value={value}>
        {children}
      </LastCompanyIdContext.Provider>
    </LastIdsContext.Provider>
  )
}

const useLastId = (key) => {
  const { lastIds } = useContext(LastIdsContext)
  return lastIds[key] || undefined
}

const useSetLastId = (key, value) => {
  const { setLastId } = useContext(LastIdsContext)
  useEffect(() => setLastId(key, value), [setLastId, key, value])
}

const useLastCompanyId = () => useContext(LastCompanyIdContext)

export { LastCompanyIdProvider, useLastCompanyId, useSetLastId, useLastId }
