import { forwardRef } from 'react'

import { styled } from 'styled-components'

const ClearButton = styled.button`
  display: inline-block;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  text-align: center;
  color: ${(p) => p.theme.disabledText};
  font-size: 14px;

  &:hover {
    color: ${(p) => p.theme.mutedText};
  }

  &:disabled {
    opacity: 0.5;
    color: ${(p) => p.theme.disabledText};
  }
`

const X = styled.span`
  position: relative;
`

const SelectTopSearch = forwardRef((props, ref) => {
  const { onClick, show, disabled } = props

  const isShown = _.has(props, 'show') ? !!show : true

  return isShown ? (
    <ClearButton onClick={onClick} disabled={disabled} ref={ref}>
      <X>✗</X>
    </ClearButton>
  ) : null
})

SelectTopSearch.displayName = 'SelectTopSearch'

export default SelectTopSearch
