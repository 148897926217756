import { Children, Fragment } from 'react'

import { styled } from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(p) => p.padding};
`
const Spacer = styled.div`
  height: ${(p) => p.gap};
`
const Item = styled.div``

const Stack = ({ children, padding, gap }) => {
  const list = _.compact(Children.toArray(children))

  const count = _.size(list)

  const items = _.map(list, (item, index) => (
    <Fragment key={index}>
      <Item>{item}</Item>
      {index < count - 1 && <Spacer gap={gap || '8px'} />}
    </Fragment>
  ))

  return <Wrapper padding={padding || 0}>{items}</Wrapper>
}

export default Stack
