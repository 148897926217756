import { useDataQuery, useQueryApi } from '../useQueryApi'

const useCaseTypesQuery = (options) => {
  const [api, { caseTypeId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['case-types', caseTypeId],

    queryFn: () =>
      api.caseTypes.caseTypes.get({
        pathArgs: { caseTypeId },
      }),

    enabled: !!caseTypeId,
  })
}

export default useCaseTypesQuery
