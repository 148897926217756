import styled, { css } from 'styled-components'

import Bar from '../../Bar/Bar'
import Panels from '../../Panels/Panels'

import { ModalPagesProvider, useModalPages } from './useModalPages'

const Tabs = styled.div`
  padding: 8px;
`

const Tab = styled.button`
  display: block;
  margin-top: 8px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  padding: 8px 12px;
  width: 100%;
  text-align: left;
  color: ${(p) => p.theme.text};
  font-size: 14px;

  &:hover {
    ${(p) =>
      !p.isActive &&
      css`
        background-color: ${(p) => p.theme.hover};
      `}
  }

  ${(p) =>
    p.isActive &&
    css`
      background-color: ${(p) => p.theme.highlight};
      cursor: default;
      color: ${(p) => p.theme.invertedText};
    `}
`

const ModalPagesContainer = ({ children }) => {
  const { activePage, setActivePage, pages } = useModalPages()

  const tabs = _.map(pages, ({ page }) => (
    <Tab
      key={page}
      isActive={activePage === page}
      onClick={() => setActivePage(page)}
    >
      {page}
    </Tab>
  ))

  const left = <Tabs>{tabs}</Tabs>

  const header = <Bar title={activePage || 'No Page Selected'} />

  return (
    <Panels left={left}>
      <Panels header={header}>{children}</Panels>
    </Panels>
  )
}

const ModalPagesContainerWrapper = ({ children }) => (
  <ModalPagesProvider>
    <ModalPagesContainer>{children}</ModalPagesContainer>
  </ModalPagesProvider>
)

export default ModalPagesContainerWrapper
