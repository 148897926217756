import { styled } from 'styled-components'

// const checkmarks = '☑️✔️✅✓✔︎'

const Box = styled.div`
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.theme.background};
  color: ${(p) => p.theme.highlight};
  line-height: 1;
  border: 1px solid ${(p) => p.theme.border};
  width: 20px;
  height: 20px;
  text-align: center;
  vertical-align: middle;
`

const Check = styled.span`
  line-height: 1;
  font-size: 16px;
  opacity: ${(p) => (p.hidden ? '0.5' : '1')};
`
const Checkbox = ({ value, onChange }) => {
  const handleChange = () => onChange(!value)

  return (
    <Box onClick={handleChange}>
      <Check hidden={!value}>{'›' || '✓'}</Check>
    </Box>
  )
}

export default Checkbox
