import { useDataQuery, useQueryApi } from '../useQueryApi'

const MINUTE = 60 * 1000

const useSessionUserQuery = (options) => {
  const [api] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['session', 'user'],

    queryFn: async () => api.auth.user.get(),

    staleTime: 5 * MINUTE,
  })
}

export default useSessionUserQuery
