import { styled } from 'styled-components'

const ErrorMessage = styled.span`
  display: inline-block;
  font-size: 12px;
  color: red;
  background-color: rgb(255, 220, 220);
  padding: 4px 8px;
  border-radius: 3px;
  margin-top: -8px;
  box-shadow: 0 0 2px red;
`

// const asterisks = '*✹✸✷✶✵✳︎﹡＊⩮❊❉✻✲⊛＊﹡'

const ItemValidationMessage = ({ meta }) => {
  const { errorMessage, hasBlurred, hasChanged } = meta || {}

  const show = (hasBlurred || hasChanged) && errorMessage

  return show ? <ErrorMessage>{errorMessage}</ErrorMessage> : null
}

export default ItemValidationMessage
