import styled, { css } from 'styled-components'

import Link from 'peach/router/Link'

const StyledCard = styled.div`
  position: relative;
`

const StyledLink = styled(Link)`
  display: block;
  border-radius: 4px;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  padding: 8px;
  text-decoration: none;
  color: ${(p) => p.theme.text};

  &:hover:not([disabled]) {
    background-color: ${(p) => p.theme.hover};
  }

  &[disabled] {
    pointer-events: none;
  }

  ${(p) =>
    p.isSelected &&
    css`
      background-color: ${p.theme.hover};
    `}
`

const Title = styled.div`
  opacity: ${(p) => (p.disabled ? 0.5 : 1)};
  color: ${(p) => p.color || (p.isLink ? p.theme.linkText : p.theme.text)};
  font-size: 13px;
  font-weight: bold;
`

const Subtitle = styled.div`
  padding-top: 4px;
  color: ${(p) => p.theme.mutedText};
  font-size: 11px;
`

const Note = styled.div`
  padding-top: 4px;
  color: ${(p) => p.theme.mutedText};
  font-size: 11px;
`

const DisplayCard = (props) => {
  const { title, subtitle, note, color, to, disabled, isSelected, ...rest } =
    props

  return (
    <StyledCard>
      <StyledLink
        to={to || '#'}
        {...rest}
        disabled={disabled}
        isSelected={isSelected}
      >
        <Title color={color} isLink={!!to} disabled={disabled}>
          {title}
        </Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {note && <Note>{note}</Note>}
      </StyledLink>
    </StyledCard>
  )
}

export default DisplayCard
