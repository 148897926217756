import { useState, useEffect } from 'react'

import _ from 'lodash'
import { useParams } from 'react-router-dom'

import { Bar, Panels, Button, Tabs, LoadingContainer } from 'peach/components'
import { useWrite, useApi, usePrevious } from 'peach/hooks'
import { useLastId } from 'peach/scopes/company/LastCompanyIdProvider'

import apiConfig from 'core/api/apiConfig'
import { useAuthState } from 'core/auth/AuthProvider'

import ApiCallCode from './ApiCallCode'
import ApiCallForm from './ApiCallForm'
import ApiCallRequest from './ApiCallRequest'
import ApiCallResponse from './ApiCallResponse'

const re = /{\w+}/g

const getPathArgs = (pathPattern = '') => {
  const list = pathPattern.match(re) || []
  return list.map((str) => str.slice(1).slice(0, -1))
}

const getPathPatternLabel = (pathPattern) => pathPattern.replaceAll(re, ':id')

const ApiCall = () => {
  const authState = useAuthState()
  const lastCompanyId = useLastId('companyId')
  const lastPersonId = useLastId('personId')
  const lastUserId = useLastId('userId')

  const {
    companyId: sessionCompanyId,
    userId: sessionUserId,
    personId: sessionPersonId,
  } = authState || {}

  const { category, action } = useParams()

  const api = useApi()

  const [options, setOptions] = useState({
    method: 'get',
    pathArgs: {
      companyId: lastCompanyId || sessionCompanyId,
      userId: lastUserId || sessionUserId,
      personId: lastPersonId || sessionPersonId,
    },
    body: {},
    queryParams: {},
  })

  const { method, pathArgs, queryParams, body } = options

  const pathPattern = apiConfig[category][action]

  const pathKeys = getPathArgs(pathPattern)

  const pathArgsWithDefaults = _.fromPairs(
    _.map(pathKeys, (key) => [key, pathArgs[key] || '']),
  )

  const callOptions = {
    pathArgs: pathArgsWithDefaults,
    queryParams,
    body,
  }

  const callFn = api[category][action][method]

  const fetchArgs = callFn.getFetchOptions({ ...callOptions })

  const apiCall = () => callFn(callOptions)

  const [send, sending, resp, error, onClear] = useWrite(apiCall)

  const [view, setView] = useState('request')
  const previousResp = usePrevious(resp)

  useEffect(() => {
    if (resp !== previousResp) {
      if (!resp) {
        setView('request')
      } else if (resp) {
        setView('response')
      }
    }
  }, [resp, previousResp])

  const controls = (
    <Bar
      center={
        <Tabs
          onChange={setView}
          options={['request', 'response', 'code']}
          value={view}
        />
      }
      right={
        <>
          <Button disabled={!resp && !error} onClick={onClear}>
            Clear
          </Button>
          <Button onClick={send}>Send</Button>
        </>
      }
      title={`${method.toUpperCase()} ${getPathPatternLabel(pathPattern)}`}
    />
  )

  const onSetParams = (paramsOrFn) => {
    setOptions((options) => {
      const newParams = _.isFunction(paramsOrFn)
        ? paramsOrFn(options.queryParams)
        : paramsOrFn

      return { ...options, queryParams: newParams }
    })
  }

  return (
    <Panels
      header={controls}
      left={
        <ApiCallForm
          onChange={setOptions}
          pathArgsList={getPathArgs(pathPattern)}
          value={options}
        />
      }
      leftWidth='400px'
    >
      <LoadingContainer error={error} loading={sending} onClearError={onClear}>
        {view === 'request' && (
          <ApiCallRequest
            action={action}
            callOptions={callOptions}
            category={category}
            fetchArgs={fetchArgs}
            method={method}
            pathPattern={pathPattern}
          />
        )}
        {view === 'code' && (
          <ApiCallCode
            action={action}
            callOptions={callOptions}
            category={category}
            fetchArgs={fetchArgs}
            method={method}
            pathPattern={pathPattern}
          />
        )}
        {view === 'response' && (
          <ApiCallResponse
            loading={sending}
            onSetParams={onSetParams}
            params={options.queryParams}
            resp={resp}
          />
        )}
      </LoadingContainer>
    </Panels>
  )
}

export default ApiCall
