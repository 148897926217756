import { Dispatch, FC, SetStateAction } from 'react'

import { FormProvider, useForm } from 'react-hook-form'
import * as R from 'remeda'

import { LoadingContainer } from 'peach/components'
import { StyledButton } from 'peach/components/Button/Button'
import Input from 'peach/components/Input'
import { useApi, useWrite } from 'peach/hooks'

import { ResponseAPIKeyNoSecretAPIVersioning } from 'core/types'
import { Role } from 'core/types/generated/schemas-roles/types'

import {
  Error as ErrorComponent,
  FormActions,
  Section,
  SectionLabel,
  Title,
} from './Components'
import { Company } from './types'

type CreateUserAndAPIKeyFormValues = {
  type?: 'borrower' | 'agent' | 'service'
  authType?: {
    email?: string
  }
  roleIds?: Array<string>
}

type CreateUserAndAPIKeyFormProps = {
  company: Company
  updateCompany: (obj: Company) => void
  title: string
  roles?: Array<Role>
  userType: 'lender' | 'peach'
  apiKeys?: Array<ResponseAPIKeyNoSecretAPIVersioning> | []
  setApiKeys: Dispatch<
    SetStateAction<Array<ResponseAPIKeyNoSecretAPIVersioning> | []>
  >
}

const CreateUserAndAPIKeyForm: FC<CreateUserAndAPIKeyFormProps> = ({
  company,
  updateCompany,
  title,
  roles,
  userType,
  apiKeys,
  setApiKeys,
}) => {
  const api: any = useApi()

  const form = useForm<CreateUserAndAPIKeyFormValues>({
    values: {
      type: 'service',
      authType: {
        email: userType === 'peach' ? 'api@peach.finance' : '',
      },
      roleIds: [
        R.pipe(
          roles ?? [],
          R.find((role) =>
            userType === 'peach'
              ? role.alias === 'super-admin-role'
              : role.alias === 'api',
          ),
        )?.id ?? '',
      ],
    },
  })

  const [onCreate, isPending, , error, clear] = useWrite(async (values) => {
    try {
      const userResp = await api.company.users.post({
        body: values,
        pathArgs: { companyId: company?.companyId },
      })

      if (userResp?.status === 201 && !R.isEmpty(userResp?.data)) {
        updateCompany({
          ...company,
          users: [
            ...(company?.users ?? []),
            {
              userType,
              userId: userResp?.data?.userId,
              id: userResp?.data?.id,
            },
          ],
        })
      }

      const apiKeyResp = await api.company.keys.post({
        body: {
          userId: userResp?.data?.id,
          apiVersion: '2018-01-01',
        },
        pathArgs: { companyId: company?.companyId },
      })

      if (apiKeyResp?.status === 201 && !R.isEmpty(apiKeyResp?.data)) {
        setApiKeys([...(apiKeys ?? []), apiKeyResp?.data])
      }
    } catch (e) {
      console.error('Error with create actions: ', e)
      throw e
    }
  })

  return (
    <LoadingContainer loading={isPending}>
      <Title>{title}</Title>
      <form
        onSubmit={form.handleSubmit(async (values) => {
          try {
            clear()
            await onCreate(values)
          } catch (e) {
            console.error('Error with create actions: ', e)
          }
        })}
      >
        <FormProvider {...form}>
          <Input
            label='User Type'
            required
            type='text'
            {...form.register('type')}
          />

          <Section>
            <SectionLabel>Auth Type</SectionLabel>
            <Input
              label='Email'
              required
              type='text'
              {...form.register('authType.email')}
            />
          </Section>

          <Input
            disabled
            label='User Type'
            type='text'
            value={userType === 'peach' ? 'super-admin-role' : 'api-role'}
          />

          {error && <ErrorComponent>{error.message}</ErrorComponent>}
          <FormActions>
            <StyledButton primary type='submit'>
              Submit
            </StyledButton>
          </FormActions>
        </FormProvider>
      </form>
    </LoadingContainer>
  )
}

export default CreateUserAndAPIKeyForm
