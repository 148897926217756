import { useDataQuery, useQueryApi } from '../useQueryApi'

const useEmployeesQuery = (options) => {
  const [api, { companyId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['companies', companyId, 'employees'],

    queryFn: () =>
      api.company.employees.getAll({
        pathArgs: { companyId },
        queryParams: { limit: 100 },
      }),
  })
}

export default useEmployeesQuery
