import { useDataQuery, useQueryApi } from '../useQueryApi'

const useContactsQuery = (options) => {
  const [api, { companyId, personId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['companies', companyId, 'borrowers', personId, 'contacts'],

    queryFn: async () => api.person.contacts.getAll({ pathArgs: { personId } }),
  })
}

export default useContactsQuery
