import { useMemo } from 'react'

import _ from 'lodash'
import { useRouteMatch } from 'react-router-dom'

import {
  ListNav2,
  LoadingContainer,
  Panels,
  Breadcrumb,
} from 'peach/components'
import { useLoansQuery, usePersonQuery } from 'peach/data'
import { getName } from 'peach/helpers'

import PersonRoutes from './PersonRoutes'

const getMainItems = () => [
  ['Borrower', '', true],
  '/contacts',
  '/payment-instruments',
  '/consents',
  '/interactions',
  '/cases',
  '/documents',
  { label: 'Loans', to: '/loans', exact: true },
]

const Person = () => {
  const { url } = useRouteMatch()
  const [person, query] = usePersonQuery()
  const [loans] = useLoansQuery()

  const name = getName(person)

  const items = useMemo(() => {
    const groupedByStatus = _.groupBy(loans, 'status')
    const pairs = _.sortBy(_.toPairs(groupedByStatus), (pair) => pair[0])

    const sections = _.map(pairs, (pair) => {
      const [status, loanGroup] = pair

      const items = _.map(loanGroup, (loan) => ({
        key: loan.id,
        label: getName(loan),
        to: `/loans/${loan.id}`,
        note: loan.id,
      }))
      const label = _.str.humanize(status) + ' Loans'

      return { label, items, key: `loans-group--${label}` }
    })

    return [...getMainItems(name), ...sections]
  }, [loans, name])

  const nav = <ListNav2 baseUrl={url} items={items} searchable />

  return (
    <Panels left={nav}>
      <Breadcrumb title={name} to={url} />
      <LoadingContainer query={query}>
        <PersonRoutes person={person?.data} />
      </LoadingContainer>
    </Panels>
  )
}

export default Person
