import { useState, useEffect, useMemo, createContext, useContext } from 'react'

import _ from 'lodash'

import { usePeachGlobal, useStableCallback } from 'peach/hooks'

import makeStorage from '../storage/makeStorage'

const envStorage = makeStorage('peach-env')

const EnvApiBaseContext = createContext('')
const EnvSetApiBaseContext = createContext(() => {})
const EnvApiContext = createContext({})

const parseOrigin = (apiBase) => {
  try {
    return apiBase.split('//')[1].split('/')[0]
  } catch {
    return apiBase
  }
}

const getNewEnvKey = (obj, envs) => {
  const { key, apiBase } = obj

  const isUnique = (newKey) => {
    if (!newKey) return false
    return _.every(envs, ({ id, key }) => newKey !== id && newKey !== key)
  }

  if (isUnique(key)) return key

  const newKey = parseOrigin(apiBase)

  let safeKey = undefined
  let count = 1
  while (!isUnique(safeKey) && count < 20) {
    count = count + 1
    safeKey = `${newKey}--${count}`
  }
  return safeKey
}

const parseEnvs = (envs = []) => {
  const newEnvs = []
  _.each(envs, (env) => {
    const { key, ...rest } = env || {}
    const newEnv = key
      ? env
      : { ...rest, key: getNewEnvKey(env, [...envs, ...newEnvs]) }

    newEnvs.push(newEnv)
  })
  return newEnvs
}

const makeEnv = (env, envs) => {
  const { label, apiBase, companyId, userType, username } = env

  const key = getNewEnvKey(env, envs)

  return {
    id: key,
    key,
    label,
    apiBase,
    companyId,
    userType,
    username,
  }
}

const useEnvManagement = () => {
  usePeachGlobal('envStorage', envStorage)

  const [activeId, setActiveId] = useState(() => envStorage.get('activeId'))
  useEffect(() => envStorage.set('activeId', activeId), [activeId])

  const [envs, setEnvs] = useState(() => parseEnvs(envStorage.get('envs')))
  useEffect(() => envStorage.set('envs', envs), [envs])

  const env = _.find(envs, { id: activeId })

  const activeKey = env?.key

  const addEnv = useStableCallback((envData) => {
    const newEnv = makeEnv(envData, envs)
    setEnvs((envs) => {
      const oldList = _.reject(envs, { id: newEnv.id })
      return [...oldList, newEnv]
    })

    return newEnv
  })

  const editEnv = useStableCallback((id, envData) => {
    setEnvs((envs) => {
      const list = [...envs]
      const envIndex = _.findIndex(envs, { id })
      const existingEnv = envs[envIndex]
      list[envIndex] = { ...existingEnv, ...envData }
      return list
    })
  })

  const storage = useMemo(() => makeStorage(activeKey), [activeKey])

  const removeEnvId = useStableCallback((id) => {
    storage.clear()
    const newEnvs = _.reject(envs, { id })
    if (id === activeId) {
      setActiveId(_.first(newEnvs)?.id)
    }
    setEnvs(newEnvs)
  })

  usePeachGlobal('appStorage', storage)

  const envApi = useMemo(
    () => ({
      envs,
      env,
      activeId,
      activeKey,
      setActiveId,
      addEnv,
      editEnv,
      removeEnvId,
    }),
    [envs, env, activeId, activeKey, addEnv, editEnv, removeEnvId],
  )

  return [env?.apiBase, storage, envApi, env?.id]
}

const EnvProvider = ({ apiBase, setApiBase, envApi, children }) => (
  <EnvApiContext.Provider value={envApi}>
    <EnvApiBaseContext.Provider value={apiBase}>
      <EnvSetApiBaseContext.Provider value={setApiBase}>
        {children}
      </EnvSetApiBaseContext.Provider>
    </EnvApiBaseContext.Provider>
  </EnvApiContext.Provider>
)

const useApiBase = () => useContext(EnvApiBaseContext)

const useSetApiBase = () => useContext(EnvSetApiBaseContext)

const useEnvApi = () => useContext(EnvApiContext)

export { EnvProvider, useApiBase, useSetApiBase, useEnvApi, envStorage }

export default useEnvManagement
