import {
  MouseEvent as ReactMouseEvent,
  ReactNode,
  useEffect,
  useState,
} from 'react'

import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form'
import ReactSelect, {
  GroupBase,
  MultiValue,
  OnChangeValue,
  Props as ReactSelectProps,
  SingleValue,
} from 'react-select'
import { styled } from 'styled-components'

import { Label } from 'peach/components'

import { R } from 'core/helpers'

import {
  DropdownIndicator,
  MenuList,
  Option,
  OptionsOrGroups,
  SelectOption,
  ValueContainer,
} from './components'
import customStyles from './customStyles'

export const usePendingCallback = <
  T extends Array<unknown>,
  R extends Promise<unknown> | unknown,
>(
  cb?: (...args: T) => R,
) => {
  const [isPending, setIsPending] = useState(false)

  return [
    isPending,
    (...args: T) => {
      const res = cb?.(...args)

      if (res instanceof Promise) {
        setIsPending(true)
        res.finally(() => setIsPending(false)).catch(_.noop)
      }

      return res
    },
  ] as const
}

export const findByValue = <T,>(
  options: OptionsOrGroups<T> | undefined,
  value: T,
) =>
  R.pipe(
    options ?? [],
    R.flatMap((o) => ('options' in o ? o.options : o)),
    R.find((o) => R.equals(o.value, value)),
  )

export type PendingEventHandler<
  T = ReactMouseEvent<HTMLButtonElement, MouseEvent>,
> = (event: T) => Promise<unknown> | void

type SelectInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean,
  Value,
> = Omit<UseControllerProps<TFieldValues, TName>, 'disabled'> &
  Omit<
    ReactSelectProps<
      SelectOption<Value>,
      IsMulti,
      GroupBase<SelectOption<Value>>
    >,
    | 'disabled'
    | 'onChange'
    | 'onBlur'
    | 'value'
    | 'name'
    | 'styles'
    | 'defaultValue'
  > & {
    className?: string
    width?: string
    onChange?: PendingEventHandler<OnChangeValue<SelectOption<Value>, IsMulti>>
    unwrapValue?: boolean
    prefix?: ReactNode
    label?: string
  }

const SelectInput = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean = false,
  Value = string,
>({
  className,
  width,
  control,
  defaultValue,
  name,
  rules,
  shouldUnregister,
  options,
  isMulti,
  isDisabled,
  isLoading,
  label,
  onChange: passedOnChange,
  ...reactSelectProps
}: SelectInputProps<TFieldValues, TName, IsMulti, Value>) => {
  const {
    formState,
    field: { onChange: controlOnChange, value, disabled, ...field },
  } = useController({
    control,
    defaultValue,
    name,
    rules,
    shouldUnregister,
    disabled: isDisabled,
  })

  const selected =
    typeof value !== 'boolean' && !value
      ? value
      : isMulti
      ? R.pipe(
          value as Array<Value>,
          R.map((v) => findByValue(options, v)),
          R.compact,
        )
      : findByValue<Value>(options, value)

  useEffect(() => {
    if (!isLoading && value && selected === undefined) {
      controlOnChange(formState.defaultValues?.[name])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  const checkMulti = (
    option: MultiValue<SelectOption<Value>> | SingleValue<SelectOption<Value>>,
  ): option is MultiValue<SelectOption<Value>> => !!isMulti

  const rawOnChange: typeof passedOnChange = (option) => {
    controlOnChange(
      !option
        ? option
        : checkMulti(option)
        ? R.map(option, R.prop('value'))
        : option.value,
    )
    return passedOnChange?.(option)
  }
  const [isPending, onChange] = usePendingCallback(rawOnChange)

  // This needs to be passed as a prop so we can share styles with the existing Select component.
  // I'm not exposing it as a prop because I don't believe it should be optional as these fields
  // quickly become unusable if the selections do not wrap onto newlines.
  const additionalProps = { wrap: true }

  return (
    <Box className={className} width={width}>
      {label && <Label>{label}</Label>}
      <ReactSelect
        components={{
          DropdownIndicator,
          MenuList,
          Option,
          ValueContainer,
        }}
        styles={customStyles}
        options={options}
        isDisabled={
          isPending || isLoading || disabled || formState.isSubmitting
        }
        onChange={onChange}
        menuPlacement='auto'
        isLoading={isPending || isLoading}
        value={selected} // Workaround for JedWatson/react-select#5729
        {...reactSelectProps}
        {...field}
        {...additionalProps}
        {...(isMulti && { isMulti })}
      />
    </Box>
  )
}

export default SelectInput

const Box = styled.div<{ width?: string }>`
  padding-top: 12px;
  padding-bottom: 12px;
  width: ${(p) => p.width ?? 'initial'};
`
