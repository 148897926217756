import { createContext, useContext } from 'react'

const AuthApiContext = createContext({})
const AuthStateContext = createContext({})
const SessionPersonContext = createContext({})

const AuthProvider = ({ authState, authApi, person, children }) => (
  <AuthStateContext.Provider value={authState}>
    <AuthApiContext.Provider value={authApi}>
      <SessionPersonContext.Provider value={person}>
        {children}
      </SessionPersonContext.Provider>
    </AuthApiContext.Provider>
  </AuthStateContext.Provider>
)

const useAuthState = () => useContext(AuthStateContext)

const useAuthApi = () => useContext(AuthApiContext)

const useSessionPerson = () => useContext(SessionPersonContext)

export { AuthProvider, useAuthState, useAuthApi, useSessionPerson }
