import { styled } from 'styled-components'

const SourceItem = ({
  id,
  title,
  description,
  data,
  onClick,
  selectedSource,
  setSelectedSource,
}) => (
  <Container
    $selected={selectedSource === id}
    onClick={() => {
      onClick(data)
      setSelectedSource(id)
    }}
  >
    <Title>{title}</Title>
    <Description>{description}</Description>
  </Container>
)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  margin-bottom: 20px;
  border: 1px solid ${(p) => p.theme.border};
  border-radius: 4px;
  background-color: ${(p) =>
    p.$selected ? `${p.theme.border} !important` : p.theme.offset};
  cursor: pointer;
  padding: 20px;
  color: ${(p) =>
    p.$selected ? `${p.theme.text} !important` : p.theme.labelText};

  &:hover {
    background: ${(p) => p.theme.hover};
  }
`

const Title = styled.div`
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
`

const Description = styled.div`
  font-size: 14px;
`

export default SourceItem
