import { styled } from 'styled-components'

import Textarea from '../Textarea/Textarea'

const Wrapper = styled.div`
  position: relative;
  background-color: ${(p) => p.theme.offset};
  width: 100%;
  font-size: 14px;
`

const Raw = ({ value, onChange }) => (
  <Wrapper>
    <Textarea value={value} onChange={onChange} />
  </Wrapper>
)

export default Raw
