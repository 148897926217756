import { Select } from 'peach/components'
import { useCompanyRolesQuery } from 'peach/data'
import { getName } from 'peach/helpers'

const CompanyRolesSelect = (props) => {
  const [roles] = useCompanyRolesQuery()

  const options = _.map(roles, (role) => ({
    value: role.id,
    label: getName(role),
  }))

  return <Select multi {...props} label='Roles' options={options} />
}

export default CompanyRolesSelect
