import { useState } from 'react'

import _ from 'lodash'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import { Panels, ListNav, Breadcrumb, Markdown, Input } from 'peach/components'
import { humanizeKey } from 'peach/helpers'
import { useApi } from 'peach/hooks'

import apiMarkdownUrl from './api.md'
import ApiCall from './ApiCall'

const compactMap = (...args) => _.compact(_.map(...args))

const matches = (query = '', ...values) =>
  !query ||
  _.some(values, (value) => value.toLowerCase().includes(query.toLowerCase()))

const ApiRoutes = () => {
  const { path, url } = useRouteMatch()

  const api = useApi()

  const [query, setQuery] = useState('')

  const nav = (
    <ListNav>
      {compactMap(api, (section, category) => {
        const items = compactMap(section, (caller, name) => {
          if (matches(query, category, name)) {
            return (
              <ListNav.Link
                key={name}
                subtitle={caller._pathPattern.replaceAll(/{\w+}/g, ':id')}
                to={`${path}/${category}/${name}`}
              >
                {humanizeKey(name)}
              </ListNav.Link>
            )
          }
        })

        if (!_.isEmpty(items)) {
          return (
            <ListNav.Section key={category} title={humanizeKey(category)}>
              {items}
            </ListNav.Section>
          )
        }
      })}
    </ListNav>
  )

  const header = (
    <div style={{ padding: 8 }}>
      <Input
        bare
        onChange={setQuery}
        placeholder='Filter API Calls'
        value={query}
      />
    </div>
  )

  const sidebar = <Panels header={header}>{nav}</Panels>

  return (
    <Panels left={sidebar}>
      <Switch>
        <Route exact path='/api'>
          <Markdown.Page url={apiMarkdownUrl} />
        </Route>
        <Route
          path='/api/:category/:action'
          render={({ match }) => {
            const { category, action } = match.params
            return (
              <>
                <Breadcrumb
                  category={false}
                  title={humanizeKey(category)}
                  to={`${url}/${category}`}
                />
                <Breadcrumb
                  title={humanizeKey(action)}
                  to={`${url}/${category}/${action}`}
                />
                <ApiCall key={category + action} />
              </>
            )
          }}
        />
      </Switch>
    </Panels>
  )
}

export default ApiRoutes
