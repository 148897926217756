import { forwardRef } from 'react'

import { styled } from 'styled-components'

const Trigger = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${(p) => p.theme.border};
  border-radius: 4px;
  background-color: ${(p) => p.theme.background};
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  padding: 8px 12px;
  width: 100%;
  width: ${(p) => p.width};
  min-width: 180px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(p) => (p.triggerHasValue ? p.theme.text : p.theme.mutedText)};

  &:disabled {
    border-color: ${(p) => p.theme.border};
    background-color: ${(p) => p.theme.offset};
    color: #888;
  }
`

const TriggerLabel = styled.span`
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Chevron = styled.span`
  flex-shrink: 0;
  padding-left: 8px;
  color: ${(p) => (p.disabled ? p.theme.disabledText : p.theme.mutedText)};
`

const SelectTrigger = forwardRef((props, ref) => {
  const {
    isOpen,
    onOpen,
    onClose,
    triggerLabel,
    triggerHasValue,
    disabled,
    width,
  } = props

  const handleKeyDown = (event) => {
    if (props.onKeyDown) props.onKeyDown(event)
    if (event.key === 'ArrowDown') {
      event.preventDefault()
      onOpen()
    }
  }

  return (
    <Trigger
      ref={ref}
      onClick={isOpen ? onClose : onOpen}
      onKeyDown={handleKeyDown}
      disabled={disabled}
      width={width}
      triggerHasValue={triggerHasValue}
    >
      <TriggerLabel>{triggerLabel}</TriggerLabel>
      <Chevron disabled={disabled}>▼</Chevron>
    </Trigger>
  )
})

SelectTrigger.displayName = 'SelectTrigger'

export default SelectTrigger
