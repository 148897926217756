import variables from 'core/theme/variables'

const borderAndMarginStyles = (position) => ({
  [`margin${position}`]: '-4px',
  [`border${position}`]: '0',
  [`border${position}LeftRadius`]: '0',
  [`border${position}RightRadius`]: '0',
})

const baseContainerStyles = (provided, state) => {
  const { selectProps } = state || {}
  const { classNamePrefix } = selectProps || {}
  return {
    ...provided,
    fontSize: classNamePrefix === 'inline' ? '14px' : '16px',
  }
}

const baseControlStyles = (provided, state) => {
  const { isFocused, menuIsOpen, selectProps } = state || {}
  const { classNamePrefix } = selectProps || {}

  return {
    ...provided,
    alignItems: 'normal',
    cursor: 'pointer',
    ...(isFocused || menuIsOpen
      ? {
          /**
           * Using !important to override the hover styles
           * for inline drop downs, since those styles are
           * slightly more specific than this.
           */
          borderColor: `${variables.colorBlueSecondary} !important`,
          boxShadow: 'none',
        }
      : classNamePrefix === 'inline'
      ? {
          '&:hover': { borderColor: 'transparent' },
          borderColor: 'transparent',
          minHeight: '0',
        }
      : {
          borderColor: variables.colorBlack20,
          boxShadow: 'none',
          '&:hover': { borderColor: variables.colorBlack30 },
        }),
  }
}

const baseDropdownIndicator = (provided, state) => {
  const { selectProps } = state || {}
  const { classNamePrefix } = selectProps || {}

  const stylesForInlineDropdown =
    classNamePrefix === 'inline' ? { padding: '0' } : {}

  return {
    ...provided,
    ...stylesForInlineDropdown,
  }
}

const baseIndicatorSeparatorStyles = (provided) => ({
  ...provided,
  display: 'none',
  paddingLeft: '0',
})

const baseMenuStyles = (provided, { selectProps, placement }: any = {}) => ({
  ...provided,
  boxShadow: 'none',
  border: `1px solid ${variables.colorBlueSecondary}`,
  ...borderAndMarginStyles(placement === 'bottom' ? 'Top' : 'Bottom'),
  ...(selectProps.classNamePrefix === 'inline' ? { borderWidth: '1px' } : {}),
})

const baseMenuListStyles = (provided, any) => ({
  ...provided,
  '::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    width: '12px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '8px',
    backgroundColor: variables.colorBlack30,
    border: '3px solid white',
  },
})

const baseMultiValueLabelStyles = (provided, state) => {
  const { selectProps } = state || {}
  const { wrap } = selectProps || {}
  const stylesForWrap = {
    whiteSpace: wrap ? 'wrap' : 'nowrap',
  }
  return {
    ...provided,
    ...stylesForWrap,
  }
}

const baseOptionStyles = (provided, state) => {
  const { isDisabled, isFocused, isSelected, selectProps } = state || {}
  const { classNamePrefix } = selectProps || {}

  const stylesForInlineDropdown =
    classNamePrefix === 'inline'
      ? {
          padding: '12px',
          fontSize: 'inherit',
          ':hover': {
            backgroundColor: variables.colorBlack10,
          },
        }
      : { paddingLeft: '12px' }

  return {
    ...provided,
    display: 'flex',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    backgroundColor: isFocused ? variables.colorBlack10 : null,
    color: isDisabled ? variables.colorBlack50 : variables.colorBlack70,
    fontWeight: isSelected ? 500 : 400,
    ':hover': {
      backgroundColor: isDisabled && 'transparent',
    },
    svg: {
      fill: variables.colorBlack90,
    },
    padding: '8px',
    ...stylesForInlineDropdown,
  }
}

const basePlaceholderStyles = (provided, state) => {
  const { selectProps } = state || {}
  const { classNamePrefix } = selectProps || {}

  const stylesForInlineDropdown =
    classNamePrefix === 'inline' ? { fontSize: 'inherit' } : {}

  return {
    ...provided,
    // react-select default overrides
    position: 'relative',
    top: '0',
    transform: 'none',
    margin: '0',
    // end react-select overrides ---
    color: variables.colorBlack50,
    fontSize: '16px',
    whiteSpace: 'nowrap',
    ...stylesForInlineDropdown,
  }
}

const baseSingleValueStyles = (provided, state) => {
  const { selectProps } = state || {}
  const { classNamePrefix } = selectProps || {}

  const stylesForInlineDropdown =
    classNamePrefix === 'inline'
      ? {
          maxWidth: 'none',
          color: 'inherit',
        }
      : {}

  return {
    ...provided,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '0',
    marginRight: '0',
    maxWidth: 'none',
    ...stylesForInlineDropdown,
  }
}

const baseValueContainerStyles = (provided, state) => {
  const { selectProps } = state || {}
  const { classNamePrefix, wrap } = selectProps || {}

  const stylesForInlineDropdown =
    classNamePrefix === 'inline'
      ? {
          fontSize: 'inherit',
          /**
           * Using 4px top/bottom padding to split the 8px padding
           * that was originally on the Row component, which now
           * has 4px top/bottom padding and is used to wrap this
           * component.
           */
          padding: '4px 0 4px 10px',
        }
      : {}

  const stylesForWrap = {
    flexWrap: wrap ? 'wrap' : 'nowrap',
  }

  return {
    ...provided,
    ...stylesForInlineDropdown,
    ...stylesForWrap,
  }
}

const customStyles = {
  container: (provided, state) => baseContainerStyles(provided, state),
  control: (provided, state) => baseControlStyles(provided, state),
  dropdownIndicator: (provided, state) =>
    baseDropdownIndicator(provided, state),
  indicatorSeparator: (provided) => baseIndicatorSeparatorStyles(provided),
  menu: (provided, state) => baseMenuStyles(provided, state),
  menuList: (provided, state) => baseMenuListStyles(provided, state),
  multiValueLabel: (provided, state) =>
    baseMultiValueLabelStyles(provided, state),
  option: (provided, state) => baseOptionStyles(provided, state),
  placeholder: (provided, state) => basePlaceholderStyles(provided, state),
  singleValue: (provided, state) => baseSingleValueStyles(provided, state),
  valueContainer: (provided, state) =>
    baseValueContainerStyles(provided, state),
}

export default customStyles
