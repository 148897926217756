import { useState, useMemo, Fragment } from 'react'

import { styled } from 'styled-components'

import { searchObjects } from 'peach/helpers'
import { useDebounceValue } from 'peach/hooks'

import Input from '../InputLegacy/Input'
import Panels from '../Panels/Panels'

import NavItem from './NavItem'

const getKey = (item, index) => {
  const { key, id, to, label } = item || {}
  return _.isEmpty(item) ? `index-${index}` : `key-${key}-${id}-${to}-${label}`
}

const Wrapper = styled.div`
  padding: 8px;
`

const Heading = styled.div`
  margin-bottom: 4px;
  padding: 4px;
  color: ${(p) => p.theme.text};
  font-size: 14px;
  font-weight: bold;
`
const InputWrapper = styled.div`
  padding: 8px;
`

const matches = (str = '', rawQuery = '') => {
  const query = rawQuery.trim().toLowerCase()
  if (!query) return true
  return str.toLowerCase().includes(query)
}

const filterItems = (items, query) => {
  if (!query) return items

  const filtered = _.map(items, (item) => {
    const { label, note, items } = item

    if (matches(label, query) || matches(note, query)) {
      return item
    }

    if (items) {
      const subItems = searchObjects(items, query)
      if (!_.isEmpty(subItems)) {
        return { ...item, items: subItems }
      }
    }
  })

  return _.compact(filtered)
}

const formatItem = (item, { baseUrl } = {}) => {
  if (_.isString(item)) {
    const label = _.str.titleize(_.str.humanize(_.last(item.split('/'))))
    return { to: `${baseUrl}${item}`, label, key: item }
  }

  if (_.isArray(item)) {
    const [label, to, exact] = item
    return { label, to: `${baseUrl}${to}`, exact }
  }

  if (_.isObject(item) && item.items) {
    const items = _.map(item.items, (item) => formatItem(item, { baseUrl }))
    return { ...item, items }
  }

  if (item.to) {
    return { ...item, to: `${baseUrl}${item.to}` }
  }

  return item
}

const formatItems = (items, { baseUrl } = {}) =>
  _.map(items, (item) => formatItem(item, { baseUrl }))

const ListNav2 = ({ baseUrl = '', items, searchable }) => {
  const [query, setQuery] = useState('')

  const [debouncedQuery] = useDebounceValue(query, 100)

  const formattedItems = useMemo(
    () => formatItems(items, { baseUrl }),
    [items, baseUrl],
  )

  const filteredItems = useMemo(
    () => filterItems(formattedItems, debouncedQuery),
    [formattedItems, debouncedQuery],
  )

  const finalItems = _.isEmpty(filteredItems) ? formattedItems : filteredItems

  const displayItems = _.map(
    finalItems,
    ({ items, ...item } = {}, outerIndex) => {
      if (items) {
        const { label } = item
        const heading = <Heading>{label}</Heading>
        const subItems = _.map(items, (item, innerIndex) => (
          <NavItem
            key={getKey(item, `${outerIndex}-${innerIndex}`)}
            item={item}
          />
        ))
        return (
          <Fragment key={getKey(item, outerIndex)}>
            {heading}
            {subItems}
          </Fragment>
        )
      }
      return <NavItem key={getKey(item, outerIndex)} item={item} />
    },
  )

  const searchBar = searchable && (
    <InputWrapper>
      <Input value={query} onChange={setQuery} placeholder='Search' bare />
    </InputWrapper>
  )
  return (
    <Panels>
      {searchBar}
      <Wrapper>{displayItems}</Wrapper>
    </Panels>
  )
}

export default ListNav2
