import { Switch, Redirect, useRouteMatch } from 'react-router-dom'

import { getName } from 'peach/helpers'

import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import Model from '../components/ModelList'
import Page from '../components/Page'

import Route from './Route'

const ListSubroutes = (props) => {
  const { list, item, paramKey = 'itemId', json, title } = props

  const { path, url } = useRouteMatch()

  return (
    <Switch>
      <Route path={path} exact>
        {list || (
          <Page.Full>
            <Model.List title={title} json={json} />
          </Page.Full>
        )}
      </Route>
      <Route
        path={`${path}/:${paramKey}`}
        render={({ match }) => {
          if (item) return item
          const id = match.params[paramKey]
          const data = json?.data ? json.data : json
          const itemJson = _.find(data, { id })
          const name = getName(itemJson)
          return (
            <Page.Full>
              <Breadcrumb title={name} to={match.url} />
              <Model.Viewer json={itemJson} />
            </Page.Full>
          )
        }}
      />
      <Redirect to={url} />
    </Switch>
  )
}

export default ListSubroutes
