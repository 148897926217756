import _ from 'lodash'

import { LoadingContainer, Page, Model, Modal } from 'peach/components'
import { useLoanTypesQuery } from 'peach/data'
import { humanizeKey } from 'peach/helpers'
import { useApi } from 'peach/hooks'

import {
  CreateInstallmentLoanTypeForm,
  CreateLineOfCreditLoanTypeForm,
} from './CreateLoanTypeForm/CreateLoanTypeForm'
import {
  defaultInstallmentLoanType,
  defaultLineOfCreditLoanType,
} from './CreateLoanTypeForm/defaultLoanType'

const LoanTypes = () => {
  const [loanTypes, query] = useLoanTypesQuery()

  const sortedLoanTypes = _.orderBy(loanTypes, ['createdAt'], ['desc'])

  const api = useApi()

  const sendCreateLoanType = async (loanType) => {
    await api.company.loanTypes.post({ body: loanType })
    query.refetch()
  }

  const groupedLoanTypes = _.groupBy(sortedLoanTypes, 'object')

  const createInstallmentLoanType = (
    <Modal
      initialValue={defaultInstallmentLoanType}
      onSubmit={sendCreateLoanType}
      pages
      title='Create Installment Loan Type'
      trigger
    >
      <CreateInstallmentLoanTypeForm />
    </Modal>
  )

  const createLineOfCreditLoanType = (
    <Modal
      initialValue={defaultLineOfCreditLoanType}
      onSubmit={sendCreateLoanType}
      pages
      title='Create Line Of Credit Loan Type'
      trigger
    >
      <CreateLineOfCreditLoanTypeForm />
    </Modal>
  )

  return (
    <LoadingContainer query={query}>
      {createInstallmentLoanType}
      {createLineOfCreditLoanType}
      <Page.Full>
        {_.map(groupedLoanTypes, (group, key) => (
          <Model.List json={group} key={key} title={humanizeKey(key)} />
        ))}
      </Page.Full>
    </LoadingContainer>
  )
}

export default LoanTypes
