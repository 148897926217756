import { useDataQuery, useQueryApi } from '../useQueryApi'

const useTemplateDescriptorQuery = (options) => {
  const [api, { companyId, templateDesriptorId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: [
      'companies',
      companyId,
      'templateDescriptors',
      templateDesriptorId,
    ],

    queryFn: () =>
      api.communicator.templateDescriptor.get({
        pathArgs: { templateDesriptorId },
        queryParams: { companyId },
      }),
  })
}

export default useTemplateDescriptorQuery
