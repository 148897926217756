import { useDataQuery, useQueryApi } from '../useQueryApi'

const useWorkflowTypesQuery = (options) => {
  const [api, { companyId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['companies', companyId, 'workflow-types'],

    queryFn: () => api.company.workflowTypes.get({ pathArgs: { companyId } }),
  })
}

export default useWorkflowTypesQuery
