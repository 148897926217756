import { NavLink } from 'react-router-dom'
import { styled } from 'styled-components'

import { Bar } from 'peach/components'

import { useAuthState } from 'core/auth/AuthProvider'
import { useEnvApi } from 'core/env/useEnv'

import AppEnvMenu from './AppEnvMenu'
import AppSearch from './AppSearch/AppSearch'
import AppUserInfo from './AppUserInfo'
import { headerItemStyle } from './headerStyles'

const NavItem = styled(NavLink)`
  ${headerItemStyle}
`

const Title = styled(NavLink)`
  padding: 4px;
  text-decoration: none;
  color: ${(p) => p.theme.text};
  font-size: 20px;
  font-weight: bold;

  &:not(:disabled) {
    cursor: pointer;
  }
`

// const emoji = '⚗️ 😮👤👀👁👨‍💻👨‍💻🧶🧵🧤🎩🐲'
// const companies = ['🧐', '🎩', '🏢', '🏬', '🕋', '📠']
// const people = ['👤', '👥', '😼', '⛄️']
// const tools = ['🍑', '🪓', '🧰', '🗜', '⛏', '🔭', '🔬', '🔩', '🔨', '🔧', '🛠']

// const company = '🏢'
const tool = '🛠'

const AppHeader = () => {
  const { env } = useEnvApi()

  const { isLoggedIn } = useAuthState()

  const title = env?.label

  const left = (
    <Bar.Section>
      <Title disabled={!isLoggedIn} exact to='/'>
        {`<${title || tool}/>`}
      </Title>
      <AppSearch />
    </Bar.Section>
  )

  const right = (
    <Bar.Section>
      <NavItem to='/scratch'>📝</NavItem>
      <NavItem to='/api'>🛠</NavItem>
      <AppUserInfo />
      <AppEnvMenu />
    </Bar.Section>
  )
  return <Bar left={left} right={right} />
}

export default AppHeader
