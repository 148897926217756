import { useState, createContext, useContext, useMemo, useEffect } from 'react'

import _ from 'lodash'
import { ThemeProvider } from 'styled-components'

import { useSessionCompaniesQuery } from 'peach/data'
import { useLastId } from 'peach/scopes/company/LastCompanyIdProvider'

import { envStorage as storage } from '../env/useEnv'

import getTheme from './getTheme'
import useDetectColorScheme from './useDetectColorScheme'

// todo: add 'system' which would always listen to the system value

const DarkModeContext = createContext({
  darkMode: 'light',
  setDarkMode: () => {},
  sysDarkMode: 'light',
})

const DarkModeProvider = ({ children }) => {
  const sysDarkMode = useDetectColorScheme()

  const [companies] = useSessionCompaniesQuery()

  const lastCompanyId = useLastId('companyId')

  const [darkMode, setDarkMode] = useState(
    () => storage.get('darkMode') || sysDarkMode,
  )

  useEffect(() => {
    if (sysDarkMode && !darkMode) setDarkMode(sysDarkMode)
  }, [darkMode, sysDarkMode])

  const storeDarkMode = useMemo(
    () => (key) => {
      setDarkMode(key)
      storage.set('darkMode', key)
    },
    [],
  )

  const company = _.find(companies, { id: lastCompanyId })

  const colors = company?.config?.brandAssets?.colors

  const darkModeInfo = useMemo(
    () => ({
      darkMode,
      setDarkMode: storeDarkMode,
      sysDarkMode,
    }),
    [darkMode, storeDarkMode, sysDarkMode],
  )

  const theme = getTheme(darkMode, colors)

  return (
    <DarkModeContext.Provider value={darkModeInfo}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </DarkModeContext.Provider>
  )
}

const useDarkModeInfo = () => useContext(DarkModeContext)

export { useDarkModeInfo }

export default DarkModeProvider
