import { styled } from 'styled-components'

const Button = styled.button`
  margin: 12px;
  padding: 6px 12px;
`

export const Warning = styled.div`
  border: 3px solid red;
  background-color: #fcc;
  padding: 30px;
  overflow-y: auto;
`

const Code = styled.div`
  white-space: pre;
  font-family: monospace;
  font-size: 10px;
`

const Stack = ({ title, text }) => (
  <div>
    <h3>{title}</h3>
    <Code>{text}</Code>
  </div>
)

const DefaultFallback = ({ error, onReset, componentStack }) => (
  <Warning>
    <Button onClick={() => window.location.reload()}>Reload Page</Button>

    <Button onClick={onReset}>Reload Component</Button>

    <h1>Error: {error?.message || 'Unknown Error'}</h1>

    {error?.stack && <Stack text={error.stack} title='Call Stack' />}

    {componentStack && <Stack text={componentStack} title='Component Stack' />}
  </Warning>
)

export default DefaultFallback
