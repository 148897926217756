import CenteredPage from '../Page/CenteredPage'

import Markdown from './Markdown'

const Page = (props) => (
  <CenteredPage>
    <Markdown {...props} />
  </CenteredPage>
)

Markdown.Page = Page

export { Page }

export default Markdown
