import { Link as RouterLink, useRouteMatch } from 'react-router-dom'
import { styled } from 'styled-components'

import { getName, parseError } from 'peach/helpers'
import { useLink } from 'peach/helpers/getLink'
import { useToggle } from 'peach/hooks'

import JsonDisplayValue from '../JsonDisplayValue'

const Link = styled(RouterLink)`
  text-decoration: none;
  color: ${(p) => p.theme.mutedText};

  &:hover {
    text-decoration: underline;
  }
`

const Span = styled.span`
  color: ${(p) => p.theme.disabledText};
`

const Chevron = styled.span`
  color: ${(p) => p.theme.disabledText};
`

const ErrorText = ({ error }) => {
  const [show, , , toggle] = useToggle()
  const msg = show ? `Error: "${parseError(error)}"` : 'Error'
  return <Span onDoubleClick={toggle}>{msg}</Span>
}

// const chevrons =  '› ›‹'

const ModelLink = ({ id, model, link, query, jsonKey }) => {
  const { isLoading, isError, error, isIdle } = query || {}

  const [showName, , , toggle] = useToggle(true)

  const displayId = (
    <JsonDisplayValue onDoubleClick={toggle} type='id'>
      {id}
    </JsonDisplayValue>
  )

  const modelLink = useLink(model)

  const $link = link || modelLink

  const match = useRouteMatch({ path: $link, exact: true })

  if (showName && !isIdle) {
    const name = isLoading ? (
      <Span>Loading…</Span>
    ) : isError ? (
      <ErrorText error={error} />
    ) : (
      <Span>{getName(model)}</Span>
    )

    const isLink = $link && !match

    return (
      <>
        {displayId}
        <Chevron>{' › '}</Chevron>
        {isLink ? <Link to={$link}>{name}</Link> : <Span>{name}</Span>}
        <Chevron />
      </>
    )
  }

  return displayId
}

export default ModelLink
