import { Input, Textarea, Select, Form } from 'peach/components'

import CaseTypeIdSelect from '../case/CaseTypeSelect'

const UpdateCaseForm = () => (
  <>
    <CaseTypeIdSelect disabled formKey='typeId' />

    <Input formKey='externalId' />

    <Select boolean formKey='autoCreated' />

    <Select
      formKey='status'
      nullable
      options={['initiated', 'processing', 'completed', 'canceled', 'reopened']}
    />

    <Textarea.Json formKey='metaData' />

    <Form.If if={{ caseType: '' }} />
  </>
)

export default UpdateCaseForm
