import {
  createContext,
  useState,
  useCallback,
  useContext,
  useMemo,
  useEffect,
} from 'react'

import { styled } from 'styled-components'

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${(p) => p.theme.offset};
  padding: 4px;
  line-height: 1;
`

const Tab = styled.button`
  display: inline-block;
  margin-right: 6px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 2px 6px;
  line-height: 1;
  color: ${(p) => (p.isActive ? p.theme.highlight : p.theme.labelText)};
  font-size: 12px;
`

const Page = styled.div`
  height: 100%;
`

const CompactTabsContext = createContext({})

const CompactTabsContainer = ({ children, defaultPage }) => {
  const [activePage, setActivePage] = useState(defaultPage)
  const [pages, setPages] = useState([])

  const addPage = useCallback(({ page, isDefault }) => {
    setPages((pages) => _.uniq(pages.concat({ page, isDefault })))
  }, [])

  const removePage = useCallback((page) => {
    setPages((pages) => _.reject(pages, { page }))
  }, [])

  useEffect(() => {
    if (!activePage) {
      const theDefault = _.find(pages, 'isDefault') || _.first(pages)

      if (theDefault?.page) setActivePage(theDefault.page)
    }
  }, [activePage, pages])

  const value = useMemo(
    () => ({ activePage, setActivePage, pages, addPage, removePage }),
    [pages, addPage, removePage, activePage],
  )

  return (
    <CompactTabsContext.Provider value={value}>
      {children}
    </CompactTabsContext.Provider>
  )
}

const BarTab = ({ isActive, onClick, page }) => (
  <Tab isActive={isActive} onClick={onClick}>
    {page}
  </Tab>
)

const CompactTabs = () => {
  const { pages, activePage, setActivePage } = useContext(CompactTabsContext)
  const tabs = _.map(pages, ({ page }) => {
    const isActive = page === activePage
    const onClick = () => setActivePage(page)

    return (
      <BarTab key={page} isActive={isActive} onClick={onClick} page={page} />
    )
  })

  return <Tabs>{tabs}</Tabs>
}

const CompactTabsPage = ({ page, isDefault, children }) => {
  const { addPage, removePage, activePage } = useContext(CompactTabsContext)

  const isActive = page && activePage === page

  useEffect(() => {
    addPage({ page, isDefault })
    return () => removePage(page)
  }, [page, addPage, removePage, isDefault])

  return isActive ? <Page>{children}</Page> : null
}

CompactTabs.Container = CompactTabsContainer

CompactTabs.Page = CompactTabsPage

export { CompactTabs, CompactTabsContainer, CompactTabsPage }

export default CompactTabs
