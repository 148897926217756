import { useDataQuery, useQueryApi } from '../useQueryApi'

const useInteractionsQuery = (options) => {
  const [api, args] = useQueryApi(options)
  const { personId } = args

  return useDataQuery({
    queryKey: ['people', personId, 'interactions'],

    queryFn: async () =>
      api.person.interactions.get({ pathArgs: { personId } }),
  })
}

export default useInteractionsQuery
