import { forwardRef } from 'react'

import { styled } from 'styled-components'

const Controls = styled.div`
  padding: 8px;
`

const SearchWrapper = styled.div`
  position: relative;
`

const SearchInput = styled.input`
  display: block;
  border: none;
  border: 1px solid ${(p) => p.theme.border};
  border-radius: 4px;
  background-color: transparent;
  background-color: ${(p) => p.theme.background};
  cursor: text;
  padding: 8px 12px;
  padding-right: 36px;
  width: 100%;
  color: ${(p) => p.theme.highlight};
  color: ${(p) => p.theme.text};
  font-size: 14px;

  &:disabled {
    color: ${(p) => p.theme.mutedText};
  }
`
const ClearButton = styled.button`
  position: absolute;
  top: 50%;
  right: 8px;
  transition: none;
  margin-top: -10px;
  border: 1px solid ${(p) => p.theme.border};
  border-radius: 4px;
  background-color: ${(p) => p.theme.banner};
  cursor: pointer;
  width: 21px;
  height: 21px;
  vertical-align: middle;
  text-align: center;
  color: ${(p) => p.theme.mutedText};
  font-size: 14px;

  &:disabled {
    border-color: ${(p) => p.theme.banner};
    background-color: ${(p) => p.theme.offset};
    color: ${(p) => p.theme.disabledText};
  }

  &:not(:disabled):hover {
    background-color: ${(p) => p.theme.hover};
  }
`

const X = styled.span`
  position: relative;
  top: -1px;
  right: 0;
`

const SelectTopSearch = forwardRef((props, ref) => {
  const { value, onChange } = props

  return (
    <Controls>
      <SearchWrapper>
        <SearchInput
          ref={ref}
          value={value || ''}
          placeholder='Search…'
          onChange={(event) => onChange(event.target.value)}
        />

        <ClearButton onClick={() => onChange('')} disabled={!value}>
          <X>✗</X>
        </ClearButton>
      </SearchWrapper>
    </Controls>
  )
})

SelectTopSearch.displayName = 'SelectTopSearch'

export default SelectTopSearch
