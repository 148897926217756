import { styled } from 'styled-components'

const Message = styled.div`
  padding: 8px;
  font-size: 12px;
  font-weight: bold;
`

const Content = styled.div`
  padding: 16px;
  text-align: center;
  color: ${(p) => p.theme.text};
`

const ActionWrapper = styled.div`
  padding: 8px;
  text-align: center;
`
const Action = styled.button`
  border: none;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  padding: 4px 12px;
  color: ${(p) => p.theme.labelText};

  &:hover {
    text-decoration: underline;
  }
`

const SearchResultsMessage = ({ message, onAction, actionLabel }) => (
  <Message>
    <Content>{message}</Content>
    {actionLabel && (
      <ActionWrapper>
        <Action onClick={onAction}>{actionLabel}</Action>
      </ActionWrapper>
    )}
  </Message>
)

export default SearchResultsMessage
