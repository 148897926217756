import { useDataQuery, useQueryApi } from '../useQueryApi'

const useTemplateDescriptorsQuery = (options) => {
  const [api, { companyId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['companies', companyId, 'templateDescriptors'],

    queryFn: () =>
      api.communicator.templateDescriptors.get({
        queryParams: { companyId },
      }),
  })
}

export default useTemplateDescriptorsQuery
