import { LoadingContainer, Tabs, Tab, Page } from 'peach/components'
import { useApi, useReadData } from 'peach/hooks'
import { Switch, Route, Redirect, useParams, useRouteMatch } from 'peach/router'

import FancyLedgerTable from './FancyLedgerTable'
import LedgerFilters from './LedgerFilters'
import LedgerTable from './LedgerTable'
import LoanRawLedger from './LoanRawLedger'

const Ledger = () => {
  const api = useApi()
  const { path, url } = useRouteMatch()
  const { companyId, personId, loanId } = useParams()

  const [ledger, loading, error] = useReadData(async () =>
    api.companyPerson.ledger.get({
      pathArgs: { companyId, personId, loanId },
    }),
  )

  const tabs = (
    <Tabs basePath={url}>
      <Tab exact to=''>
        Ledger
      </Tab>
      <Tab to='/fancy-table'>Fancy Table</Tab>
      <Tab to='/raw'>Raw</Tab>
    </Tabs>
  )

  return (
    <LoadingContainer error={error} loading={loading}>
      <LedgerFilters
        ledger={ledger}
        render={({ ledger }) => (
          <Page.Full>
            <Switch>
              <Route exact path={`${path}`}>
                <LedgerTable ledger={ledger} />
              </Route>

              <Route path={`${path}/fancy-table`}>
                <FancyLedgerTable ledger={ledger} />
              </Route>

              <Route path={`${path}/raw`}>
                <LoanRawLedger ledger={ledger} />
              </Route>

              <Redirect to={`${url}`} />
            </Switch>
          </Page.Full>
        )}
        right={tabs}
      />
    </LoadingContainer>
  )
}

export default Ledger
