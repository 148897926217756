import { useEffect } from 'react'

import { useQuery } from 'react-query'

import { usePrevious } from 'peach/hooks'

import { useAuthState } from 'core/auth/AuthProvider'
import { useEnvApi } from 'core/env/useEnv'

const defaultConfig = {
  refetchOnMount: true,
  retry: 1,
}

const getIsEnabled = (config) => {
  const { forceEnabled, queryKey, enabled, disabled } = config || {}

  if (_.has(config, 'forceEnabled')) {
    return forceEnabled
  }

  if (_.some(queryKey, (key) => !key)) {
    return false
  }

  if (_.has(config, 'disabled')) {
    return !disabled
  }

  if (_.has(config, 'enabled')) {
    return enabled
  }
}

const useDataQuery = (options) => {
  const { activeKey } = useEnvApi()

  const { queryKey, queryFn, ...config } = options

  const { isRenewing, isLoggingIn, requiresReauth } = useAuthState()

  const enabled = !requiresReauth && getIsEnabled(options)

  const queryInfo = useQuery({
    queryKey: [activeKey, ...queryKey],
    queryFn: queryFn,
    ...defaultConfig,
    ...config,
    enabled,
  })

  const { data: queryData, error, refetch } = queryInfo

  const errorStatus = error?.status

  const isAuthing = isRenewing || isLoggingIn

  const previousIsAuthing = usePrevious(isAuthing)

  useEffect(() => {
    if (previousIsAuthing && !isAuthing) {
      if (errorStatus === 401) refetch()
    }
  }, [previousIsAuthing, isAuthing, errorStatus, refetch])

  const respData = queryData?.data || queryData

  const resp = [respData, queryInfo]

  Object.assign(resp, queryInfo)

  return resp
}

export default useDataQuery
