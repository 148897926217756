import { useState, useRef, useEffect, useCallback } from 'react'

import { Popover } from '@reach/popover'
import _ from 'lodash'
import { styled } from 'styled-components'

import { Input } from 'peach/components'
import KeyboardNavArea from 'peach/components/$internal/KeyboardNavArea'
import useOnClickOutside from 'peach/components/Modal/useClickOutside'
import { positionMatchWidthMaxHeight } from 'peach/components/Select/positions'
import {
  hasFocusInside,
  focusFirstFocusableItem,
  focusNextFocusableElement,
  getFocusableElements,
} from 'peach/helpers/focus'
import { useToggle, useOnChangeEffect } from 'peach/hooks'
import { useLocation } from 'peach/router'
import { useLastId } from 'peach/scopes/company/LastCompanyIdProvider'

import SearchResults from './SearchResults'
import SearchResultsBox from './SearchResultsBox'
import SearchResultsMessage from './SearchResultsMessage'
import useAppSearchResults from './useAppSearchResults'

const Search = styled.span`
  display: inline-block;
`

const AppSearch = () => {
  const lastCompanyId = useLastId('companyId')
  const [query, setQuery] = useState('')
  const inputRef = useRef()
  const contentRef = useRef()

  const [isActivated, , onHide, toggleActive] = useToggle(false)

  const isActive = !!(query || isActivated)

  const handleClear = useCallback(() => {
    setQuery('')
    inputRef.current.focus()
  }, [])

  const onDone = useCallback(() => {
    _.delay(() => {
      setQuery('')
      onHide()
    }, 100)
  }, [onHide])

  useOnChangeEffect([isActive], ([prevIsActive]) => {
    // on activate
    if (isActive && prevIsActive === false) {
      focusFirstFocusableItem(inputRef.current)
    }
    // on deactivate
    if (!isActive && prevIsActive) {
      setQuery('')
    }
  })

  const handleInputBlur = useCallback(() => {
    _.defer(() => {
      if (!hasFocusInside(contentRef, inputRef)) onDone()
    })
  }, [onDone])

  useEffect(() => {
    const onCommandP = (event) => {
      if (event.key === 'p' && event.metaKey) {
        event.preventDefault()
        toggleActive()
      }
    }
    document.addEventListener('keydown', onCommandP)

    return () => document.removeEventListener('keydown', onCommandP)
  }, [toggleActive])

  const location = useLocation()

  const key = location.key

  useOnChangeEffect([key], ([prevKey]) => {
    if (isActive && prevKey && key) onDone()
  })

  useOnClickOutside(onDone, contentRef, inputRef)

  const [results, { isFetching }] = useAppSearchResults({
    query,
    companyId: lastCompanyId,
  })

  const totalNum = _.size(results)

  const onArrowDown = () => {
    focusNextFocusableElement(contentRef)
  }

  const onArrowUp = () => {
    focusNextFocusableElement(contentRef, { increment: -1 })
  }

  const handleInputEnter = () => {
    const first = _.first(getFocusableElements(contentRef))
    if (first) first.click()
  }

  const input = (
    <Input
      bare
      isFocused={isActive}
      onArrowDown={onArrowDown}
      onArrowUp={onArrowUp}
      onBlur={handleInputBlur}
      onChange={setQuery}
      onEnter={handleInputEnter}
      onEscape={onDone}
      placeholder='Search (⌘P)'
      ref={inputRef}
      value={query}
      width='320px'
    />
  )

  const popover = isActive && (
    <Popover
      position={positionMatchWidthMaxHeight}
      ref={contentRef}
      targetRef={inputRef}
    >
      <SearchResultsBox key='results' ref={contentRef}>
        <KeyboardNavArea
          onArrowDown={onArrowDown}
          onArrowUp={onArrowUp}
          onEscape={onDone}
          onTypeCharacter={() => inputRef.current.focus()}
        >
          {isFetching ? (
            <SearchResultsMessage
              actionLabel='Clear query'
              message='Loading…'
              onAction={handleClear}
            />
          ) : totalNum ? (
            <SearchResults onClick={onDone} results={results} />
          ) : (
            <SearchResultsMessage
              actionLabel='Clear query'
              message='No Results'
              onAction={handleClear}
            />
          )}
        </KeyboardNavArea>
      </SearchResultsBox>
    </Popover>
  )

  return (
    <Search>
      {input}
      {popover}
    </Search>
  )
}

export default AppSearch
