import { useDataQuery, useQueryApi } from '../useQueryApi'

const useUserQuery = (options) => {
  const [api, { companyId, userId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['companies', companyId, 'users', userId],

    queryFn: () =>
      api.company.user.get({
        pathArgs: { companyId, userId },
      }),

    enabled: !!(companyId && userId),
  })
}

export default useUserQuery
