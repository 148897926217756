import { FC } from 'react'

import { FormProvider, useForm } from 'react-hook-form'
import * as R from 'remeda'

import { LoadingContainer } from 'peach/components'
import { StyledButton as Button } from 'peach/components/Button/Button'
import Input from 'peach/components/Input'
import { useApi, useWrite } from 'peach/hooks'

import { Error as ErrorComponent, FormActions, Title } from './Components'
import { Company } from './types'

type Domain = {
  type: 'borrower' | 'agent' | 'admin'
  domain: string
}

type CreateAppDomainsFormValues = {
  domains: Array<Domain>
}

type CreateAppDomainsFormProps = {
  company: Company
  updateCompany: (obj: Company) => void
  title: string
}

const CreateAppDomainsForm: FC<CreateAppDomainsFormProps> = ({
  company,
  updateCompany,
  title,
}) => {
  const api: any = useApi()

  const form = useForm<CreateAppDomainsFormValues>({
    values: {
      domains: [
        {
          type: 'borrower',
          domain: '',
        },
        {
          type: 'agent',
          domain: '',
        },
        {
          type: 'admin',
          domain: '',
        },
      ],
    },
  })

  const { domains } = form.getValues()

  const [onCreate, isPending, , error, clear] = useWrite(async (values) => {
    const responses = await Promise.all(
      R.map(values.domains as Array<Domain>, (d) =>
        api.company.appDomains.post({
          body: {
            type: d.type,
            domain: d.domain,
          },
          pathArgs: { companyId: company?.companyId },
        }),
      ),
    )

    if (responses.every((response) => response.status === 201)) {
      updateCompany({
        ...company,
        appDomains: [
          ...(company?.appDomains ?? []),
          ...(R.map(responses, (response) => ({
            id: response.data.id,
            type: response.data.type,
            domain: response.data.domain,
          })) ?? []),
        ],
      })
    }
  })

  return (
    <LoadingContainer loading={isPending}>
      <Title>{title}</Title>
      <form
        onSubmit={form.handleSubmit(async (values) => {
          try {
            clear()
            await onCreate(values)
          } catch (e) {
            console.error('Error with create actions: ', e)
          }
        })}
      >
        <FormProvider {...form}>
          {R.map.indexed(domains, (domain, index) => (
            <Input
              key={domain.type}
              label={`${domain.type} domain`}
              placeholder={
                domain.type === 'borrower'
                  ? 'lender-borrower.peach-sandbox.com'
                  : domain.type === 'agent'
                  ? 'lender-agent.peach-sandbox.com'
                  : 'lender-admin.peach-sandbox.com'
              }
              required
              type='text'
              {...form.register(`domains.${index}.domain`)}
            />
          ))}

          {error && <ErrorComponent>{error.message}</ErrorComponent>}
          <FormActions>
            <Button primary type='submit'>
              Submit
            </Button>
          </FormActions>
        </FormProvider>
      </form>
    </LoadingContainer>
  )
}

export default CreateAppDomainsForm
