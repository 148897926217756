import { forwardRef } from 'react'

import { withLabel, withFormValue } from 'peach/decorators'

import { RawRadioSelect } from '../Radio/RadioSelect'

const BooleanRadioSelect = forwardRef((props, ref) => {
  const {
    onChange,
    value,
    trueLabel,
    falseLabel,
    nullLabel,
    labels = [],
    nullable,
  } = props

  const $value =
    value === true
      ? 'true'
      : value === false
      ? 'false'
      : value === null
      ? 'null'
      : ''

  const options = _.compact([
    {
      label: trueLabel || labels[0] || 'True',
      value: 'true',
    },
    {
      label: falseLabel || labels[1] || 'False',
      value: 'false',
    },
    nullable && {
      label: nullLabel || labels[1] || 'Null',
      value: 'null',
    },
  ])

  const handleChange = (value) => {
    onChange(value === 'true' ? true : value === 'false' ? false : null)
  }

  return (
    <RawRadioSelect
      ref={ref}
      options={options}
      value={$value}
      onChange={handleChange}
    />
  )
})

BooleanRadioSelect.displayName = 'BooleanRadioSelect'

export default withFormValue(withLabel(BooleanRadioSelect))
