import {
  useState,
  useEffect,
  useMemo,
  useCallback,
  createContext,
  useContext,
} from 'react'

const CrumbsContext = createContext({
  crumbs: [],
  setCrumb: () => {},
  removeCrumb: () => {},
})

const CrumbProvider = ({ children }) => {
  const [crumbs, setCrumbs] = useState({})

  const setCrumb = useCallback((crumb) => {
    setCrumbs((crumbs) => ({ ...crumbs, [crumb.id]: crumb }))
  }, [])

  const removeCrumb = useCallback((id) => {
    setCrumbs((crumbs) => _.pickBy(crumbs, (crumb) => crumb.id !== id))
  }, [])

  const $crumbs = _.sortBy(_.values(crumbs), (crumb) => crumb.to.length)

  const value = useMemo(
    () => ({ crumbs: $crumbs, setCrumb, removeCrumb }),
    [$crumbs, setCrumb, removeCrumb],
  )

  return (
    <CrumbsContext.Provider value={value}>{children}</CrumbsContext.Provider>
  )
}

const useCrumbs = () => useContext(CrumbsContext)

const Crumb = ({ to, title, category, hidden, children }) => {
  const [id] = useState(() => _.uniqueId('crumb-'))

  const { setCrumb, removeCrumb } = useContext(CrumbsContext)

  useEffect(() => {
    setCrumb({ id, to, title, category })
    return () => removeCrumb(id)
  }, [id, to, title, setCrumb, removeCrumb, category])

  return children ?? null
}

export { Crumb, CrumbProvider, useCrumbs }
