import { useDataQuery, useQueryApi } from '../useQueryApi'

const useDrawBalancesQuery = (options) => {
  const [api, params] = useQueryApi(options)

  const { personId, loanId, drawId } = params

  return useDataQuery({
    queryKey: [
      'people',
      personId,
      'loans',
      loanId,
      'draws',
      drawId,
      'balances',
    ],

    queryFn: () =>
      api.draw.balances.get({
        pathArgs: { personId, loanId, drawId },
      }),

    enabled: !!(personId && loanId && drawId),
  })
}

export default useDrawBalancesQuery
