// this file is deprecated.
// move new functions into peach/helpers/*.js
// err on the side of descriptive names.
// once all usages of this file have been converted
// it will be removed

const isPeachIdValue = (val = '') =>
  _.size(val) === 12 && val[2] === '-' && val[7] === '-'

const isSimple = (obj) =>
  _.isObject(obj) && _.every(obj, (item) => !_.isObject(item))

const dateRegex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/

const isDateValue = (value) => {
  if (_.isFinite(value)) return true
  if (_.isString(value)) {
    if (value.slice(0, 10).match(dateRegex)) return true
  }
  return false
}

const getTypeFromValue = (value) =>
  _.isArray(value)
    ? _.isEmpty(value)
      ? 'emptyArray'
      : 'array'
    : _.isPlainObject(value)
    ? _.isEmpty(value)
      ? 'emptyObject'
      : 'object'
    : _.isBoolean(value)
    ? 'boolean'
    : _.isString(value)
    ? isPeachIdValue(value)
      ? 'id'
      : isDateValue(value)
      ? 'date'
      : _.isEmpty(value.trim())
      ? 'emptyString'
      : 'string'
    : _.isNumber(value)
    ? 'number'
    : _.isNull(value)
    ? 'null'
    : _.isUndefined(value)
    ? 'undefined'
    : 'unknown'

const getTitle = (json) => {
  if (!json) return 'Unknown Object'
  const { name, description, nickname, id } = json
  return _.filter(
    [name, description || nickname, id],
    (str) => _.isString(str) && str,
  ).join(' / ')
}

const isIdKey = (str = '') => {
  if (_.isString(str)) {
    return str === 'id' || str.slice(-2) === 'Id' || str.slice(-3) === '_id'
  } else {
    return false
  }
}

const dateKeys = ['date', 'timestamp', 'dateOfBirth', 'dateEffective']

const isDateKey = (str = '', value) =>
  _.isString(str) &&
  (str.slice(-2) === 'At' ||
    str.slice(-3) === '_at' ||
    str.slice(-4) === 'Date' ||
    str.slice(-5) === '_date' ||
    dateKeys.includes(str))

const amountKeys = ['amount', 'creditLimit']

const isAmountKey = (str = '') =>
  _.isString(str) &&
  (str.slice(-6) === 'Amount' ||
    str.slice(-7) === '_amount' ||
    amountKeys.includes(str))

const getTypeFromKey = (key = '', value) =>
  isIdKey(key)
    ? 'id'
    : isDateKey(key) && isDateValue(value)
    ? 'date'
    : isAmountKey(key)
    ? 'amount'
    : undefined

const baseKeys = ['status', 'type']

const sortEntries = (list = []) =>
  _.sortBy(list, (item = []) => {
    const [key, value] = item

    if (_.isObject(value)) {
      if (isSimple(value)) {
        return _.isArray(value) ? 100 : 110
      }
      return _.isArray(value) ? 130 : 120
    }

    return key === 'id'
      ? 1
      : isIdKey(key)
      ? 20
      : baseKeys.includes(key)
      ? 30
      : isAmountKey(key)
      ? 40
      : _.isBoolean(value)
      ? 50
      : key === 'updatedAt'
      ? 90
      : key === 'createdAt'
      ? 91
      : 50
  })

const sortObject = (obj) => _.fromPairs(sortEntries(_.toPairs(obj)))

export {
  getTypeFromValue,
  getTypeFromKey,
  isIdKey,
  isPeachIdValue,
  isDateKey,
  isDateValue,
  getTitle,
  sortEntries,
  sortObject,
}
