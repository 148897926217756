import {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react'

import styled, { css } from 'styled-components'

import Bar from '../Bar/Bar'
import Panels from '../Panels/Panels'

const FormPage = styled.div`
  display: ${(p) => (p.visible ? 'block' : 'none')};
  padding: 16px;
  height: 100%;
`
const Tabs = styled.div`
  padding: 8px;
`

const Tab = styled.button`
  display: block;
  margin-top: 8px;
  border: none;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
  padding: 8px 12px;
  width: 100%;
  text-align: left;
  font-size: 14px;

  &:hover {
    ${(p) =>
      !p.isActive &&
      css`
        background-color: ${(p) => p.theme.hover};
      `}
  }
  ${(p) =>
    p.isActive &&
    css`
      background-color: ${(p) => p.theme.highlight};
      cursor: default;
      color: ${(p) => p.theme.invertedText};
    `}
`

const PagesContext = createContext({
  addPage: () => {},
  removePage: () => {},
  activePage: '',
})

const Page = ({ page, isDefault, children }) => {
  const { addPage, removePage, activePage } = useContext(PagesContext)

  const visible = page && activePage === page

  useEffect(() => {
    addPage({ page, isDefault })
    return () => removePage(page)
  }, [page, addPage, removePage, isDefault])
  return <FormPage visible={visible}>{children}</FormPage>
}

const FormTabs = ({ children }) => {
  const [activePage, setActivePage] = useState(undefined)

  const [pages, setPages] = useState([])

  const addPage = useCallback(({ page, isDefault }) => {
    setPages((pages) => _.uniq(pages.concat({ page, isDefault })))
  }, [])

  const removePage = useCallback((page) => {
    setPages((pages) => _.reject(pages, { page }))
  }, [])

  const value = useMemo(
    () => ({ activePage, addPage, removePage }),
    [activePage, addPage, removePage],
  )

  useEffect(() => {
    if (!activePage) {
      const theDefault = _.find(pages, 'isDefault') || _.first(pages)

      if (theDefault?.page) setActivePage(theDefault.page)
    }
  }, [activePage, pages])

  useEffect(() => {
    setActivePage(activePage)
  }, [activePage])

  const tabs = _.map(pages, ({ page }) => (
    <Tab
      key={page}
      isActive={activePage === page}
      onClick={() => setActivePage(page)}
    >
      {page}
    </Tab>
  ))

  const left = <Tabs>{tabs}</Tabs>

  const header = <Bar title={activePage || 'No Page Selected'} />

  return (
    <PagesContext.Provider value={value}>
      <Panels left={left}>
        <Panels header={header}>{children}</Panels>
      </Panels>
    </PagesContext.Provider>
  )
}
FormTabs.Page = Page

export { Page }

export default FormTabs
