import { useMemo } from 'react'

import { usePeachGlobal } from 'peach/hooks'

import apiConfig from './apiConfig'
import mapConfig from './mapConfig'

const usePeachApi = (executeRequest) => {
  const peachApi = useMemo(
    () => mapConfig(apiConfig, executeRequest),
    [executeRequest],
  )

  usePeachGlobal('api', peachApi)

  return [peachApi]
}

export default usePeachApi
