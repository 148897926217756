import { styled } from 'styled-components'

import withLabel from 'peach/decorators/withLabel/withLabel'

const Text = styled.div`
  border: 1px solid ${(p) => p.theme.border};
  border-radius: 4px;
  background-color: ${(p) => p.theme.offset};
  padding: 8px;
  line-height: 1.5;
  white-space: pre-wrap;
  word-break: break-all;
`

const FormText = (props) => {
  const { children } = props

  return <Text>{children}</Text>
}

const $FormText = withLabel(FormText)

export default $FormText
