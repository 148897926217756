import { Fragment } from 'react'

import _ from 'lodash'
import { styled } from 'styled-components'

import {
  Json,
  Panels,
  MiniTable,
  Link,
  Modal,
  Bar,
  Page,
  Input,
  Breadcrumb,
  Select,
} from 'peach/components'
import { useCompanyRolesQuery } from 'peach/data'
import { getName } from 'peach/helpers'
import { useApi, useReadData } from 'peach/hooks'
import { Switch, Route, Redirect, useRouteMatch, useParams } from 'peach/router'
import { CompanyPermissionSelect } from 'peach/scopes/company'

import CompanyPermission from './CompanyPermission' // todo: rename to CompanyRolePermission

const LightText = styled.span`
  color: ${(p) => p.theme.mutedText};
`

const CompanyRole = () => {
  const { url, path } = useRouteMatch()
  const { companyId, roleId } = useParams()
  const [roles, query] = useCompanyRolesQuery()
  const [companyPermissions] = useReadData(() => {
    if (!companyId) return []
    return api.company.permissions.get({ pathArgs: { companyId } })
  }, [companyId])

  const api = useApi()

  const role = _.find(roles, { id: roleId })

  const { permissions, ...rest } = role || {}

  const sendEditRole = async (_role, diff) => {
    await api.company.role.put({
      pathArgs: { companyId, roleId },
      body: diff,
    })
    query.refetch()
  }

  const sendAddPermission = async ({ actions }) => {
    await api.company.rolePermissions.post({
      pathArgs: { companyId, roleId },
      body: { actions },
    })
    query.refetch()
  }

  const sendRemovePermissions = async ({ actions }) => {
    await api.company.rolePermissions.delete({
      pathArgs: { companyId, roleId },
      queryParams: { actions },
    })
    query.refetch()
  }

  const editRole = (
    <Modal initialValue={role} onSubmit={sendEditRole} title='Edit Role'>
      <Input formKey='alias' />
    </Modal>
  )

  const addPermissions = (
    <Modal onSubmit={sendAddPermission} title='Add Permissions'>
      <CompanyPermissionSelect
        formKey='actions'
        multi
        omitPermissions={permissions}
      />
    </Modal>
  )

  const removePermissions = (
    <Modal onSubmit={sendRemovePermissions} title='Remove Permissions'>
      <Select
        formKey='actions'
        multi
        options={_.map(permissions, 'action')}
        searchable
      />
    </Modal>
  )

  const permissionsIndex = _.keyBy(permissions, 'action')

  const permissionsList = _.map(companyPermissions, ({ id, action }) => {
    const [group, verb] = action?.split?.(':') || []
    return { id, action, rolePermission: permissionsIndex[action], group, verb }
  })

  const groupedPerms = _.groupBy(permissionsList, 'group')

  const rows = _.map(groupedPerms, (perms, type) => {
    const sortedPerms = _.sortBy(perms, 'verb')

    const actions = _.map(sortedPerms, (perm, index) => {
      const { verb, rolePermission } = perm
      const { id } = rolePermission || {}

      return (
        <Fragment key={perm.id}>
          {id ? (
            <Link key={id} to={`${url}/permissions/${id}`}>
              {verb}
            </Link>
          ) : (
            <LightText>{verb}</LightText>
          )}
          {index < perms.length - 1 ? <LightText>{', '}</LightText> : null}
        </Fragment>
      )
    })

    return (
      <MiniTable.Row key={type}>
        <MiniTable.Cell width='300px'>
          <Json.Value value={type} />
        </MiniTable.Cell>

        <MiniTable.Cell>
          <div style={{ lineHeight: 1.75 }}>{actions}</div>
        </MiniTable.Cell>
      </MiniTable.Row>
    )
  })

  const name = getName(role)

  const header = <Bar title={name} />

  return (
    <>
      {editRole}
      {addPermissions}
      {removePermissions}

      <Breadcrumb title={name} to={url} />
      <Switch>
        <Route exact path={path}>
          <Panels header={header}>
            <Page.Full>
              <Json.Table json={rest} title='Role' />
              <MiniTable title='Permissions'>{rows}</MiniTable>
            </Page.Full>
          </Panels>
        </Route>
        <Route crumb path={`${path}/permissions`}>
          <Route exact path={`${path}/permissions`}>
            <Redirect to={url} />
          </Route>
          <Route path={`${path}/permissions/:permissionId`}>
            <CompanyPermission />
          </Route>
        </Route>
        <Redirect to={url} />
      </Switch>
    </>
  )
}

export default CompanyRole
