import { useState } from 'react'

import { styled } from 'styled-components'

import { humanizeKey } from 'peach/helpers'

import { sortEntries } from './jsonHelpers'
import JsonValue from './JsonValue'

const Table = styled.table`
  table-layout: auto;
  border-collapse: collapse;
`
const Tr = styled.tr``

const Index = styled.td`
  padding: ${(p) => (p.compact ? '2px' : '8px')};
  word-break: keep-all;
`

const IndexInner = styled.div`
  padding-right: 4px;
  width: 100%;
  text-align: right;
  color: ${(p) => p.theme.mutedText};
`

const Expander = styled.td`
  padding: ${(p) => (p.compact ? '2px' : '8px')};
`

const Value = styled.td`
  padding: ${(p) => (p.compact ? '2px' : '8px')};
`

const Wrapper = styled.div``

const Row = styled.div`
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`
const Key = styled.div`
  padding-bottom: 2px;
  padding-left: ${(p) => (p.compact ? '2px' : '8px')};
  color: ${(p) => p.theme.mutedText};
  font-size: 11px;
  font-weight: bold;
`

const ObjectValue = styled.div`
  padding: ${(p) => (p.compact ? '2px' : '8px')};
`

const Button = styled.button`
  margin: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: ${(p) => (p.compact ? '2px' : '8px')};
  color: ${(p) => p.theme.highlight};
`

const JsonList = (props) => {
  const { json, compact, humanizeKeys, limit = 16, stringLimit, object } = props

  const [isExpanded, setIsExpanded] = useState(false)

  if (_.isArray(json)) {
    const count = _.size(json)
    const exceedsLimit = count > limit + 1
    const showAll = !exceedsLimit || isExpanded

    const items = showAll ? json : _.take(json, limit)
    return (
      <Table>
        <tbody>
          {_.map(items, (value, i) => (
            <Tr key={i}>
              <Index compact={compact}>
                <IndexInner>{i}</IndexInner>
              </Index>
              <Value compact={compact}>
                <JsonValue value={value} stringLimit={stringLimit} />
              </Value>
            </Tr>
          ))}
          {exceedsLimit && (
            <Tr key='expander'>
              <Expander compact={compact}>
                <Button
                  compact={compact}
                  onClick={() => setIsExpanded((val) => !val)}
                >
                  {isExpanded ? 'Less…' : 'More…'}
                </Button>
              </Expander>
            </Tr>
          )}
        </tbody>
      </Table>
    )
  } else if (_.isPlainObject(json)) {
    const pairs = sortEntries(_.toPairs(json))
    const count = _.size(pairs)
    const exceedsLimit = count > limit + 1
    const showAll = !exceedsLimit || isExpanded

    const $pairs = showAll ? pairs : _.take(pairs, limit)

    return (
      <Wrapper>
        {_.map($pairs, ([key, value]) => (
          <Row key={key}>
            <Key compact={compact}>{humanizeKeys ? humanizeKey(key) : key}</Key>
            <ObjectValue compact={compact}>
              <JsonValue
                jsonKey={key}
                value={value}
                stringLimit={stringLimit}
                object={object}
              />
            </ObjectValue>
          </Row>
        ))}
        {exceedsLimit && (
          <Row>
            <Button
              compact={compact}
              onClick={() => setIsExpanded((val) => !val)}
            >
              {isExpanded ? 'Less…' : 'More…'}
            </Button>
          </Row>
        )}
      </Wrapper>
    )
  } else {
    return <JsonValue value={json} />
  }
}

export default JsonList
