import { Panels, Bar, Link, Tabs, Tab } from 'peach/components'
import { Switch, Route } from 'peach/router'

import ScratchContext from './ScratchContext'
import ScratchEmpty from './ScratchEmpty'
import ScratchError from './ScratchError'
import ScratchForm from './ScratchForm'
import ScratchOrderable from './ScratchOrderable'
import ScratchPopover from './ScratchPopover'
import ScratchRoute from './ScratchRoute'

const Scratch = () => {
  const header = (
    <Bar
      left={
        <>
          <Link to='/'>⬅️ Back</Link>
          <Tabs>
            <Tab exact to='/scratch'>
              Scratch
            </Tab>
            <Tab to='/scratch/form'>Form</Tab>
            <Tab to='/scratch/context'>Context</Tab>
            <Tab to='/scratch/orderable'>Orderable</Tab>
            <Tab to='/scratch/route'>Route</Tab>
            <Tab to='/scratch/error'>Error</Tab>
            <Tab to='/scratch/popover'>Popover</Tab>
          </Tabs>
        </>
      }
    />
  )
  return (
    <Panels header={header}>
      <Switch>
        <Route path='/scratch/form'>
          <ScratchForm />
        </Route>

        <Route path='/scratch/context'>
          <ScratchContext />
        </Route>

        <Route path='/scratch/orderable'>
          <ScratchOrderable />
        </Route>

        <Route path='/scratch/route/:subPath?'>
          <ScratchRoute />
        </Route>

        <Route path='/scratch/popover'>
          <ScratchPopover />
        </Route>

        <Route path='/scratch/error'>
          <ScratchError />
        </Route>

        <Route>
          <ScratchEmpty />
        </Route>
      </Switch>
    </Panels>
  )
}

export default Scratch
