import { styled } from 'styled-components'

const ImageSpan = styled.span`
  display: inline-block;
  border: 1px solid
    ${(p) => (p.border || !p.url ? p.theme.border : 'transparent')};
  background-image: ${(p) => (p.url ? `url(${p.url})` : '#967bb6')};
  background-position: ${(p) => p.backgroundPosition || 'center'};
  background-repeat: no-repeat;
  background-size: contain;
  width: ${(p) => (p.width ? `${p.width}px` : '0')};
  height: ${(p) => (p.height ? `${p.height}px` : '0')};
  line-height: 0;
`
const Image = ({ url, width, height, border, backgroundPosition }) => (
  <ImageSpan
    url={url}
    width={width}
    height={height}
    border={border}
    backgroundPosition={backgroundPosition}
  />
)
export default Image
