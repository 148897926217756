import { forwardRef } from 'react'

import { styled } from 'styled-components'

import LoadingContainer from '../Loading/LoadingContainer'

import ModalErrorBar from './ModalErrorBar'

const Contents = styled.div`
  background-color: ${(p) => p.theme.backgroun};
  padding: ${(p) => (p.noPadding ? '0' : '16px')};
  height: 100%;
  min-height: 40px;
  overflow-y: auto;
`

const ModalContents = forwardRef((props, ref) => {
  const { children, error, sending, onClearError, noPadding } = props
  return (
    <LoadingContainer loading={sending}>
      <Contents noPadding={noPadding}>{children}</Contents>

      {error && <ModalErrorBar error={error} onClear={onClearError} />}
    </LoadingContainer>
  )
})

ModalContents.displayName = 'ModalContents'

export default ModalContents
