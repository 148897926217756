import _ from 'lodash'

import { Page, Model } from 'peach/components'
import { useSessionCompaniesQuery, useAllSessionRoles } from 'peach/data'
import { getName } from 'peach/helpers'

const Companies = () => {
  const [companies] = useSessionCompaniesQuery()

  const [roles] = useAllSessionRoles()

  const formattedRoles = _.map(roles, (role) => {
    const { id, alias, roleType, companyId, permissions } = role
    const permissionsCount = _.size(permissions)

    const company = _.find(companies, { id: companyId })

    return {
      alias,
      id,
      companyId,
      roleType,
      companyName: getName(company),
      permissionsCount: permissionsCount,
    }
  })

  const groupedRoles = _.groupBy(formattedRoles, 'companyName')

  const note = (
    <>
      {'All roles across any company that the current user has the '}
      <b>role:list</b>
      {' permission to.'}
    </>
  )

  return (
    <Page.Full>
      <Model.Item json={groupedRoles} note={note} title='All Roles' />
    </Page.Full>
  )
}

export default Companies
