import { Circles } from 'react-loader-spinner'
import { useTheme } from 'styled-components'

const SIZE = 80

const Spinner = ({ size }) => {
  const theme = useTheme()

  const color = theme.mutedText

  return (
    <Circles
      color={color}
      height={size || SIZE}
      width={size || SIZE}
      ariaLabel='circles-loading'
      timeout={3000}
    />
  )
}

export default Spinner
