import _ from 'lodash'

import {
  LoadingContainer,
  Panels,
  Bar,
  Modal,
  Page,
  ModelList,
} from 'peach/components'
import { useLoansQuery } from 'peach/data'
import { humanizeKey } from 'peach/helpers'
import { useApi } from 'peach/hooks'

import {
  CreateInstallmentLoanForm,
  CreateLineOfCreditLoanForm,
} from './CreateLoanForm/CreateLoanForm'
import {
  defaultInstallmentLoan,
  defaultLineOfCreditLoan,
} from './CreateLoanForm/defaultLoan'

const PersonLoans = ({ personId }) => {
  const [loans, loansQuery] = useLoansQuery()

  const displayLoans = _.sortBy(loans, 'createdAt').reverse()

  const api = useApi()

  const refresh = () => loansQuery.refetch()

  const send = async (loan) => {
    try {
      await api.person.loans.post({
        pathArgs: { personId },
        queryParams: { validate: false },
        body: loan,
      })
      refresh()
    } catch (err) {
      refresh()
      throw err
    }
  }

  const createInstallmentLoan = (
    <Modal
      initialValue={defaultInstallmentLoan}
      onSubmit={send}
      render={({ onChange }) => (
        <CreateInstallmentLoanForm onChange={onChange} />
      )}
      tabs
      title='Create Installment Loan'
    />
  )

  const createLineOfCreditLoan = (
    <Modal
      initialValue={defaultLineOfCreditLoan}
      onSubmit={send}
      tabs
      title='Create Line Of Credit Loan'
    >
      <CreateLineOfCreditLoanForm />
    </Modal>
  )

  const groupedLoans = _.groupBy(displayLoans, 'type')

  const tables = _.map(groupedLoans, (group, key) => (
    <ModelList
      columns={[['nickname', 'object']]}
      json={group}
      key={key}
      title={`${humanizeKey(key)} Loans`}
      withDetails={[
        'loanTypeId',
        'type',
        'aprEffectiveAtActivation',
        'apiNominalAtActivation',
        'endDate',
        'productType',
        'servicedBy',
      ]}
      withTimestamps
    />
  ))

  return (
    <Panels header={<Bar title='All Loans' />}>
      {createInstallmentLoan}
      {createLineOfCreditLoan}
      <LoadingContainer query={loansQuery}>
        <Page.Full>{tables}</Page.Full>
      </LoadingContainer>
    </Panels>
  )
}

export default PersonLoans
