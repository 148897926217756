import {
  LoadingContainer,
  Panels,
  Bar,
  Page,
  Modal,
  Model,
  Breadcrumb,
} from 'peach/components'
import { usePersonCaseQuery } from 'peach/data'
import { getName } from 'peach/helpers'
import { useApi } from 'peach/hooks'
import { useParams } from 'peach/router'

import UpdateCaseForm from './UpdateCaseForm'

const PersonCase = () => {
  const api = useApi()

  const { personId, caseId } = useParams()

  const [$case, query] = usePersonCaseQuery()

  const sendUpdateCase = async (cayce, diff) => {
    await api.person.case.put({
      pathArgs: { personId, caseId },
      body: diff,
    })

    console.info({ case: cayce, diff })
    query.refetch()
  }

  const udpateCase = (
    <Modal initialValue={$case} onSubmit={sendUpdateCase} title='Update Case'>
      <UpdateCaseForm />
    </Modal>
  )

  const title = query.isLoading ? 'Loading...' : getName($case)

  const header = <Bar title={title} />

  return (
    <Panels header={header}>
      {udpateCase}
      <Breadcrumb title={title} />
      <LoadingContainer query={query}>
        <Page.Full>
          <Model.Item json={$case} />
        </Page.Full>
      </LoadingContainer>
    </Panels>
  )
}

export default PersonCase
