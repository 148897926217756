import { styled } from 'styled-components'

import { withLabel, withFormValue } from 'peach/decorators'
import { humanizeKey } from 'peach/helpers'

import JsonTextarea from './JsonTextarea'
import RawTextarea from './RawTextarea'

const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
`

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 4px;
`

const Action = styled.button`
  margin-left: 8px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: ${(p) => p.theme.highlight};
  font-size: 12px;

  &:not(:disabled):hover {
    text-decoration: underline;
  }

  &:disabled {
    color: ${(p) => p.theme.mutedText};
  }
`

const Textarea = (props) => {
  const {
    formKey,
    onChange,
    width,
    value,
    onEnter,
    onKeyPress,
    placeholder,
    type,
    minRows,
    nullable,
    disabled,
    ...rest
  } = props

  const handleKeyPress = (event) => {
    if (onEnter && event.key === 'Enter') onEnter(event)
    if (onKeyPress) onKeyPress(event)
  }

  const displayPlaceholder = placeholder || humanizeKey(formKey)

  return (
    <Wrapper>
      <RawTextarea
        {...rest}
        width={width || '100%'}
        minRows={minRows ?? 5}
        onChange={onChange}
        value={value || ''}
        placeholder={displayPlaceholder}
        onKeyPress={handleKeyPress}
        disabled={disabled || !onChange}
      />
      {nullable && (
        <Actions>
          <Action disabled={value === null} onClick={() => onChange(null)}>
            Clear
          </Action>
        </Actions>
      )}
    </Wrapper>
  )
}

const $Textarea = withFormValue(withLabel(Textarea))

$Textarea.Json = JsonTextarea

export default $Textarea
