import { useReadData } from 'peach/hooks'

const useSessionPersonManagement = (personId, executeRequest) => {
  const [person, loading, error, refresh] = useReadData(
    async () =>
      executeRequest({
        method: 'GET',
        pathPattern: '/people/{personId}',
        pathArgs: { personId },
      }),
    [personId],
  )

  return [person, loading, error, refresh]
}
export default useSessionPersonManagement
