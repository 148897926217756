import { styled } from 'styled-components'

import { useSessionUserQuery } from 'peach/data'

import { useAuthState } from 'core/auth/AuthProvider'
import { useApiBase } from 'core/env/useEnv'

const UserInfo = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`
const Info = styled.div`
  display: inline-block;
  text-align: right;
`
const Name = styled.div`
  color: ${(p) => p.theme.text};
`
const ApiBase = styled.div`
  padding-top: 4px;
  color: #888;
  font-size: 10px;
`

const AppUserInfo = () => {
  const { decodedToken } = useAuthState()
  const { sub: userId } = decodedToken || {}
  const [user, query] = useSessionUserQuery()

  const { username } = user || {}

  const apiBase = useApiBase()

  return (
    <UserInfo>
      <Info>
        <Name>{query?.isLoading ? 'Loading…' : username || userId}</Name>
        <ApiBase>{apiBase}</ApiBase>
      </Info>
    </UserInfo>
  )
}

export default AppUserInfo
