import _ from 'lodash'

import { useSessionCompaniesWithPermissions } from 'peach/data'
import { searchObjects, getName, getLink, combineQueries } from 'peach/helpers'

import useBorrowerAppSearch from './pages/useBorrowerAppSearch'
import useEmployeePages from './pages/useEmployeePages'
import useUsersPages from './pages/useUsersPages'
import usePagesSearch from './usePagesSearch'

const useAppSearchResults = ({ query, companyId }) => {
  const [companies] = useSessionCompaniesWithPermissions('company:read')

  const companyOptions = _.map(companies, (company) => ({
    group: 'Companies',
    label: getName(company),
    to: getLink(company),
    subtitle: 'Company',
  }))

  const lastCompany = _.find(companies, { id: companyId })

  const resultCompanies = searchObjects(companyOptions, query, {
    max: 5,
    keys: ['label', 'subtitle'],
  })

  const [people, peopleQuery] = useBorrowerAppSearch({
    query,
    companyId: companyId,
  })

  const pages = usePagesSearch(query, { companyId, company: lastCompany })

  const [employees, emplpoyeesQuery] = useEmployeePages({ query, companyId })

  const [users, usersQuery] = useUsersPages({ query, companyId })

  const searchQuery = combineQueries(peopleQuery, emplpoyeesQuery, usersQuery)

  const fullResults = [people, employees, users, pages, resultCompanies]

  const searchResults = _.compact(
    _.flatten(_.map(fullResults, (list) => _.take(list, 5))),
  )

  return [searchResults, searchQuery]
}

export default useAppSearchResults
