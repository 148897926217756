import { FC } from 'react'

import { styled } from 'styled-components'

import { StyledButton as Button } from 'peach/components/Button/Button'
import { useHistory } from 'peach/router'

import { Title } from './Components'
import { Company } from './types'

type CompleteProps = {
  company: Company
  updateCompany: (obj: Company) => void
  title: string
}

const Complete: FC<CompleteProps> = ({ company, updateCompany, title }) => {
  const history = useHistory()

  return (
    <div>
      <Title>{title}</Title>
      <Container>
        <div>
          Successfully created company: <b>{company?.name}</b>
        </div>
        <StyledButton
          onClick={() => {
            updateCompany({ ...company, completed: true })
            setTimeout(
              () => history.push(`/companies/${company?.companyId}`),
              100,
            )
          }}
          primary
        >
          Complete & view company &gt;
        </StyledButton>
      </Container>
    </div>
  )
}

export default Complete

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: start;
  margin-top: 40px;
  color: ${(p) => p.theme.text};
`

const StyledButton = styled(Button)`
  margin-top: 8px;
`
