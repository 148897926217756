import { useDataQuery, useQueryApi } from '../useQueryApi'

const usePersonCasesQuery = (options) => {
  const [api, { companyId, personId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['people', personId, 'cases'],

    queryFn: async () =>
      api.person.cases.get({
        pathArgs: { personId },
      }),

    enabled: !!(companyId && personId),
  })
}

export default usePersonCasesQuery
