import { useEffect } from 'react'

import _ from 'lodash'

import { Select, Form, Page } from 'peach/components'
import { withFormValue } from 'peach/decorators'
import { getName } from 'peach/helpers'
import { useReadData, useApi, usePrevious } from 'peach/hooks'

import { useAuthState } from 'core/auth/AuthProvider'

const getOptions = (list) =>
  _.map(list, (item) => ({ value: item.id, label: getName(item) }))

const TemplateRenderOptions = ({ value, onChange }) => {
  const api = useApi()
  const authState = useAuthState()
  const { companyId } = authState

  const { personId } = value || {}
  const previousPersonId = usePrevious(personId)
  useEffect(() => {
    if (personId !== previousPersonId) {
      onChange({
        ...value,
        paymentInstrumentId: undefined,
        loanId: undefined,
        caseId: undefined,
      })
    }
  }, [personId, previousPersonId, value, onChange])

  const [people, loadingPeople] = useReadData(
    () => api.company.people.get({ pathArgs: { companyId } }),
    [companyId],
  )

  const [cases, loadingCases] = useReadData(
    () => api.person.cases.get({ pathArgs: { personId } }),
    [personId],
  )

  const [loans, loadingLoans] = useReadData(
    () => api.person.loans.get({ pathArgs: { personId } }),
    [personId],
  )

  const [paymentInstruments, loadingInstruments] = useReadData(
    () =>
      api.person.paymentInstruments.get({
        pathArgs: { personId },
      }),
    [personId],
  )

  return (
    <Page.Full>
      <Form.Provider onChange={onChange} value={value}>
        <Select formKey='fmt' options={['html', 'text']} />

        <Select
          formKey='personId'
          loading={loadingPeople}
          options={getOptions(people)}
        />
        <Select
          formKey='paymentInstrumentId'
          loading={loadingInstruments}
          options={getOptions(paymentInstruments)}
        />
        <Select
          formKey='loanId'
          loading={loadingLoans}
          options={getOptions(loans)}
        />
        <Select
          formKey='caseId'
          loading={loadingCases}
          options={getOptions(cases)}
        />
      </Form.Provider>
    </Page.Full>
  )
}

export default withFormValue(TemplateRenderOptions)
