import { createContext, useContext, useEffect } from 'react'

import { styled } from 'styled-components'

import color from 'peach/helpers/color/color'
import { usePrevious, useToggle } from 'peach/hooks'

import LoadingErrorBox from './LoadingErrorBox'
import Spinner from './Spinner'

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`

const Content = styled.div`
  position: relative;
  height: 100%;
`

const Shade = styled.div`
  display: flex;
  position: absolute;
  inset: 0;
  flex-direction: column;
  align-items: center;
  background-color: ${(p) => color.fade(p.theme.background, 0.6)};
  padding: 150px 15%;
  text-align: center;
  color: #888;
  font-size: 20px;
`

const IsLoadingContext = createContext(false)
const HasErrorContext = createContext(false)

const LoadingContainer = (props) => {
  const {
    loading: passedLoading,
    error: passedError,
    query,
    queries,
    compact,
    children,
    message = 'Loading…',
    onClearError,
  } = props

  // can pas loading and error manually,
  // or can pass a react-query style query object

  const loading =
    passedLoading || query?.isLoading || _.some(queries, 'isLoading')
  const error =
    passedError || query?.error || _.first(_.compact(_.map(queries, 'error')))

  const isLoadingAbove = useContext(IsLoadingContext)
  const showLoading = loading && !isLoadingAbove

  const hasErrorAbove = useContext(HasErrorContext)
  const hasError = !!(error && !hasErrorAbove)

  const [showError, setShow, setHide] = useToggle()

  const prevError = usePrevious(error)

  useEffect(() => {
    if (error && !prevError) setShow()
  }, [error, prevError, setShow])

  const handleClear = () => {
    if (onClearError) onClearError()
    setHide(false)
  }

  return (
    <Wrapper>
      <IsLoadingContext.Provider value={loading || isLoadingAbove}>
        <Content>{children}</Content>
        {showLoading ? (
          <Shade>
            <Spinner compact={compact} />
            {message}
          </Shade>
        ) : (
          hasError &&
          showError && (
            <Shade>
              <LoadingErrorBox error={error} onClear={handleClear} />
            </Shade>
          )
        )}
      </IsLoadingContext.Provider>
    </Wrapper>
  )
}

export default LoadingContainer
