import { useDataQuery, useQueryApi } from '../useQueryApi'

const useLoanTypeQuery = (options) => {
  const [api, { companyId, loanTypeId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['companies', companyId, 'loan-types', loanTypeId],

    queryFn: () =>
      api.company.loanType.get({
        pathArgs: { companyId, loanTypeId },
      }),

    enabled: !!(companyId && loanTypeId),
  })
}

export default useLoanTypeQuery
