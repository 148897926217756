import { forwardRef } from 'react'

import { styled } from 'styled-components'

import { withFormValue, withLabel } from 'peach/decorators'

import { RawInput } from './Input'

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
`

const StyledInput = styled(RawInput)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`
const ColorPicker = styled.input`
  display: inline-block;
  margin: 0;
  border: 1px solid ${(p) => p.theme.border};
  border-left: none;
  border-radius: 0 4px 4px 0;
  background-color: transparent;
  cursor: pointer;
  padding: 4px;
  width: 64px;
  height: auto;
`

const ColorInput = forwardRef((props, ref) => {
  const { value, onChange, disabled, ...rest } = props

  // note: Input already has withLabel, but we disable with with `bare`
  return (
    <Wrapper>
      <StyledInput {...rest} value={value} onChange={onChange} ref={ref} />
      <ColorPicker
        type='color'
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
      />
    </Wrapper>
  )
})

export default withFormValue(withLabel(ColorInput))
