import _ from 'lodash'

import * as helpers from 'peach/helpers'

import './underscore'
import { React, ReactDom } from './reactVersions'

window.peach.helpers = _.mapValues(helpers)

export { React, ReactDom }
