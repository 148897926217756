import {
  LoadingContainer,
  Form,
  Input,
  Bar,
  Panels,
  Select,
  Modal,
  Page,
  Model,
  StateCodeSelect,
  CountryCodeSelect,
} from 'peach/components'
import { useContactQuery } from 'peach/data'
import { useApi } from 'peach/hooks'
import { useParams } from 'peach/router'

const PersonContact = () => {
  const { personId, contactId } = useParams()
  const api = useApi()

  const [contact, query] = useContactQuery()

  const sendEditContact = async (_contact, diff) => {
    await api.person.contact.put({
      pathArgs: { personId, contactId },
      body: diff,
    })
    query.refetch()
  }

  const types = ['phone', 'email', 'address', 'whatsApp', 'fbMessenger']

  const labels = ['personal', 'home', 'work']

  const statuses = ['primary', 'secondary', 'additional', 'archived']

  // todo:  add country code select to components and use here
  const editContact = (
    <Modal
      initialValue={contact}
      onSubmit={sendEditContact}
      render={({ value }) => (
        <>
          <Select formKey='status' options={statuses} />
          <Select formKey='label' options={labels} />
          <Select formKey='contactType' options={types} />
          {value.contactType === 'address' ? (
            <>
              <Form.Provider formKey='address'>
                <Input formKey='addressLine1' />
                <Input formKey='addressLine2' />
                <Input formKey='city' />
                <Input formKey='countyOrRegion' />
                <StateCodeSelect formKey='state' />
                <Input formKey='postalCode' />

                <CountryCodeSelect formKey='country' />
              </Form.Provider>
            </>
          ) : (
            <Input formKey='value' />
          )}
          <Select boolean formKey='verified' />
          <Select boolean formKey='valid' />
        </>
      )}
      title='Edit Contact'
      trigger
    />
  )

  const header = <Bar right={editContact} title='Contact' />

  return (
    <Panels header={header}>
      <LoadingContainer query={query}>
        <Page>
          <Model.Viewer json={contact} title='Contact' />
        </Page>
      </LoadingContainer>
    </Panels>
  )
}

export default PersonContact
