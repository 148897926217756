import { Page, Model, LoadingContainer, Panels, Bar } from 'peach/components'
import { useTemplateQuery, useTemplateDescriptorQuery } from 'peach/data'

import TemplateEditor from '../TemplateEditor/TemplateEditor'

const TemplateSubject = () => {
  const [template, tQuery] = useTemplateQuery()

  const [descriptor, dQuery] = useTemplateDescriptorQuery({
    templateDesriptorId: template?.descriptorId,
  })

  const onSaveTemplate = (newTemplateData, diff) => {
    console.info(newTemplateData, diff)
  }

  const editTemplate = template && descriptor && (
    <TemplateEditor
      descriptor={descriptor}
      onSaveTemplate={onSaveTemplate}
      template={template}
      triggerButtonLabel='Edit Template'
    />
  )

  return (
    <LoadingContainer queries={[tQuery, dQuery]}>
      <Panels header={<Bar right={editTemplate} title='Template' />}>
        <Page.Full>
          <Model.Item json={template} title='Template' />
          <Model.Item json={descriptor} noLink title='Descriptor' />
        </Page.Full>
      </Panels>
    </LoadingContainer>
  )
}

export default TemplateSubject
