import { useRef, useEffect } from 'react'

const useIsUnmounted = () => {
  const unmountedRef = useRef(false)

  useEffect(() => () => (unmountedRef.current = true), [])

  return unmountedRef
}

export default useIsUnmounted
