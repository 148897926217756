import { styled } from 'styled-components'

import { Button, Json } from 'peach/components'
import { withLabel, withFormValue } from 'peach/decorators'
import { humanizeKey } from 'peach/helpers'

import { FormProvider } from './FormProvider'
import useListOnChange from './useListOnChange'

const List = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  border: 1px solid red;
  border: 1px solid ${(p) => p.theme.border};
  border-radius: 4px;
  background-color: ${(p) => p.theme.offset};
`

const Item = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${(p) => p.theme.border};

  &:last-child {
    border-bottom: none;
  }
`

const ItemContent = styled.div`
  flex-grow: 1;
  padding-right: 8px;
  padding-left: 8px;
`

const ItemIndex = styled.div`
  display: block;
  padding: 8px;
  color: ${(p) => p.theme.labelText};
  font-size: 12px;
  font-weight: bold;
`

const ItemAction = styled.div`
  padding: 8px;
`

const Placeholder = styled.div`
  opacity: 0.5;
`

const defaultRender = (item) => <Json.Item json={item} />

const FormListAdd = ({ onAdd, render, title, children }) => {
  const contents = render ? (
    render({ onAdd })
  ) : children ? (
    children
  ) : (
    <Button onClick={onAdd}>➕</Button>
  )

  const itemName = title || 'Item'

  return (
    <Item>
      <ItemIndex>[ ]</ItemIndex>
      <ItemContent>
        <Placeholder>{`Add New ${itemName}…`}</Placeholder>
      </ItemContent>
      <ItemAction>{contents}</ItemAction>
    </Item>
  )
}

const FormList = (props) => {
  const {
    formKey,
    value,
    onChange,
    renderItem,
    add,
    remove,
    provider,
    defaultValue,
    children,
  } = props

  const { list, onAddItem, onRemoveItem, onUpdateItem } = useListOnChange({
    value,
    onChange,
    defaultValue,
  })

  const displayChildren = _.map(list, ({ listId, item }, index) => {
    const onItemChange = (newVal) => onUpdateItem(listId, newVal)

    const onRemove = () => onRemoveItem(listId)

    const renderProps = { value: item, onChange: onItemChange, onRemove }

    const content = children
      ? children
      : renderItem
      ? renderItem(renderProps)
      : defaultRender(renderProps)

    const displayContent =
      provider || children ? (
        <FormProvider value={item} onChange={onItemChange}>
          {content}
        </FormProvider>
      ) : (
        content
      )

    //todo:  add up and down actions in addition to remove
    // (must take listId into account so keys stay working correctly)

    return (
      <Item key={listId}>
        <ItemIndex title={listId}>{`[${index}]`}</ItemIndex>
        <ItemContent>{displayContent}</ItemContent>
        <ItemAction>
          {remove && <Button onClick={onRemove}>➖</Button>}
        </ItemAction>
      </Item>
    )
  })

  return (
    <List>
      {displayChildren}
      {add === true && (
        <FormListAdd onAdd={() => onAddItem()} title={humanizeKey(formKey)} />
      )}
    </List>
  )
}

export default withFormValue(withLabel(FormList), { renderWhenHidden: true })
