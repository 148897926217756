import { LoadingContainer, Model, Page, Modal } from 'peach/components'
import { useLoanQuery, useLoanAutopayQuery } from 'peach/data'
import { useApi } from 'peach/hooks'
import { useParams } from 'peach/router'

const LoanAutopay = () => {
  const api = useApi()
  const { personId, loanId } = useParams()

  const [loan, loanQuery] = useLoanQuery()

  const [autopay, autopayQuery] = useLoanAutopayQuery({
    disabled: !loan?.current?.autopay,
  })

  const sendCreateInstallmentAutopay = async (options) => {
    await api.loan.autopay.put({
      pathArgs: { personId, loanId },
      body: options,
    })
    loanQuery.refetch()
    autopayQuery.refetch()
  }

  const sendCreateLineOfCreditAutopay = async (options) => {
    await api.loan.autopay.post({
      pathArgs: { personId, loanId },
      body: options,
    })
    loanQuery.refetch()
    autopayQuery.refetch()
  }

  const createInstallmentAutopay = (
    <Modal
      initialValue={{}}
      onSubmit={sendCreateInstallmentAutopay}
      title='Create Installment Autopay'
    />
  )

  const createLineOfCreditAutopay = (
    <Modal
      initialValue={{}}
      onSubmit={sendCreateLineOfCreditAutopay}
      title='Create Line of Credit Autopay'
    />
  )

  const modals =
    loan?.type === 'installment'
      ? createInstallmentAutopay
      : loan?.type === 'lineOfCredit'
      ? createLineOfCreditAutopay
      : null

  return (
    <LoadingContainer queries={[loanQuery, autopayQuery]}>
      {modals}
      <Page.Full>
        <Model.Item json={autopay} />
      </Page.Full>
    </LoadingContainer>
  )
}

export default LoanAutopay
